/**
 * 首页路由配置
 */
export default {
  path: '/home/about',
  name: 'about',
  component: () => import('iebdc/views/about'),
  meta: {
    isNotMenu: true,
    permission: 'IEBDC:GNXS:DBLJ'
  }
};
