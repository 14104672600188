<template>
  <div class="container">
    <e-dialog :config="openAddConfig" :btns="openAddBtns" ref="dialog">
      <template slot="content" ref="formcontent">
        <el-table ref="table" :data="data">
          <el-table-column property="orgName" label="缴费单位"></el-table-column>
          <el-table-column property="payerName" label="缴费人"
            ><template slot-scope="scope"> {{ encryptData(scope.row.payerName) }} </template></el-table-column
          >
          <el-table-column property="money" label="缴费金额"></el-table-column>
          <el-table-column label="操作" fixed="right" width="200px" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="toPay(scope.row)" v-if="scope.row.status === '0'">缴 费 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </e-dialog>
  </div>
</template>

<script>
import payOnline from '@iebdc/api/pay-online.js';
export default {
  name: 'detail',
  components: {},
  data() {
    return {
      data: [],
      // 打开新增弹框配置
      openAddConfig: {
        title: '缴费信息',
        width: '800px',
        // height:""
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
        appendBody: true,
      },
      fromFeeDatas: [],
      openAddBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openAddConfig.visible = false;
            // this.$emit('close');
          },
          show: true,
        },
      ],
    };
  },
  computed: {
    encryptData() {
      return (val) => {
        return this.$decryptByDES(val);
      };
    },
  },
  methods: {
    toPay(row) {
      console.log(row);
      payOnline.payApply({ id: row.id }).then((res) => {
        if (res.success) {
          if (JSON.parse(res.data).orderId) {
            this.$router.push({
              path: '/home/online-pay',
              query: {
                orderNo: JSON.parse(res.data).orderId,
              },
            });
          } else {
            if (res.data.indexof('开票失败') > -1) {
              this.$message.success('开票结果审核中');
            } else this.$message.error('财政服务调用：' + JSON.parse(res.data).respmsg);
          }
        }
      });
    },

    open(data) {
      console.log(data);
      this.openAddConfig.visible = true;
      this.data = data;
      this.$nextTick(() => {
        // this.getFormData();
      });
    },
    // table状态切换
    ztRender(h, row, prop) {
      this.sfzs = row['sfzs'];
      const val = row[prop] === true ? '是' : '否';
      return h('span', {
        domProps: {
          innerHTML: val,
        },
        style: {
          marginRight: '10px',
        },
      });
    },
    mounted() {},
  },
};
</script>

<style scoped lang="scss">
.guid {
  display: flex;
  justify-content: space-between;
  .guid-button {
    margin-bottom: 2px;
  }
}
/deep/ .el-dialog .el-dialog__body.el-dialog__body {
  padding: 35px 30px;
  max-height: auto;
  overflow-y: auto;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
  width: 100%;
}
</style>
