<template>
  <el-input placeholder="请输入手机验证码" v-model="phoneCode">
    <el-button class="yzmButton" slot="append" @click="getCode" :disabled="codeBtnDisabled">
      {{ getCodeBtnText }}
    </el-button>
  </el-input>
</template>

<script>
import { getCode } from '../../api/register';
export default {
  name: 'phone-code',
  model: {
    prop: 'code',
    event: 'update'
  },
  props: {
    code: {
      type: String
    },
    phone: {
      required: true
    }
  },
  data() {
    return {
      codeBtnDisabled: true,
      getCodeBtnText: '获取短信验证码',
      phoneCode: ''
    };
  },
  watch: {
    code(n) {
      this.phoneCode = n;
    },
    phoneCode(n) {
      this.$emit('update', n);
    },
    phone(n) {
      var re = /^((0\d{2,3}-\d{7,8})|((19[0-9])|(16[0-9])|(13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      this.codeBtnDisabled = !re.test(n)
    }
  },
  methods: {
    getCode() {
      this.countDown();
      const params = {
        phone: this.phone,
        areaCode: window.CONFIG.code
      };
      getCode(params).then((res) => {
        if (res && res.success) {
          this.$message.success('验证码已发送');
        } else {
          this.$message.error('验证码发送失败');
        }
      });
    },
    // 倒计时
    countDown() {
      let time = 60;
      this.codeBtnDisabled = true;
      this.getCodeBtnText = `${time}s后重新发送`;
      const timer = setInterval(() => {
        if (time > 1) {
          --time;
          this.getCodeBtnText = `${time}s后重新发送`;
        } else {
          this.codeBtnDisabled = false;
          this.getCodeBtnText = '获取短信验证码';
          clearInterval(timer);
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped></style>
