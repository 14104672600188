<template>
  <div class="appointmentSteps">
    <div class="header box-shadow-default">
      <el-steps :active="activeStep" align-center>
        <el-step v-for="(item, index) in stepsData" :title="item.title" :key="index"></el-step>
      </el-steps>
    </div>
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: 'AppointmentSteps',
  props: {
    currentLabel: { type: String },
    stepsData: { type: Array },
    activeStep: { type: Number }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/common-variables.scss';
@import '~@/styles/public.scss';
// .appointmentSteps {
//   .head-steps {
//     background-color: #ffffff;
//     padding: $spacing-base $spacing-medium;
//   }
//   /deep/ .el-steps {
//     padding: 30px;
//   }
// }
.appointmentSteps {
  .header {
    margin-bottom: $spacing-medium;
    border-radius: $border-radius-base;
    background-color: $background-color-layout;
    /deep/ .flex-space-between {
      padding: $spacing-base $spacing-medium;
    }
    /deep/ .el-step__head.is-process {
      color: #2C8BFF;
      border-color: #2C8BFF;
    }
    /deep/ .el-step__title.is-process {
      color: #2C8BFF;
    }
    /deep/ .el-steps--horizontal {
      padding: $spacing-large 0 $spacing-large 0;
    }
  }
}
</style>
