import { REST_SERVICE } from '../config';
import request from '../utils/request';
import { crypto } from '@iebdc/utils/crypto';

export const login = (data) =>
  request({
    url: `${REST_SERVICE.zww.login}?client_id=${data.client_id}&client_secret=${window.CONFIG.LOGIN_TO_ZWW.secret}
    &grant_type=authorization_code&state=a&code=${data.code}&redirect_uri=${data.redirect_uri}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data,
  });
export const zwloginByToken = (params) =>
  request({
    url: `${REST_SERVICE.unified.zwLoginByToken}?host=${params.host}&retoken=${params.retoken}&token=${params.token}`,
    method: 'post',
  });

export const logOut = (params) =>
  request({
    url: `${REST_SERVICE.zww.logout}?redirect_uri=${params.redirect_uri}`,
    method: 'get',
    headers: {
      token: params.zwToken,
      Referrer: params.redirect_uri,
    },
  });

export function login1(data) {
  request({
    url: `${REST_SERVICE.zww.login1}?display=outside&client_id=${data.client_id}&client_secret=${
      REST_SERVICE.LOGIN_TO_ZWW.secret
    }&state=${new Date().getTime()}&response_type=code&redirect_uri=${data.redirect_uri}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data,
  });
}
