/**
 * area store
 * @description
 * 区域
 */
export default {
  namespaced: true,
  state: {
    area: [],
    allOrgList: [], //所有登记机构列表
  },
  getters: {
    getArea: function (state) {
      return state.area;
    },
    // 根据code值取登记机构名称
    getOrgNameByCode: function (state) {
      return function (code) {
        if (!code) return;
        const object = state.allOrgList.find(function (item) {
          return item.djjg === code;
        });
        if (object && object.jgmc) {
          return object.jgmc;
        }
      };
    },
    getAreaNameByCode: function (state) {
      return function (code) {
        if (!code) return;
        const allArea = getAllNodes(state.area, 'childrens');
        const object = allArea.find(function (item) {
          return item.code === code;
        });
        if (object && object.title) {
          return object.title;
        }
      };
    },
    // 根据登记机构名称取code值
    getOrgCodeByName: function (state) {
      return function (name) {
        if (!name) return;
        const object = state.allOrgList.find(function (item) {
          return item.jgmc === name;
        });
        if (object && object.djjg) {
          return object.djjg;
        }
      };
    },
  },
  mutations: {
    setArea(state, area) {
      state.area = area;
      state.allOrgList = getAlldjjg(state.area);
    },
  },
  actions: {
    setArea({ commit }, area) {
      commit('setArea', area);
    },
  },
};
// 登记机构
function getAlldjjg(arrayNodes) {
  if (!arrayNodes) {
    return [];
  }
  let result = [];
  if (arrayNodes[0].childrens) {
    arrayNodes[0].childrens.map((shiArray) => {
      if (shiArray.childrens) {
        shiArray.childrens.map((xianArray) => {
          if (!xianArray.childrens && xianArray.bsdtData.length) {
            xianArray.bsdtData.map((djjg) => {
              result.push(djjg);
            });
          }
        });
      }
    });
  }
  return [...result];
}
function getAllNodes(arrayNodes, attChildren) {
  if (arrayNodes === null) {
    return [];
  }
  attChildren = attChildren === null ? 'children' : attChildren;
  let result = [];
  for (let ii = 0; ii < arrayNodes.length; ii++) {
    let pNode = arrayNodes[ii];
    result.push(pNode);
    if (pNode[attChildren]) {
      let arrayTempNodes = getAllNodes(pNode[attChildren], attChildren);
      result = result.concat(arrayTempNodes);
    }
  }
  return result;
}
