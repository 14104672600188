/**
 * 申请路由配置
 */
export default {
  path: '/home/check-in/apply',
  name: 'apply',
  component: () => import('iebdc/views/apply'),
  meta: {
    label: '在线办理',
    isNotMenu: true,
    activeMenu: '/home/wybl',
    permission: 'IEBDC:SQ',
  },
  redirect: '/home/check-in/apply/read',
  children: [
    {
      path: '/home/check-in/apply/read',
      name: 'read',
      component: () => import('iebdc/views/apply/flow/read'),
      meta: {
        label: '阅读须知',
        icon: 'document',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:YDXZ',
      },
    },
    {
      path: '/home/check-in/apply/applicantType',
      name: 'applicantTypeContainer',
      component: () => import('iebdc/views/apply/flow/applicantTypeContainer'),
      meta: {
        label: '申请类型',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SY:WDSQ:SQLX',
      },
    },
    {
      path: '/home/check-in/apply/rightCertify',
      name: 'rightCertify',
      component: () => import('iebdc/views/apply/flow/rightCertify'),
      meta: {
        label: '产权证核验',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:CQZHY',
      },
    },
    {
      path: '/home/check-in/apply/lpb',
      name: 'showHouseTable',
      component: () => import('iebdc/views/apply/flow/showHouseTable'),
      meta: {
        label: '楼盘表提取',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:LPBTQ',
      },
    },
    {
      path: '/home/check-in/apply/applicantTable',
      name: 'applicantTable',
      component: () => import('iebdc/views/apply/flow/applicantTable'),
      // ...applyGuard,
      meta: {
        label: '填写申请表',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:TXSQB',
      },
    },
    {
      path: '/home/check-in/apply/uploadFile',
      name: 'uploadFile',
      component: () => import('iebdc/views/apply/flow/uploadFile'),
      meta: {
        label: '材料上传',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:SCFJ',
      },
    },
    {
      path: '/home/check-in/apply/expressFile',
      name: 'expressFile',
      component: () => import('iebdc/views/apply/flow/expressFile'),
      meta: {
        label: '办理方式',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:SCYJ',
      },
    },
    {
      path: '/home/check-in/apply/authorizedCertify',
      name: 'authorizedCertify',
      component: () => import('iebdc/views/apply/flow/authorizedCertify'),
      meta: {
        label: '身份认证',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:SQRZ',
      },
    },
    {
      path: '/home/check-in/apply/intelligentAudit',
      name: 'intelligentAudit',
      component: () => import('iebdc/views/apply/flow/intelligentAudit'),
      meta: {
        label: '信息核验',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:ZNSP',
      },
    },
    {
      path: '/home/check-in/apply/finish',
      name: 'finish',
      component: () => import('iebdc/views/apply/flow/finish'),
      meta: {
        label: '完成',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:WC',
      },
    },
  ],
};
