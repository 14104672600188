import lodash from 'lodash';
//申请数据状态保存
export default {
  namespaced: true,
  state: {
    // 一级配置流程数据
    /**
     * @param firstTypeData   一级选择数据
     * @param secondTypeData  二级选择数据
     * @param thirdTypeData   三级选择数据
     * @param fourTypeData    四级选择数据
     * @desc 使用方式 (firstFlow.firstTypeData)
     * */
    firstFlow: {},
    // 正在进行的流程业务号、ID信息
    flowInfoIng: {},
    processData: [],
  },
  getters: {
    getFirstFlow(state) {
      return state.firstFlow;
    },
    getFlowInfoIng(state) {
      return state.flowInfoIng;
    },
  },
  mutations: {
    //更新三级选择数据
    updateFirstFlow(state, fourTypeData) {
      state.firstFlow.thirdTypeData = fourTypeData;
    },
    // 同步保存一级流程
    setFirstFlow(state, firstFlow) {
      state.firstFlow = firstFlow;
    },

    // 设置新增成功的数据
    setFlowInfoIng(state, flowInfoIng) {
      state.flowInfoIng = flowInfoIng;
    },
    //设置表单值
    setFormData(state, processData) {
      // state.processData = JSON.parse(JSON.stringify(processData));
      state.processData = lodash.cloneDeep(processData);
    },
    //设置表单值
    setFormDataKey(state, data) {
      if (state.processData && state.processData[data.formIndex]) {
        if (data.key === 'qlxxExMhs') {
          // state.processData[data.formIndex][data.key][0] = JSON.parse(JSON.stringify(data.value));
          state.processData[data.formIndex][data.key][0] = lodash.cloneDeep(data.value);
        } else if (data.key.indexOf('qlxxExMhs') > -1) {
          const updateindex = data.key.split('qlxxExMhs')[1]; //修改的那个不动产信息序列号
          // state.processData[data.formIndex]['qlxxExMhs'][updateindex] = JSON.parse(JSON.stringify(data.value));
          state.processData[data.formIndex]['qlxxExMhs'][updateindex] = lodash.cloneDeep(data.value);
        } else {
          // state.processData[data.formIndex][data.key] = JSON.parse(JSON.stringify(data.value));
          state.processData[data.formIndex][data.key] = lodash.cloneDeep(data.value);
        }
      }
    },
  },
  actions: {
    // 异步一级流程数据
    setFirstFlow({ commit }, firstFlow) {
      commit('setFirstFlow', firstFlow);
    },
  },
};
