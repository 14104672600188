// 权限混合
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['permission'])
  },
  methods: {
    // 权限判断
    hasPermission(permission) {
      return this.permission.includes(permission);
    }
  }
};
