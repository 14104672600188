/**
 * 按需加载 element-ui 组件
 * @description
 * [参考官网 - 按需加载](https://element.eleme.cn/#/zh-CN/component/quickstart#an-xu-yin-ru)
 */
// import Vue from 'vue';

// 方式一：按需加载
// import { Button, Loading, Message } from 'element-ui';
//
// Vue.use(Button);
// Vue.use(Loading.directive);
//
// Vue.prototype.$loading = Loading.service;
// Vue.prototype.$message = Message;

// 方式二：完整加载方式
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI, { size: 'small' });
/**
 * element-ui 注册
 */
import Vue from 'vue';
import ElementUI from 'element-ui';
import '../styles/element-theme/index.css';
// ElSelectTree 适用于省、市、县选择，非级联
import ElSelectTree from 'el-select-tree';

Vue.use(ElementUI, { size: 'small' });

Vue.use(ElSelectTree);
