import progressQuery from './progress-query'; // 办理进度查询
import quanSZMQuery from './quanSZM-query'; // 权属证明查询
import electronicLicenseRoute from './electronic-license'; //电子证照
import djtcx from './djtcx'; //自治区可视化查询
/**
 * 我要查询路由配置
 */
export default {
  path: '/home/info-search',
  name: 'info-search',
  component: () => import('iebdc/views/info-search'),
  meta: {
    label: '我要查询',
    permission: 'IEBDC:CX',
    // permission: 'IEBDC:SY:XXCX:JDCX'
  },
  children: [progressQuery, quanSZMQuery, electronicLicenseRoute, djtcx],
  // djtcx,
};
