// 权限混合
import { getCookie } from '@iebdc/utils/cookies';
export default {
  methods: {
    // 权限判断
    bsdtDialog() {
      if (!getCookie('selectedBsdtCode')) {
        this.$store.commit('dashboard/isShowBsdtDialog', true);
        return false;
      }
      return true;
    }
  }
};
