/**
 * 首页路由配置
 */
export default {
  path: '/home/dashboard/express',
  name: 'express',
  component: () => import('iebdc/views/express'),
  meta: {
    label: '我的邮寄',
    isNotMenu: true,
    activeMenu: '/home/dashboard',
    permission: 'IEBDC:YJDZ'
  }
};
