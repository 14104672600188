<template></template>
<script>
export default {
  name: 'fromZJ',
  methods: {},
  created() {},
  watch: {
    $route: {
      handler() {
        var url = decodeURIComponent(window.location.href); //获取当前url
        var cs = {};
        cs = this.$route.query;
        console.log(cs);
        if (
          typeof cs.client_id != 'undefined' &&
          typeof cs.state != 'undefined' &&
          typeof cs.response_type != 'undefined' &&
          typeof cs.scope != 'undefined' &&
          typeof cs.type != 'undefined' &&
          typeof cs.redirect_uri != 'undefined'
        ) {
          var query = url.split('?')[1]; //获取?之后的参数字符串
          console.log(query);
          //   console.log()
          window.location.href = `${this.$decryptByDES(
            window.CONFIG.LOGIN_TO_ZWW.url
          )}/xjwwrz/login/oauth2login?client_id=${cs.client_id}&state=a&response_type=code&scope=user&redirect_uri=${
            cs.redirect_uri
          }`;
        }
      },
      deep: true,
      immediate: true, // immediate选项可以开启首次赋值监听
    },
  },
};
</script>
