/**
 * 首页路由配置
 */
export default {
  path: '/home/appointment/visiting-service',
  name: 'visition-service',
  component: () => import('iebdc/views/visiting-service'),
  meta: {
    label: '上门服务', // 对应uums 上门预约
    isNotMenu: true,
    activeMenu: '/home/wybl',
    permission: 'IEBDC:SY:WSYY:SMFW',
    construction: 'IEBDC:SY:WSYY:SMFW:SFZJ',
    icon: require('../../assets/images/smfw.png')
    // background: 'linear-gradient(to bottom right, #19EBE1 , #02ADBF)'
  }
};
