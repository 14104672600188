<template>
  <div class="navMenu full-height">
    <el-row ref="rowId">
      <el-col v-for="(navMenu, index) in navMenus" :key="index" class="colId">
        <!-- 最后一级菜单 -->
        <el-menu-item v-if="!navMenu.children || navMenu.meta.noRenderChildren" :key="navMenu.path" :index="navMenu.path">
          <i v-if="navMenu.meta.icon" :class="getIconClass(navMenu.meta.icon)"></i>
          <span slot="title">{{ navMenu.meta.label }}</span>
        </el-menu-item>

        <!-- 此菜单下还有子菜单 -->
        <el-submenu v-if="navMenu.children && !navMenu.meta.noRenderChildren" :key="navMenu.path" :index="navMenu.path">
          <template slot="title">
            <i v-if="navMenu.meta.icon" :class="getIconClass(navMenu.meta.icon)"></i>
            <span> {{ navMenu.meta.label }}</span>
          </template>
          <!-- 递归 -->
          <Navbar :navMenus="navMenu.children" />
        </el-submenu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: ['navMenus'],
  data() {
    return {};
  },
  methods: {
    getIconClass(iconName) {
      return `el-icon-${iconName}`;
    }
  },
  mounted() {
    const sWidth = Math.floor(this.$refs.rowId.$el.clientWidth / this.navMenus.length);
    const colList = document.getElementsByClassName('colId');
    [...colList].map((item) => {
      item.style.width = sWidth + 'px';
    });
  }
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';

* {
  outline: none;
}
.el-row {
  .el-col {
    ///* 水平样式 */
    //.el-menu--horizontal > div > li {
    //  height: 100%;
    //}
    //.el-menu--horizontal > div > .el-submenu {
    //  float: left;
    //}
    //width: auto !important;
    //margin-right: 35px !important;
    /* 一级菜单的样式 */
    li {
      float: left;
      margin: 0;
      padding: 0 6px;
      height: 100%;
      line-height: 32.5px;
      border-bottom: 6px solid transparent;
      font-size: 18px;
      font-weight: bold;
      &:hover {
        background-color: #fff;
      }
      /* color: #fff;
      i {
        color: #fff;
      } */
    }
    ///deep/ li

    ///* 解决下图1 下拉三角图标 */
    .el-submenu /deep/ .el-submenu__icon-arrow {
      position: static;
      vertical-align: middle;
      margin-left: 8px;
      margin-top: -3px;
      // color: #fff;
    }
    /* 解决下图2 无下拉菜单时 不对齐问题 */
    .el-submenu {
      margin-right: 78px;
    }
    .el-submenu /deep/ .el-submenu__title {
      padding: 0 5px;
      height: 100%;
      line-height: 34px;
      border-bottom: 6px solid transparent;
      font-size: 18px;
      font-weight: bold;
      /* color: #fff;
      i {
        color: #fff;
      } */
    }

    .el-menu-item:not(.is-disabled):hover {
      background-color: #fff !important;
    }
  }
}
</style>
