/**
 * 首页路由配置
 */
export default {
  path: '/message',
  name: 'message',
  component: () => import('iebdc/views/message'),
  meta: {
    label: '留言板',
    permission: 'IEBDC:GNXS:LYB'
  }
};
