<template>
  <div class="step-content">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[active === index ? 'active' : 'default', 'step-item']"
    >
      <img :src="logo(step, index)" />
      <div>{{ step.title }}</div>
      <div v-if="index < steps.length - 1" class="step-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vertical-step',
  props: {
    steps: {
      type: Array,
      required: true
    },
    active: {
      type: Number,
      required: true
    }
  },
  methods: {
    logo(step, index) {
      return index === this.active ? step.activeLogo : step.logo;
    }
  }
};
</script>

<style lang="scss" scoped>
.step-content {
  width: 100%;
  height: auto;
  padding: 0 20px;
  .step-item {
    margin-bottom: 38px;
    height: 74px;
    width: 74px;
    padding-top: 15px;
    text-align: center;
    border-radius: 10px;
    .step-line {
      width: 4px;
      height: 38px;
      border-left: solid #f3f3f3 3px;
      margin-left: 37px;
      margin-top: 15px;
    }
  }
  .default {
    color: black;
    background-color: #f3f3f3;
  }
  .active {
    color: white;
    background-color: #167cc7;
  }
}
</style>
