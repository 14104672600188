/**
 * app store
 * @description
 * 主模块的状态存储
 */
export default {
  namespaced: true,
  state: {
    taxStatus: null,
    beianStatus: null,
  },
  mutations: {
    setTaxStatus(state, type) {
      state.taxStatus = type;
    },
    setbeianStatus(state, type) {
      state.beianStatus = type;
    },
  },
  actions: {
    setTaxStatus({ commit }, type) {
      commit('setTaxStatus', type);
    },
    setbeianStatus({ commit }, type) {
      commit('beianStatus', type);
    },
  },
};
