<template>
  <div class="jg">
    <el-form label-width="255px" :model="formData" :rules="rules" ref="form">
      <el-form-item label="法人类型：" prop="frlx">
        <dict-select type="法人类型" clearable v-model="formData.frlx"></dict-select>
      </el-form-item>

      <el-form-item label="企业名称：" prop="qymc">
        <el-input v-model="formData.qymc" placeholder="请输入企业名称"></el-input>
      </el-form-item>

      <el-form-item label="企业类型：" prop="qylx">
        <dict-select type="企业类型" clearable v-model="formData.qylx"></dict-select>
      </el-form-item>

      <el-form-item label="企业工商注册号/统一社会信用代码：" prop="qyzjh">
        <el-input v-model="formData.qyzjh" placeholder="请输入企业证件号码"></el-input>
      </el-form-item>

      <el-form-item label="企业员工姓名：" prop="xm" v-if="formData.qylx === '2'">
        <el-input v-model="formData.xm" placeholder="请输入真实姓名"></el-input>
      </el-form-item>

      <el-form-item label="企业员工身份证号：" prop="sfzh" v-if="formData.qylx === '2'">
        <el-input v-model="formData.sfzh" placeholder="请输入身份证号"></el-input>
      </el-form-item>

      <el-form-item label="法定代表人：" prop="frmc">
        <el-input v-model="formData.frmc" placeholder="请输入法人姓名"></el-input>
      </el-form-item>

      <el-form-item label="法人代表人民族：" prop="frmz">
        <dict-select type="民族" clearable v-model="formData.frmz"></dict-select>
      </el-form-item>

      <el-form-item label="法定代表人身份证号：" prop="frzjh">
        <el-input v-model="formData.frzjh" placeholder="请输入您的证件号码"></el-input>
      </el-form-item>

      <el-form-item label="法定代表人性别：" prop="frxb">
        <dict-select type="性别" v-model="formData.frxb" disabled></dict-select>
      </el-form-item>

      <el-form-item label="企业地址：" v-if="hasPermission('IEBDC:QYZC:QYDZ')" prop="qydz">
        <el-input placeholder="请输入企业详细地址" v-model="formData.qydz"></el-input>
      </el-form-item>

      <el-form-item label="审核区域：" v-if="hasPermission('IEBDC:QYZC:QYDZ')" prop="bsdt">
        <el-cascader @change="areaChange" v-model="checkAreaData" :options="areaData" :props="props"></el-cascader>
        <el-select v-model="formData.bsdt" placeholder="办事大厅" v-show="bsdtData.length !== 0">
          <el-option v-for="(item, index) in bsdtData" :key="index" :label="item.officeName" :value="item.officeCode"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="身份证明：" v-if="hasPermission('IEBDC:QYZC:QYDZ')">
        <el-upload
          :data="uploadParams"
          name="file"
          accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
          :show-file-list="false"
          ref="upload"
          class="upload-demo"
          drag
          :action="submitUrl"
          :auto-upload="true"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item v-if="hasPermission('IEBDC:QYZC:QYDZ')">
        <div class="flex-wrap">
          <div class="file-content" v-for="(item, index) in fileList" :key="item.id">
            <div class="delete-file text-center pointer" @click="deleteFile(item.id, index)">
              <i class="el-icon-close"></i>
            </div>
            <el-image style="width: 100px; height: 100px" :src="item.url" fit="cover" :preview-src-list="[item.url]">
              <div slot="error" class="image-slot pointer" @click="downLoad(item.url)">
                <i class="el-icon-document"></i>
              </div>
            </el-image>
            <p>{{ item.fileName }}</p>
          </div>
        </div>
      </el-form-item>

      <el-form-item class="text-center">
        <el-button @click.native="submit" class="next-btn">立即注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateName, validateZjh, validateZzjg } from '../common/validate';
import hasPermission from '../../../mixin/hasPermission';
import { getAllNodes } from '../../../utils/common';
import { REST_SERVICE } from '../../../config';
import { getSystemTime, delFileById, authJgUser } from '../../../api/register';
import { getAreaData, getOrganBsdt, getWorkOffice } from '../../../api/register';
export default {
  name: 'auth-jg',
  mixins: [hasPermission],
  /* props: {
    userInfo: {
      type: Object,
      required: true
    }
  }, */
  data() {
    this.rules = {
      frlx: [{ required: true, message: '请选择法人类型', trigger: 'change' }],
      qymc: [{ required: true, message: '请输入企业名称', trigger: 'change' }],
      qylx: [{ required: true, message: '请选择企业类型', trigger: 'change' }],
      qyzjh: [{ required: true, validator: validateZzjg, trigger: 'change' }],
      xm: [{ required: true, validator: validateName, trigger: 'change' }],
      sfzh: [{ required: true, validator: validateZjh(function () {}), trigger: 'change' }],
      frmc: [{ required: true, validator: validateName, trigger: 'change' }],
      frmz: [{ required: true, message: '请选择法人民族', trigger: 'change' }],
      frzjh: [
        {
          required: true,
          validator: validateZjh(this.getDataFromCard),
          trigger: 'change',
        },
      ],
      qydz: [{ required: true, message: '请输入企业地址', trigger: 'change' }],
      bsdt: [{ required: true, message: '请选择审核区域办事大厅', trigger: 'change' }],
    };
    // 级联选择器配置
    this.props = {
      value: 'code',
      label: 'title',
      children: 'childrens',
    };
    return {
      submitUrl: REST_SERVICE.files.mulFileSave,
      fileList: [], // 已经上传文件集合
      uploadParams: {}, // 上传文件附带参数
      checkAreaData: '', // 选中的区域（无用)
      areaData: [], // 区域数据
      bsdtData: [], // 所属区域对应的办事大厅数据
      formData: {
        xm: '',
        sfzh: '',
        bsdt: '',
        frxb: '',
      },
    };
  },
  computed: {
    ...mapGetters(['jgFirstStepData']),
  },
  mounted() {
    this.getAreaData();
    console.log(this.jgFirstStepData);
  },
  methods: {
    // 根据身份证获取用户的信息
    getDataFromCard(value) {
      const strCord = String(value);
      this.formData.frxb = this.getSex(strCord);
    },

    // 获取性别
    getSex(idCard) {
      let sexVal = '';
      if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexVal = 1; //男性
      } else if (parseInt(idCard.slice(-2, -1)) % 2 === 0) {
        sexVal = 2; // 女性
      } else {
        sexVal = '不详';
      }
      return sexVal;
    },

    // 获取区域数据
    getAreaData() {
      Promise.all([getAreaData(), getOrganBsdt(), getWorkOffice({ nSize: 10000 })]).then((res) => {
        const areaData = res[0].data;
        const orgData = res[1].data;
        const bsdtData = res[2].data.page;
        // 合并三个数据
        this.initBsdtData(areaData, orgData, bsdtData);
        this.areaData = areaData;
      });
    },
    // 维护区域办事大厅数据对应的区域信息
    maintainAreaForBsdt(orgData, bsdtData) {
      if (orgData === null || bsdtData === null) {
        return false;
      }
      let allOrgData = getAllNodes(orgData, 'childrens');
      for (let ii = 0; ii < bsdtData.length; ii++) {
        let bsdtItem = bsdtData[ii];
        for (let jj = 0; jj < allOrgData.length; jj++) {
          if (allOrgData[jj].code === bsdtItem.officeCode) {
            bsdtItem.areaId = allOrgData[jj].areaId;
            break;
          }
        }
      }
    },
    // 重新组织办事大厅数据
    initBsdtData(areaData, orgData, bsdtData) {
      //组织办事大厅区域信息
      this.maintainAreaForBsdt(orgData, bsdtData);
      //组织区域中的办事大厅数据
      let allAreaData = getAllNodes(areaData, 'childrens');
      for (let ii = 0; ii < allAreaData.length; ii++) {
        let areaItem = allAreaData[ii];
        areaItem.bsdtData = [];
        for (let jj = 0; jj < bsdtData.length; jj++) {
          if (areaItem.id === bsdtData[jj].areaId) {
            areaItem.bsdtData.push(bsdtData[jj]);
            bsdtData[jj].areaData = areaItem;
          }
        }
      }
    },
    // 区域改变时获取办事大厅的数据
    areaChange(val) {
      // 重置办事大厅
      this.formData.bsdt = '';
      // 找到选择的省
      const checkProvince = this.areaData.find((item) => item.code === val[0]);
      this.bsdtData = checkProvince.bsdtData || [];
      // 找到选择的市
      if (val.length > 1) {
        const checkCity = checkProvince.childrens.find((item) => item.code === val[1]);
        this.bsdtData = checkCity.bsdtData || [];
        // 找到选择的县
        if (val.length > 2) {
          const chcekCounty = checkCity.childrens.find((item) => item.code === val[2]);
          this.bsdtData = chcekCounty.bsdtData || [];
        }
      }
      this.formData.djjg = val[val.length - 1];
    },
    // 上传文件之前
    async beforeUpload(file) {
      // 判断文件是否重复
      const isExist = this.fileList.find((item) => item.fileName === file.name);
      if (isExist) {
        this.$message.warning('上传文件的文件名不可重复！');
        return Promise.reject();
      }
      //文件名命名规范
      let filetypes = ['~', '!', '@', '#', '$', '%', '^', '&', '*', '|', '-', ' '];
      let filename = file.name.substring(0, file.name.lastIndexOf('.'));
      for (let item of filetypes) {
        if (filename.indexOf(item) > 0) {
          item = item === ' ' ? '空格' : item;
          this.$message.warning(`上传文件的文件名不可包含${item}等特殊字符！`);
          return Promise.reject();
        }
      }
      const res = await getSystemTime();
      const systemTime = res.data;
      this.uploadParams = {
        dir: `qyzc/${this.jgFirstStepData.loginName}/${systemTime}`,
        recover: true,
      };
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res && res.success) {
        const { data } = res;
        this.fileList.push({
          id: data[0].info.id,
          fileName: data[0].info.fileName,
          url: data[0].info.filePathUrl,
          type: data[0].info.fileType,
        });
      }
    },
    // 下载文件
    downLoad(url) {
      window.location.href = url;
    },
    // 删除文件
    deleteFile(id, index) {
      delFileById({ id }).then((res) => {
        if (res && res.success) {
          this.fileList.splice(index, 1);
        }
      });
    },
    // 上传表单
    submit() {
      if (this.hasPermission('IEBDC:QYZC:FJXX') && this.fileList.length <= 0) {
        this.$message.warning('请上传相关证件信息！');
        return false;
      } else {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.authJg();
            /* this.$emit('callback', this.areaData);
            this.$store.dispatch('sign-in-step/getActiveStep', 2); */
          }
        });
      }
    },
    // 注册
    authJg() {
      // 取出上传文件的fileid
      this.formData.fileIds = this.fileList.map((item) => item.id).join(',');
      this.formData.loginName = this.jgFirstStepData.loginName;
      console.log(this.jgFirstStepData);
      this.formData.frdh = this.jgFirstStepData.lxdh;
      authJgUser(this.formData).then((res) => {
        if (res && res.success) {
          let finalData = {
            userName: this.jgFirstStepData.loginName,
            phone: this.jgFirstStepData.lxdh,
            frmc: this.formData.frmc,
            qymc: this.formData.qymc,
          };
          // 将finalData传递到完成页面
          this.$store.dispatch('sign-in-step/getJgSecondStepData', finalData);
          this.$store.dispatch('sign-in-step/getJgActiveStep', 2);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.jg {
  /* width: 150%;
  transform: translateX(-210px); */
  .el-image {
    position: relative;
    border: 1px solid #dcdfe6;
  }
  .file-content {
    position: relative;
    margin-left: 20px;
    .delete-file {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #f56c6c;
      color: white;
      z-index: 2;
      i {
        transform: translateY(-5px);
      }
    }
  }
}
</style>
