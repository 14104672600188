<template>
  <el-row
    class="applycation-top"
    :gutter="20"
    type="flex"
    justify="space-between"
  >
    <el-col
      :span="8"
      v-for="(item, index) in list"
      :key="index"
      @click.native="routeTo(item.path)"
    >
      <el-card
        v-if="hasPermission(item.permisssion)"
        class="content-corn"
        :style="{ 'align-items': alignItems }"
        :body-style="{ padding: 0 }"
      >
        <img :src="item.img" class="image" />
        <div class="name">
          <span class="name-title">{{ item.title }}</span>
          <span v-if="item.tip" class="name-info">{{ item.tip }}</span>
          <span v-else class="name-info">&nbsp;</span>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import hasPermission from '../../mixin/hasPermission';
export default {
  mixins: [hasPermission],
  props: ['list', 'alignItems'],
  data() {
    return {};
  },
  methods: {
    routeTo(path) {
      this.$emit('routeTo', path);
    }
  }
};
</script>
<style>
.name {
  text-align: center;
  margin-top: 43px;
}
.name-title {
  color: #222222;
  font-size: 26px;
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
}
.name-info {
  color: #666666;
  font-size: 18px;
  font-family: MicrosoftYaHeiLight;
  width: 296px;
  display: block;
  margin: 0 auto;
  line-height: 1.53846154;
}
.content-corn {
  float: left;
  width: 380px;
  height: 372px;
  -webkit-box-shadow: 0px 8px 16px 2px rgba(66, 66, 66, 0.2);
  box-shadow: 0px 8px 16px 2px rgba(66, 66, 66, 0.2);
  margin-right: 30px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applycation-top {
  display: inline-block !important;
  margin-top: 21px;
}
</style>
