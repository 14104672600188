/**
 * 首页路由配置
 */
export default {
  path: '/home/info-search/progress-query',
  name: 'progress-query',
  component: () => import('../../views/progress-query'),
  meta: {
    label: '进度查询',
    isNotMenu: true,
    icon: require('../../assets/images/bljdcx.png'),
    activeMenu: '/home/info-search',
    permission: 'IEBDC:JDCX', //permission: 'IEBDC:SY:XXCX:JDCX',
    construction: 'IEBDC:CX:JDCX', //construction: 'IEBDC:SY:XXCX:JDCX:SFZJ'
    // background: 'linear-gradient(to bottom right, #19EBE1 , #02ADBF)'
  }
};
