/**
 * 电子证照路由配置
 */
export default {
  path: '/home/info-search/electronic-license',
  name: 'electronic-license',
  component: () => import('iebdc/views/electronic-license/index1.vue'),
  meta: {
    label: '电子证照',
    isNotMenu: true,
    permission: 'IEBDC:SY:DZZZ', //IEBDC:SY:XXCX:DZZZ
    construction: 'IEBDC:SY:XXCX:DZZZ:SFZJ', //IEBDC:SY:XXCX:DZZZ:SFZJ
    activeMenu: '/home/info-search',
    icon: require('../../assets/images/wdzz.png'),
    // background: 'linear-gradient(to bottom right, #8C10F8 , #C079FF)'
  },
};
