let preantIds = [];

/**
 * 过滤筛选行政区数据
 * @param {*} data 全部数据
 * @param {*} code 被过滤的行政区代码
 */
export function filterArea(data, code) {
  const zjurl = window.CONFIG.APP_INFO.zjUrl;
  const areas1 = Object.keys(zjurl);
  const areas = [];
  data[0].childrens.forEach((item) => {
    item.childrens.forEach((res) => {
      res.bsdtData.forEach((bsd) => {
        if (areas1.includes(bsd.officeName)) {
          areas.push(res.code);
        }
      });
    });
  });
  unique(areas);
  // console.log(unique(areas), '12');
  // 需要保留的区域
  // const areas = [
  //   '650101',
  //   '650121',
  //   '652901',
  //   '652922',
  //   '652923',
  //   '652924',
  //   '652925',
  //   '652926',
  //   '652927',
  //   '652928',
  //   '652929',
  //   '653101',
  //   '653201',
  //   '652301',
  //   '652327'

  // ];
  code = `${code},${unique(areas).join(',')}`;
  preantIds = [];
  // 仅设置要显示的节点
  setVisibleArea(data, code);
  // console.log('===================== preantIds ', preantIds);

  // console.log('===================== 仅设置要显示的节点 data ', JSON.parse(JSON.stringify(data)));
  // 设置节点向上的数据
  setParentVisible(data);

  // 设置节点向下的数据
  setChildrenVisble(data, code);
  // console.log('===================== 设置节点向下的数据 data ', JSON.parse(JSON.stringify(data)));

  // console.log('===================== data ', data);
  return data;
}
// 去重
function unique(arr) {
  if (!Array.isArray(arr)) {
    console.log('type error!');
    return;
  }
  var array = [];
  for (var i = 0; i < arr.length; i++) {
    if (array.indexOf(arr[i]) === -1) {
      array.push(arr[i]);
    }
  }
  return array;
}

function getBool(codes, codeAim) {
  const codeArr = codes.split(',');
  return codeArr.indexOf(codeAim) > -1;
}

function setParentVisible(data) {
  if (Array.isArray(data)) {
    data.map((c) => {
      if (preantIds.indexOf(c.id) > -1) {
        c.visible = true;
      }
      if (c.childrens && c.childrens.length > 0) {
        setParentVisible(c.childrens);
      }
      return c;
    });
  } else {
    if (preantIds.indexOf(data.id) > -1) {
      data.visible = true;
    }
    if (data.childrens && data.childrens.length > 0) {
      setParentVisible(data.childrens);
    }
  }
}

function setVisibleAll(data) {
  data.map((c) => {
    c.visible = true;
    if (c.childrens && c.childrens.length > 0) {
      setVisibleAll(c.childrens);
    }
    return c;
  });
}

function setChildrenVisble(data, codes) {
  if (Array.isArray(data)) {
    data.forEach((c) => {
      if (codes.indexOf(c.code) > -1 && c.childrens && c.childrens.length > 0) {
        setVisibleAll(c.childrens);
      } else if (c.childrens && c.childrens.length > 0) {
        setChildrenVisble(c.childrens, codes);
      }
    });
  } else {
    if (codes.indexOf(data.code) > -1 && data.childrens && data.childrens.length > 0) {
      setVisibleAll(data.childrens);
    } else if (data.childrens && data.childrens.length > 0) {
      setChildrenVisble(data.childrens, codes);
    }
  }
}

function setVisibles(pids) {
  if (pids) preantIds = preantIds.concat(pids.split(','));
}

function setVisibleArea(data, code) {
  if (Array.isArray(data)) {
    data.map((c) => {
      c['visible'] = getBool(code, c.officeCode || c.code);
      if (c['visible']) setVisibles(c.pids);
      if (c.childrens && c.childrens.length > 0) {
        setVisibleArea(c.childrens, code);
      }
    });
  } else {
    data['visible'] = getBool(code, data.officeCode || data.code);
    if (data['visible']) setVisibles(data.pids);
    if (data.childrens && data.childrens.length > 0) {
      setVisibleArea(data.childrens, code);
    }
  }
}

export function filterAreaCode(arr, code) {
  let arr1 = [];
  let arr2 = [];
  let arr3 = [];
  // 需要保留的区域
  const areas = [
    '650101',
    '650121',
    '652901',
    '652922',
    '652923',
    '652924',
    '652925',
    '652926',
    '652927',
    '652928',
    '652929',
    '653101',
    '653201',
    '652301',
    '652327',
  ];
  code = `${code},${areas.join(',')}`;
  code = code.split(',');
  console.log(code, '12345');
  arr[0].childrens.forEach((ele) => {
    if (code.includes(ele.code)) {
      ele['visible'] = true;
      ele.childrens.forEach((res) => {
        res['visible'] = true;
      });
    } else if (ele.childrens) {
      ele.childrens.forEach((res) => {
        if (code.includes(res.code)) {
          ele['visible'] = true;
          res['visible'] = true;
        } else {
          ele['visible'] = false;
          res['visible'] = false;
        }
      });
    } else {
      ele['visible'] = false;
    }
  });
  console.log(arr, 'arr');
  return arr;
}

export function filterAreaAll(data) {
  console.log('filterAreaAll------', data);
  if (Array.isArray(data)) {
    data.map((c) => {
      if (Array.isArray(data)) {
        c.visible = true;
        if (c.childrens && c.childrens.length > 0) {
          setVisibleAll(c.childrens);
        }
      }
    });
    return data;
  } else {
    return [];
  }
}
