import apply from './apply'; // 我要申请
/**
 * 我要申请路由配置
 */
export default {
  path: '/home/check-in',
  name: 'check-in',
  // component: () => import('iebdc/views/check-in'),
  component: () => import('iebdc/views/apply/flow/registrationFor'),
  meta: {
    label: '我要申请',
    isNotMenu: true,
    activeMenu: '/home/wybl',
    permission: 'IEBDC:SY:WYSQ',
    construction: 'IEBDC:SY:DJBL:SFZJ',
    icon: require('../../assets/images/wysq.png'),
    isChildren: true
    // background: 'linear-gradient(to bottom right, #37A3FD , #2367F1)'
  },
  // redirect: '/home/check-in/registrationFor',
  children: [
    // {
    //   path: '/home/check-in/registrationFor',
    //   name: 'registrationFor',
    //   component: () => import('iebdc/views/apply/flow/registrationFor'),
    //   meta: {
    //     label: '在线办理',
    //     icon: 'document',
    //     isNotMenu: true,
    //     activeMenu: '/home/wybl',
    //     permission: 'IEBDC:GNXS:SY'
    //   }
    // },
    apply
  ]
};
