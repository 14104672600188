/**
 * 首页路由配置
 */
export default {
  path: '/home/example',
  name: 'example',
  meta: {
    label: '实例',
    icon: 'document',
  },
  component: {
    render: (h) => h('router-view'),
  },
  redirect: '/home/example/express',
  children: [
    {
      path: '/home/example/express',
      name: 'express',
      component: () => import('iebdc/views/express'),
      meta: {
        label: '我的邮寄',
      },
    },
    // {
    //   path: '/home/example/sub-example2',
    //   name: 'sub-example2',
    //   component: () => import('iebdc/views/example/sub-example2'),
    //   meta: {
    //     label: '实例2',
    //   },
    // },
    {
      path: '/home/example/wysq',
      name: 'wysq',
      component: () => import('iebdc/views/wysq'),
      meta: {
        label: '我要申请',
      },
    },
  ],
};
