<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="33%"
  >
    <div class="block">
      <span>请微信扫描下方二维码进行实名验证</span>
      <el-image :src="smsrc"></el-image>
      <span>
        <el-button @click="getzt" size="small" type="primary">刷新</el-button>
        <el-button @click="loginOut" size="small">退出登录</el-button>
      </span>
    </div>
  </el-dialog>
</template>
<script>
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
import { getEwm } from '@iebdc/api/register';
import { findUserCenter } from '@iebdc/api/user-server.js';
export default {
  data() {
    return {
      dialogVisible: false,
      userData: localDataUser.get(),
      smsrc: '',
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.getsmEwm();
      }
    },
  },
  methods: {
    // 获取二维码
    getsmEwm() {
      const params = {
        url: window.CONFIG.ewm_path_login,
        loginName: (this.userData.agentUser && this.userData.agentUser.loginName) || this.userData.loginName,
        intend: '123',
      };
      getEwm(params).then((res) => {
        if (res && res.success) {
          this.smsrc = res.data;
          this.dialogVisible = true;
        }
      });
    },
    //刷新实名状态
    getzt() {
      this.updateZt(() => {
        this.dialogVisible = false;
        location.reload();
      });
    },
    //获取用户详情
    //获取当前用户详情
    updateZt(callback) {
      findUserCenter({
        userName: this.userData.loginName,
      }).then((res) => {
        if (res.success && res.data) {
          if (Number(res.data.realNameStatus) === 1) {
            //判断是否验证
            let userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
            // 用户类型
            userInfo.userType = res.data.institutionalUser ? 'jg' : 'gr';
            // 同意联系电话字段
            userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
            // 用户是否实名注册
            userInfo.authName = res.data.authName;
            //代理人角色
            if (res.data.agentUser) {
              userInfo.agentUser = res.data.agentUser;
              userInfo.id = res.data.agentUser.id;
              userInfo.realNameStatus = res.data.agentUser.realNameStatus;
            }
            userInfo = Object.assign(localDataUser.get(), userInfo);
            localDataUser.set({
              ...userInfo,
            });
            callback();
          } else {
            this.$message.error('该用户还未实名成功');
          }
        } else {
          this.$message.error('获取实名状态失败');
        }
      });
    },
    // 退出
    loginOut() {
      localStorage.removeItem('IEBDC_ZWW_SX');
      sessionStorage.clear();
      localDataUser.clear();
      this.userData = localDataUser.set('');
      if (this.$hasPermission('IEBDC:DL:TYRZ')) {
        window.location.href = `${this.$decryptByDES(
          window.CONFIG.LOGIN_TO_ZWW.url
        )}/xjwwrz//rest/oauth2/logout?redirect_uri=${this.$decryptByDES(window.CONFIG.LOGIN_TO_ZWW.redirect_uri)}`;
      } else {
        this.$message.success('退出成功！');
        setTimeout(() => {
          location.reload(); //解决从首页登录后再从首页登出，页面不刷新问题
          this.$router.push('/home-login-out');
        }, 500);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
//弹框部分
.block {
  text-align: center;
  /deep/.el-image {
    width: 258px;
    height: 260px;
    border: 1px solid #ffffff;
  }
  span {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .rowdisplay {
    display: inline-block;
    width: 100%;
    padding: $spacing-base $spacing-medium;
    .el-col {
      margin: $spacing-base 0;
      /deep/ .el-form-item {
        margin: 0;
        .el-form-item__content {
          text-align: left;
          line-height: 29px !important;
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
