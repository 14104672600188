import { CacheObj } from '@iebdc/components/select-area/area-cahce.js';
/**
 * dashboard store
 * @description
 * 区域选择数据
 **/
const selectArea = {
  namespaced: true,
  state: {
    finalAreaData: [],
    keys: {
      selectedBsdtCode: 'selectedBsdtCode',
      selectedBsdtTitle: 'selectedBsdtTitle',
      selectedAreaCode: 'selectedAreaCode',
      selectedAreaTitle: 'selectedAreaTitle',
      selectedOrganizationsCode: 'selectedOrganizationsCode',
      selectedOrganizationsTitle: 'selectedOrganizationsTitle',
      selectedShiCode: 'selectedShiCode',
      selectedShiTitle: 'selectedShiTitle',
      selectedShengCode: 'selectedShengCode',
      selectedShengTitle: 'selectedShengTitle',
    },
    cacheName: 'cacheHasPermission',
    areaObj: {},
    areaCode: {},
    bsdtDialogVisible: false,
  },
  getters: {},
  mutations: {
    setFinalAreaData(state, payload) {
      state.finalAreaData = [...payload];
    },
    setArea(state, [value, flag]) {
      // 设置区域选择数据缓冲
      if (!flag) {
        CacheObj(state.cacheName).set(value);
        // 设置区域选择数据值
        state.areaObj = Object.assign(state.areaObj, value);
      } else if (flag) {
        state.cacheName = 'cache';
        CacheObj(state.cacheName).set(value);
        // 设置区域选择数据值
        state.areaObj = Object.assign(state.areaCode, value);
      }
    },
    isShowBsdtDialog(state, isShow) {
      // 设置弹框visible值
      state.bsdtDialogVisible = isShow;
    },
    setCacheName(state, cacheName) {
      state.cacheName = cacheName;
    },
  },
  actions: {
    // isShowBsdtDialog({ commit }, dialogVisible) {
    //   commit('isShowBsdtDialog', dialogVisible);
    // },
    // setArea({ commit }, value) {
    //   commit('setArea', value);
    // },
    // setCacheName({ commit }, cacheName) {
    //   commit('setCacheName', cacheName);
    // }
  },
};
export default selectArea;
