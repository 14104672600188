<template>
  <div>
    <el-form label-width="120px" ref="form" :model="formData">
      <el-form-item label="手机号：" prop="phone" :rules="[rules.phone, rules.required]">
        <el-input placeholder="请输入十一位手机号码" v-model="formData.phone"></el-input>
      </el-form-item>
      <el-form-item label="设置密码：" prop="Upwd" :rules="[rules.password, rules.required]">
        <el-input placeholder="6-20位字母、数字和符号两种或两种以上组合" v-model="formData.Upwd" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="Udpwd" :rules="[rules.password, rules.required, rules.samePassword(formData.Upwd)]">
        <el-input placeholder="再次输入密码" v-model="formData.Udpwd" show-password></el-input>
      </el-form-item>
      <!-- <el-form-item class="inpSjyzm" label="手机验证码：" prop="code" v-hasPermission="'IEBDC:DLZC:DXYZ'">
        <phone-code v-model="formData.code" :phone="formData.phone" :disabled="getCodeBtnDisabled"></phone-code>
      </el-form-item> -->
      <!-- <el-form-item label="验证码" prop="code" v-if="hasPermission('IEBDC:DLZC:DXYZ')">
        <div class="flex">
          <div>
            <el-input class="inpYzm" placeholder="请输入验证码" v-model="formData.code"></el-input>
          </div>
          <div class="pointer" @click="refreshCode">
            <s-identify :identifyCode="identifyCode"></s-identify>
            <img :src="codeUrl" @click="getVerCode" />
          </div>
          <div @click="refreshCode" class="pointer link">换一张</div>
        </div>
      </el-form-item> -->
      <el-form-item style="width: 800px;" v-hasPermission="'IEBDC:DLZC:DXYZ'">
        <el-checkbox label="我已经阅读同意" v-model="formData.bReadProtocol"></el-checkbox>
        <el-link class="zcxyLink" type="primary" :href="protocolUrl" target="_blank">《{{ systemName }}注册协议》</el-link>
      </el-form-item>
      <el-form-item class="text-center">
        <el-button @click="next" size="medium" :disabled="disabled" class="next-btn">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SIdentify from '../../login/component/identify';
import { crypto } from '../../../utils/crypto.js';
import { REST_SERVICE } from '../../../config';
import { fetchFun } from '../../../api/login';
import rules from '@/utils/validate.js';
// import { getCode } from '../../../api/register';
import hasPermission from '../../../mixin/hasPermission';
// import verCode from '../../../mixin/ver-code';

export default {
  name: 'create-account',
  mixins: [hasPermission],
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {
    SIdentify
  },
  data() {
    return {
      rules: rules,
      systemName: window.CONFIG.APP_INFO.systemName,
      protocolUrl: REST_SERVICE.register.protocol, // 协议书地址
      formData: {},
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      identifyCode: '',
      getCodeBtnText: '获取短信验证码',
      getCodeBtnDisabled: false // 获取验证码按钮禁用
    };
  },
  computed: {
    // 按钮禁用
    disabled() {
      return !this.formData.bReadProtocol;
    }
  },
  mounted() {
    this.identifyCode = '';
    if (!this.hasPermission('IEBDC:DLZC:DXYZ')) {
      // 验证码初始化
      // this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
      // this.getVerCode();
    }
  },
  methods: {
    // 新增验证码相关逻辑 start
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 新增验证码相关逻辑 end

    // 下一步(保存账号信息)
    next() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const grObj = {
            // authCode: this.formData.code,
            password: crypto.encryptByDES(this.formData.Upwd),
            areaCode: window.CONFIG.code
          };
          // 下面拼接的两个接口的参数不一样，所以分开写
          const jgObj = {
            // authCode: this.formData.code,
            pwd: crypto.encryptByDES(this.formData.Upwd),
            areaCode: window.CONFIG.code
          };
          const strUrl = this.type === 'gr' ? REST_SERVICE.register.rggr : REST_SERVICE.register.rgqy;
          const params = this.type === 'gr' ? { ...grObj, tel: this.formData.phone } : { ...jgObj, lxdh: this.formData.phone };
          fetchFun(strUrl, params, 'post').then((res) => {
            if (res && res.success) {
              // 拿到数据后发送给父组件
              // this.$emit('callback', res.data);
              // 拿到数据直接缓存进vuex，作为注册步骤的共享数据
              if (this.type === 'gr') {
                this.$store.dispatch('sign-in-step/getGrFirstStepData', res.data);
                this.$store.dispatch('sign-in-step/getGrActiveStep', 1);
              } else {
                this.$store.dispatch('sign-in-step/getJgFirstStepData', res.data);
                this.$store.dispatch('sign-in-step/getJgActiveStep', 1);
              }
            }
          });
        }
      });
    },
    // 验证手机号是否被注册过
    exsitLoginName(phone) {
      return new Promise((reslove) => {
        const strUrl = this.type === 'gr' ? REST_SERVICE.register.exsitGrLoginName : REST_SERVICE.register.exsitJgLoginName;
        const params = this.type === 'gr' ? { keyWord: phone } : { loginName: phone };
        fetchFun(strUrl, params).then((res) => {
          if (res && res.success) {
            reslove(res.data);
          } else {
            reslove(false);
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-checkbox {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.el-link {
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: bold;
}
</style>
