import request from '../utils/request';
import { REST_SERVICE } from '../config';
import { localDataDic } from '@iebdc/utils/local-data';

// 获取验证码
export const getCode = (params) =>
  request({
    url: REST_SERVICE.register.getCode,
    method: 'post',
    data: params,
  });
// 获取二维码
export const getEwm = (params) =>
  request({
    url: REST_SERVICE.register.getEwm,
    method: 'get',
    params,
  });
// 获取字典值
export const getSystemDic = (params) => {
  request({
    url: REST_SERVICE.hall.getSystemDic,
    method: 'get',
    params,
  }).then((res) => {
    if (res.success) {
      localDataDic.set({
        dic: res.data,
      });
    }
  });
};
// 验证个人用户
export const authGrUser = (params) =>
  request({
    url: REST_SERVICE.register.grrz,
    method: 'post',
    data: params,
  });
// 获取区域数据
export const getAreaData = (params) =>
  request({
    url: REST_SERVICE.uums.area,
    method: 'get',
    params,
  });
// 获取机构数据
export const getOrganBsdt = (params) =>
  request({
    url: REST_SERVICE.uums.getOrganBsdt,
    method: 'get',
    params,
  });
// 获取办事大厅数据
export const getWorkOffice = (params) =>
  request({
    url: REST_SERVICE.register.getWorkOffice,
    method: 'get',
    params,
  });
// 获取系统时间
export const getSystemTime = (params) =>
  request({
    url: REST_SERVICE.hall.getSystemTime,
    method: 'get',
    params,
  });
// 删除文件
export const delFileById = (params) =>
  request({
    url: REST_SERVICE.files.delFileById,
    method: 'get',
    params,
  });
// 验证企业用户
export const authJgUser = (params) =>
  request({
    url: REST_SERVICE.register.qyrz,
    method: 'post',
    data: params,
  });

export const institutionalApprove = (data) =>
  request({
    url: REST_SERVICE.register.institutionalApprove,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
