/**
 * 首页路由配置
 */
export default {
  path: '/home/my-appointment',
  name: 'my-appointment',
  component: () => import('iebdc/views/my-appointment'),
  meta: {
    label: '我的预约',
    isNotMenu: true,
    // permission: 'IEBDC:SY:WDYY'
    permission: 'IEBDC:WDYY'
  }
};
