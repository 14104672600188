/**
 * 我的缴费路由配置
 */
export default {
  path: '/home/bswd',
  name: 'bswd',
  component: () => import('iebdc/views/dashboard'),
  meta: {
    label: '办事网点',
    permission: 'IEBDC:BSWD'
  }
};
