// 姓名验证
export const validateName = (rule, value, callback) => {
  const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
  if (!value) {
    return callback(new Error('请填写姓名'));
  } else if (!reg.test(value)) {
    return callback(new Error('请填写正确的姓名'));
  }
  callback();
};
// 身份证号验证
export const validateZjh = (func) => {
  return (rule, value, callback) => {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!value) {
      return callback(new Error('请输入您的身份证号'));
    } else if (!reg.test(value)) {
      return callback(new Error('身份证号格式不对'));
    } else {
      if (typeof func === 'function') {
        func(value);
      }
      callback();
    }
  };
};
// 组织机构验证
export const validateZzjg = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入正确的企业证件号'));
  }
  const re1 = /(?:(?![IOZSV])[\dA-Z]){9}$/;
  const re2 = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]$/;
  if (re1.test(value) || re2.test(value)) {
    callback();
  } else {
    return callback(new Error('请输入正确的企业证件号'));
  }
};
