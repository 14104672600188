<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="60%"
  >
    <div class="block">
      <span>请完善下方用户信息进行企业认证</span>
      <div class="main">
        <el-form ref="form" class="form" :model="smrzData" label-width="100px">
          <el-row class="rowdisplay flex-center-align" justify="space-between">
            <el-col>
              <el-form-item label="用户角色" prop="roleName" :rules="[rules.required]">
                <el-radio-group v-model="smrzData.roleName">
                  <el-radio label="FINANCIAL">银行</el-radio>
                  <el-radio label="KFS">开发商</el-radio>
                  <el-radio label="FKFQY">普通企业</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="所属区域" prop="zlData.zlArea" :rules="[rules.required]">
                <zl-directives v-model="smrzData.zlData" :zlConfig="zlConfig"></zl-directives>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="身份证明：" :rules="[rules.required]">
                <span>请上传法人身份证(正反两面)以及营业执照</span>
                <el-upload
                  :data="uploadParams"
                  list-type="picture-card"
                  name="file"
                  accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                  :file-list="smrzData.uploadfiles"
                  ref="upload"
                  class="upload-demo"
                  :action="submitUrl"
                  :auto-upload="true"
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                >
                  <i class="el-icon-plus"></i>
                  <div class="upload-img" slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.fileUrl" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="open(file.fileUrl)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="saveSmrz" size="small" type="primary">保存</el-button>
      <el-button @click="loginOut" size="small">退出登录</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
import { findUserCenter } from '@iebdc/api/user-server.js';
import { REST_SERVICE } from '@iebdc/config';
import { delFileById, institutionalApprove } from '@iebdc/api/register';
export default {
  data() {
    return {
      dialogVisible: false,
      userData: localDataUser.get(),
      smrzData: {
        uploadfiles: [],
        zlData: {
          zlProvince: '',
          zlCity: '',
          zlArea: '',
        },
      },
      submitUrl: REST_SERVICE.files.mulFileSave,
      uploadParams: {}, // 上传文件附带参数
      zlConfig: {
        zlKey: 'code',
        rules: [],
        hideZl: true,
        disabled: false,
      },
    };
  },
  methods: {
    //获取当前用户详情
    updateZt(callback) {
      findUserCenter({
        userName: this.userData.loginName,
      }).then((res) => {
        if (res.success && res.data) {
          //判断是否验证
          let userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
          // 用户类型
          userInfo.userType = res.data.institutionalUser ? 'jg' : 'gr';
          // 同意联系电话字段
          userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
          // 用户是否实名注册
          userInfo.authName = res.data.authName;
          //代理人角色
          if (res.data.agentUser) {
            userInfo.agentUser = res.data.agentUser;
            userInfo.id = res.data.agentUser.id;
            userInfo.realNameStatus = res.data.agentUser.realNameStatus;
          }
          userInfo = Object.assign(localDataUser.get(), userInfo);
          localDataUser.set({
            ...userInfo,
          });
          callback();
        } else {
          this.$message.error('获取实名状态失败');
        }
      });
    },
    //提交企业实名信息
    saveSmrz() {
      if (this.smrzData.uploadfiles.length <= 0) {
        this.$message.error('请上传身份认证材料');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.smrzData.userId = this.userData.id;
          this.smrzData.djjg = this.smrzData.zlData.zlArea;
          institutionalApprove(this.smrzData).then((res) => {
            if (res.success && res.data) {
              this.updateZt(() => {
                this.dialogVisible = false;
                location.reload();
              });
            } else {
              this.$message.error('获取实名状态失败');
            }
          });
        }
      });
    },
    // 退出
    loginOut() {
      localDataUser.clear();
      localStorage.removeItem('IEBDC_ZWW_SX');
      this.userData = localDataUser.set('');

      if (this.$hasPermission('IEBDC:DL:TYRZ')) {
        window.location.href = `${this.$decryptByDES(
          window.CONFIG.LOGIN_TO_ZWW.url
        )}/xjwwrz//rest/oauth2/logout?redirect_uri=${this.$decryptByDES(window.CONFIG.LOGIN_TO_ZWW.redirect_uri)}`;
      } else {
        this.$message.success('退出成功！');
        setTimeout(() => {
          location.reload(); //解决从首页登录后再从首页登出，页面不刷新问题
          this.$router.push('/home-login-out');
        }, 500);
      }
    },
    // 上传文件之前
    async beforeUpload(file) {
      //判断文件是否重复;
      const isExist = this.smrzData.uploadfiles.find((item) => item.clmc === file.name);
      if (isExist) {
        this.$message.warning('上传文件的文件名不可重复！');
        return Promise.reject();
      }
      // //文件名命名规范
      // let filetypes = ['~', '!', '@', '#', '$', '%', '^', '&', '*', '|', '-', ' '];
      // let filename = file.name.substring(0, file.name.lastIndexOf('.'));
      // for (let item of filetypes) {
      //   if (filename.indexOf(item) > 0) {
      //     item = item === ' ' ? '空格' : item;
      //     this.$message.warning(`上传文件的文件名不可包含${item}等特殊字符！`);
      //     return Promise.reject();
      //   }
      // }
      const systemTime = Date.parse(new Date());
      this.uploadParams = {
        dir: `qyzc/${this.userData.loginName}/${systemTime}`,
        recover: true,
      };
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res && res.success) {
        const { data } = res;
        this.smrzData.uploadfiles.push({
          id: data[0].info.id,
          clmc: data[0].info.fileName,
          url: data[0].info.filePathUrl,
          fileUrl: data[0].info.filePathUrl,
          type: data[0].info.fileType,
        });
      }
    },
    // 文件上传移除
    handleRemove(filedata) {
      this.smrzData.uploadfiles.map((item, i) => {
        if (item.clmc === filedata.clmc && item.url === filedata.url) {
          this.smrzData.uploadfiles.splice(i, 1);
        }
      });

      // delFileById({ id: file.id }).then((res) => {
      //   if (res && res.success) {
      //     this.smrzData.uploadfiles.splice(index, 1);
      //   }
      // });
    },
    open(url) {
      window.open(url);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
//弹框部分
.block {
  text-align: center;
  /deep/.el-image {
    width: 258px;
    height: 260px;
    border: 1px solid #ffffff;
  }
  span {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .rowdisplay {
    display: inline-block;
    width: 100%;
    padding: $spacing-base $spacing-medium;
    .el-col {
      margin: $spacing-base 0;
      /deep/ .el-form-item {
        margin: 0;
        .el-form-item__content {
          text-align: left;
          line-height: 29px !important;
        }
      }
    }
  }
}
.upload-img {
  line-height: 150px;
}
</style>
