/**
 * 首页路由配置
 */
export default {
  path: '/home/my-application',
  name: 'my-application',
  component: () => import('iebdc/views/my-application'),
  meta: {
    label: '我的申请',
    isNotMenu: true,
    // permission: 'IEBDC:SY:WDSQ'
    permission: 'IEBDC:WDSQ'
  }
};
