/**
 * 首页路由配置
 */
export default {
  path: '/home/xxfw/public-announcement',
  name: 'public-announcement',
  component: () => import('iebdc/views/public-announcement'),
  meta: {
    label: '公示公告',
    // isNotMenu: true,
    // activeMenu: '/home/xxfw',
    // permission: 'IEBDC:SY:XXFW:GSGG',
    permission: 'IEBDC:GGGS',
    construction: 'IEBDC:SY:XXFW:GSGG:SFZJ',
    // icon: 'icongonggao',
    // background: 'linear-gradient(to bottom right, #C079FF , #8C10F8)'
  }
};
