/**
 * app store
 * @description
 * 主模块的状态存储
 */
export default {
    namespaced: true,
    state: {
      // 用户类型 - setUserType
      userType: null
    },
    mutations: {
      setUserType(state, type) {
        console.log(type,'vuex----')
        state.userType = type;
      }
    },
    actions: {
        setUserType({ commit }, type) {
        commit('setUserType', type);
      }
    }
  };
  