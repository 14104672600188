/**
 * 首页路由配置
 */
export default {
  path: '/home/xxfw/file-download',
  name: 'file-download',
  component: () => import('iebdc/views/file-download'),
  meta: {
    label: '资料下载',
    // isNotMenu: true,
    // activeMenu: '/home/xxfw',
    // permission: 'IEBDC:SY:XXFW:ZLXZ',
    permission: 'IEBDC:ZLXZ',
    construction: 'IEBDC:SY:XXFW:ZLXZ:SFZJ',
    // icon: 'iconxiazai',
    // background: 'linear-gradient(to bottom right, #FFA318 , #FD5C14)'
  }
};
