<template>
  <div class="find-warp">
    <!-- <div class="title">
      <i class="el-icon-back fl"></i>
      <span @click="back" class="pointer fl">返回登录</span>
      <span>找回密码</span>
    </div> -->
    <div class="form-content">
      <el-form label-width="140px" :model="formData" ref="form">
        <el-form-item label="请输入手机号：" prop="tel" :rules="[rules.required, rules.phone]">
          <el-input v-model="formData.tel"></el-input>
        </el-form-item>
        <el-form-item label="手机验证码：" prop="authCode" :rules="[rules.required]">
          <phone-code v-model="formData.authCode" :phone="formData.tel"></phone-code>
        </el-form-item>
        <el-form-item label="请输入新密码：" prop="newpassWord" :rules="[rules.password, rules.required]">
          <el-input autocomplete="new-password" show-password v-model="formData.newpassWord"></el-input>
        </el-form-item>
        <el-form-item label="再次输入新密码：" prop="newpassWords" :rules="[rules.password, rules.required, rules.samePassword(formData.newpassWord)]">
          <el-input autocomplete="new-password" show-password v-model="formData.newpassWords"></el-input>
        </el-form-item>
        <el-form-item class="text-center">
          <el-button style="width: 50%" type="primary" @click="submit">
            确认修改
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { changePsw } from '@/pages/iebdc/api/login';
import { crypto } from '@/pages/iebdc/utils/crypto.js';
import rules from '@/utils/validate.js';
export default {
  name: 'find-password',
  props: {
    userType: String
  },
  data() {
    return {
      rules: rules,
      formData: {},
      getCodeBtnText: '获取短信验证码'
    };
  },
  methods: {
    back() {
      // 重置表单
      this.$refs.form.resetFields();
      this.$router.push('/home-login-out');
      // this.$emit('backLogin');
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            tel: this.formData.tel,
            authCode: this.formData.authCode,
            passWord: this.formData.newpassWord,
            passWords: this.formData.newpassWords,
            areaCode: window.CONFIG.code,
            userCategory: this.userType
          }; 
          changePsw(params).then((res) => {
            if (res && res.success) {
              this.$confirm('密码修改成功,跳转登陆！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.back();
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-checkbox {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.el-link {
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: bold;
}
/* .fl {
  float: left;
}
.find-warp {
  margin-top: 30px;
  .title {
    padding: 10px 0 20px 20px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    color: #353535;
    i {
      font-size: 18px;
      transform: translateY(1px);
    }
    span {
      font-size: 18px;
    }
  }
  .form-content {
    padding: 20px 230px 0 230px;
  }
} */
</style>
