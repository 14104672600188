<template>
  <div class="main">
    <!-- 表格区域 -->
    <el-table
      ref="elTable"
      class="base-table"
      :row-key="rowKey"
      :data="tableData"
      :size="size"
      :border="border"
      :stripe="stripe"
      :default-sort="defaultSort"
      :height="height"
      :min-height="minHeight"
      :max-height="maxHeight"
      :show-summary="showSummary"
      :show-header="showHeader"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      @row-click="rowClick"
      @selection-change="selectionChange"
    >
      <slot name="column-start" />
      <el-table-column v-if="selection" type="selection" align="center" />
      <el-table-column v-if="order && tableData.length" width="60" type="index" align="center" label="序号" />
      <!-- 循环普通列 -->
      <template v-for="column in columns">
        <el-table-column
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :sortable="column.sortable"
          :show-overflow-tooltip="showOverflowTooltip"
          :align="columnAlign"
        >
          <template slot-scope="scope">
            <!-- 字典格式化 -->
            <template v-if="column.type === 'dic'">
              {{ formatDic(column, scope.row) }}
            </template>
            <template v-else-if="column.formatter">
              <span v-html="column.formatter(scope.row, column, scope.row[column.prop])"></span>
            </template>
            <template v-else>
              <span>{{ scope.row[column.prop] }}</span>
            </template>
          </template>
        </el-table-column>
      </template>
      <!-- 操作列 -->
      <slot name="column-end"></slot>
    </el-table>
  </div>
</template>

<script>
/**
 * 基础表格
 *
 * @prop {Array} data 表格数据，example：[{ [prop]: String }]
 * @prop {Array} columns 表格栏目，example：[{ prop: String, label: String, width: Number, sortable: Boolean }]
 * @prop {Number} total 列表项总数，默认读取 data.length
 * @prop {Function} lazyLoad 懒加载数据（滚到底部加载），`(page, pageSize) => {}`，默认静态分页
 * @prop {Number} pageSize 分页大小，默认 0，表示无分页
 * @prop {Boolean} border 是否有边框，默认有
 * @prop {Boolean} stripe 是否斑马条纹，默认有
 * @prop {String} size 表格大小，默认 mini
 * @prop {Boolean} order 是否有序号，默认有
 * @prop {Object} defaultSort 默认排序，example：{ prop: 'area', order: 'descending' }
 * @prop {String} height 表格高度
 * @prop {String} maxHeight 表格最大高度
 * @prop {Boolean} showSummary 是否展示总计行，默认 false
 * @prop {Boolean} showHeader 是否展示表头
 * @prop {Boolean} autoMergeCells 是否自动合并单元格
 * @prop {Boolean} selection 是否可以勾选
 */
export default {
  name: 'index',
  props: {
    rowKey: String,
    selection: Boolean,
    showOverflowTooltip: {
      type: Boolean,
      default: false
    },
    columnAlign: {
      type: String,
      default: 'center'
    },
    data: Array,
    columns: Array,
    total: Number,
    border: {
      type: Boolean,
      default: false
    },
    stripe: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    defaultSort: Object,
    height: String,
    minHeight: {
      type: String,
      default: '750px'
    },
    maxHeight: String,
    showSummary: {
      type: Boolean,
      default: false
    },
    order: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    headerCellStyle: {
      type: Object,
      default: () => {
        return {
          height: '62px',
          fontSize: '14px',
          fontWeight: 400,
          color: '#333333',
          background: '#f2f6fc'
        };
      }
    },
    cellStyle: {
      type: Object,
      default: () => {
        return {
          height: '83px',
          fontSize: '14px',
          fontWeight: 400,
          color: '#666666'
        };
      }
    },
    tableConfig: {
      type: Object,
      default: () => {
        return {};
      }
    },
    tableIndex: Number
  },
  data() {
    return {};
  },
  computed: {
    // 当前数据
    tableData() {
      return this.data;
    }
  },
  methods: {
    rowClick(...params) {
      this.$emit('row-click', ...params);
    },
    selectionChange(...params) {
      this.$emit('selection-change', ...params);
    },
    //字典格式化
    formatDic(column, row) {
      return this.$store.getters.getLabelDicByTypeAndValue(column.label, row[column.prop]);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/common-variables';
.main {
  margin-bottom: 20px;
  .base-table {
    /deep/ thead {
      th {
        background: #f6f6f6 !important;
      }
      th.gutter {
        display: table-cell !important;
      }
    }
  }
}
</style>
