/**
 * 首页路由配置
 */
export default {
  path: '/home/appointment/service-appointment',
  name: 'service-appointment',
  component: () => import('iebdc/views/service-appointment'),
  meta: {
    label: '我要预约',
    isNotMenu: true,
    activeMenu: '/home/wybl',
    permission: 'IEBDC:SY:WYYY',
    construction: 'IEBDC:SY:WSYY:WYYY:SFZJ',
    icon: require('../../assets/images/wyyy.png')
    // background: 'linear-gradient(to bottom right, #37A3FD , #2367F1)'
  }
};
