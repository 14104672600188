<template>
  <div>
     <div v-if="pageData.page && pageData.page.length > 0">
        <div v-if="!isDeatil">
          <ul class="page-item" v-for="(item, i) in pageData.page" :key="i" @click="showDetail(item.id)">
            <el-row >
              <el-col :span="21">
                  {{ item.title }}
              </el-col>
              <!-- <el-col class="outer" :span="16" v-for="(data, i) in detailContentSent" :key="i">
                <span  v-if="item.id == data.id.id" >
                  <span  v-html="data.detail" @click="showDetail(data.id.id)"></span>
                </span>
              </el-col> -->
              <el-col :span="3">
                 {{item.ctime | formatDate('yyyy-MM-dd')}}
              </el-col>
            </el-row>
          </ul>
        </div>
        <div v-if="isDeatil">
          <article>
            <div class="text-left back">
              <el-link type="primary" @click="back" class="el-icon-back"> 返回 </el-link>
            </div>
            <h1 class="text-center">{{ detailData.subTitle }}</h1>
            <div class="address text-center" >
              发布时间：<span>{{ detailData.ctime | formatDate('yyyy-MM-dd') }}</span
              ><span v-show="detailData.mainWriter"
                >&nbsp;&nbsp;&nbsp;&nbsp; 作者：<span>{{ detailData.mainWriter }}</span></span
              ><span v-show="detailData.mainAuthor"
                >&nbsp;&nbsp;&nbsp;&nbsp; 来源：<span>{{ detailData.mainAuthor }}</span></span
              >
            </div>
            <div class="policy-content" v-html="detailData.contents"></div>
          </article>
        </div>
      </div>
      <div v-else class="no-data">暂无数据</div>
      <div class="pagination-content" v-if="!isDeatil">
        <EPagination
          class="pagination"
          :total="pageData.recordCount"
          :pageSize="pageSize"
          :current="currentPage"
          @currentPageClick="currentPageClickHandle"

        />
    </div>
  </div>
</template>

<script>
import EPagination from '@/components/e-pagination/index';
import { getPolicyData, getPolicyDetail } from '@iebdc/api/policy-service';


export default {
  name: 'ReportList',
  components: {EPagination},
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      },
    },
    detailContentSent:{
      type: Array
    }
  },
  data(){
    return {
      pageList:[],
      pageshow: true,
      pageSize: 10,
      currentPage: 1,
      AllData:[],
      detailData:[],
      isDeatil: false
    }
  },
  updated(){
    console.log(this.pageData);
    console.log(this.detailContentSent);
  },
  methods: {
    // 查看文章详细内容
    showDetail(id) {
      getPolicyDetail({ id }).then((res) => {
        if (res && res.data) {
          this.detailData = res.data;
          console.log(this.detailData);
          this.isDeatil = true;
        }
      });
    },
    back() {
      this.isDeatil = false;
    },
    setCurrentPage(value) {
      this.pageshow = false;
      this.currentPage = value;
    },
    currentPageClickHandle(val) {
      this.$emit('pageChange', val);
    },
  }
}
</script>

<style  lang="scss" scoped>
  ul{
    list-style: none;
  }
  .outer p{
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
  .outer p:not(:first-child){
    display: none;
  }
  .page-item {
    position: relative;
    height: 50px;
    line-height: 50px;
    border-bottom: dashed 1px #cccccc; 
    &:hover {
      background-color: #9bc8ff;
      color: #fff !important;
      .color2C8BFF {
        color: #fff !important;
      }
    }
    // .hover {
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
  }
  .pagination-content {
    margin-top: 20px;
    text-align: center;
    padding-bottom: 12px;
  }
  .address{
    margin: 20px;
  }
  .policy-content{
    margin-top: 30px;
    min-height: 100px;
  }
</style>