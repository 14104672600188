<template>
  <el-dialog
    title="请选择您的登录身份"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="33%"
    center
  >
    <div class="block">
      <div class="main">
        <el-form ref="form" class="form" :model="jbrListData">
          <el-row class="rowdisplay">
            <el-col>
              <el-form-item>
                <div class="biaoti">
                  <i class="el-icon-s-custom"></i><i style="margin-right: 10px"></i>自然人
                </div></el-form-item
              >
            </el-col>
          </el-row>
          <el-row class="rowdisplay">
            <el-col>
              <el-form-item>
                <div style="width: 100%; height: 100%; background-color: #f4f6f9">
                  <el-radio v-model="selectClicked" :label="jbrListData.agentuser">{{
                    $decryptByDES(jbrListData.agentuser.name)
                  }}</el-radio>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="rowdisplay">
            <el-col>
              <el-form-item>
                <div class="biaoti">
                  <i class="el-icon-office-building"></i><i style="margin-right: 10px"></i>企业经办人
                </div></el-form-item
              >
            </el-col>
          </el-row>
          <el-row class="rowdisplay">
            <el-col>
              <el-form-item v-for="(item, index) in jbrListData.institutionaluser" :key="index">
                <div style="width: 100%; height: 100%; background-color: #f4f6f9; margin-top: 10px">
                  <el-radio v-model="selectClicked" :label="item">{{ $decryptByDES(item.name) }}</el-radio>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row class="rowdisplay flex-center-align" justify="space-between">
            <el-form-item>
              <div>
                <el-button @click="loginOut" size="small">退出</el-button>
                <el-button @click="savejbr" size="small" type="primary">确定</el-button>
              </div>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
import { getAgentUserQyUser, getAgentUserByQyId } from 'iebdc/api/my-operator';
import store from '@iebdc/store/index';
export default {
  data() {
    return {
      dialogVisible: false,
      userData: localDataUser.get(),
      selectClicked: {}, //被选择的经办人
    };
  },
  computed: {
    jbrListData() {
      return store.getters['jbrlist-data/getJbrListData'];
    },
  },
  mounted() {
    console.log(store);
    console.log(this);
    if (this.jbrListData.agentuser.name == this.userData.name) {
      this.selectClicked = this.jbrListData.agentuser;
      return;
    }
    this.jbrListData.institutionaluser.map((item) => {
      if (item.name == this.userData.name) {
        this.selectClicked = item;
      }
    });
  },
  methods: {
    // 退出
    loginOut() {
      this.dialogVisible = false;
    },
    //切换
    savejbr() {
      if (this.selectClicked.name == this.userData.name) {
        this.dialogVisible = false;
        return;
      }
      //自然人切换
      if (this.selectClicked.hasOwnProperty('agentCategory')) {
        // getAgentUserQyUser({ accid: this.selectClicked.accId }).then((res) => {
        //   if (res.success && res.data != []) {
        //     console.log(res.data);
        //   } else {
        //     this.$message.error(res.message);
        //   }
        // });
        //从企业切换成自然人时,直接提取保存在localDataUser中保存的自然人信息
        let paramsOld = this.userData.oldUserData;
        paramsOld.jbrListData = this.userData.jbrListData;
        localDataUser.set({
          ...paramsOld,
        });
        location.reload();
      } else {
        //企业切换
        getAgentUserByQyId({ qyid: this.selectClicked.id, name: this.jbrListData.agentuser.name }).then((res) => {
          if (res.success && res.data) {
            let paramsNew = res.data[0].institutionalUser;
            paramsNew.jbrData = res.data[0];
            //保存请求的首次登录个人请求的经办人信息
            paramsNew.jbrListData = this.jbrListData;
            //切换前,保存登录的自然人的信息
            if (this.userData.userType == 'gr') {
              paramsNew.oldUserData = this.userData;
            } else {
              paramsNew.oldUserData = this.userData.oldUserData;
            }
            paramsNew.token = localDataUser.get().token;
            //添加其他请求需要字段
            paramsNew.userType = 'jg';
            paramsNew.tel = paramsNew.lxdh;
            console.log('===', paramsNew);
            paramsNew.userName = paramsNew.loginName;
            paramsNew.realName = paramsNew.loginName;
            delete paramsNew.jbrData.institutionalUser;
            //存放在localDataUser
            localDataUser.set({
              ...paramsNew,
            });
            this.dialogVisible = false;
            location.reload();
          } else {
            console.log(111112323);
            this.$message.error('获取数据失败');
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
//弹框部分

// /deep/.el-dialog {
//   display: flex;
//   flex-direction: column;
//   margin: 0 !important;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// /deep/.el-dialog .el-dialog__body {
//   flex: 1;
//   overflow: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .rowdisplay {
    flex: 1;
    // display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: $spacing-base $spacing-medium;
    .el-col {
      flex: 1;
      // margin: $spacing-base 0;
      /deep/ .el-form-item {
        margin: 0;
        /deep/.el-form-item__content {
          text-align: left;
          line-height: 29px !important;
          // padding-left: -50px;
          margin: 0;
        }
      }
    }
  }
}

.biaoti {
  font-size: 16px;
  color: rgb(141, 136, 136);
}
</style>
