import 'babel-polyfill';
import Vue from 'vue';

import App from './App.vue';
import store from './store';
import router from './router';
import { localDataPermission } from '@iebdc/utils/local-data';
import Bus from './utils/bus.js';
Vue.use(Bus);
//引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
// 全局组件
import './components';
import '../../components';

// 插件
import './plugins/element.js';
// 样式
import './styles/index.scss';

// 自定义指令
import './directive';
import Slsm from './directive/slsm-dialog/slsm';
Vue.prototype.$slsm = Slsm.install;
import Qyrz from './directive/qyrz-dialog/qyrz';
Vue.prototype.$qyrz = Qyrz.install;
import Jbr from './directive/jbr-dialog/jbr';
Vue.prototype.$jbr = Jbr.install;
// 自定义过滤器
import * as filters from '../../filters';
import rules from '@/utils/validate.js';
import utils from '@/utils/utils';
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

import { crypto } from '@iebdc/utils/crypto';
Vue.prototype.$encryptByDES = (strMessage, key) => {
  return crypto.encryptByDES(strMessage, key);
};
Vue.prototype.$decryptByDES = (strMessage, key) => {
  return crypto.decryptByDES(strMessage, key);
};
Vue.prototype.$plusXing = (str) => {
  return utils.plusXing(str);
};

Vue.prototype.rules = rules;
//全局-判断是否有权限
Vue.prototype.$hasPermission = (strPermission) => {
  if (String(strPermission) === 'true') return true;
  let allPermission = localDataPermission.get();
  if (!allPermission) return;
  if (allPermission.length > 0 && allPermission.indexOf(strPermission) > -1) {
    return true;
  } else {
    return false;
  }
};

Vue.prototype.$hasConstruction = (strConstruction) => {
  let allConstruction = localDataPermission.get();
  if (!allConstruction) return;
  if (allConstruction.length > 0 && allConstruction.indexOf(strConstruction) > -1) {
    return true;
  } else {
    return false;
  }
};
Vue.directive('read-excel', {
  inserted: (el, { value }) => {
    const id = Date.now();
    const input = document.createElement('input');
    el['read-excel-id'] = id;
    input.id = id;
    input.type = 'file';
    input.accept = '.xlsx, .xls';
    input.onchange = ({
      target: {
        files: [excel],
      },
    }) => {
      if (!excel) return;
      const XLSX = require('xlsx');
      const reader = new FileReader();
      reader.onload = async ({ target: { result } }) => {
        const workbook = XLSX.read(result, { type: 'array' });
        value && value(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]));
      };
      reader.readAsArrayBuffer(excel);
    };
    input.style.display = 'none';
    document.body.appendChild(input);
    el.addEventListener('click', () => document.getElementById(id).click());
  },
  unbind: (el) => document.getElementById(el['read-excel-id']).remove(),
});

// mock 请求配置（如果需要就去掉注释）
// if (process.env.NODE_ENV === 'development') {
//   // mock 服务
//   require('./mock');
// }

Vue.config.productionTip = false;

// 清除 dupilcateNavigation的错误
import Router from 'vue-router';
// import dashboard from './router/modules/dashboard';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

new Vue({
  el: '#app',
  store,
  router,
  render: (h) => h(App),
});
