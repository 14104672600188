<template>
  <div class="caption text-center">
    <span class="letter">{{ letter }}</span>
    <div class="tab-contents">
      <span class="title">———————</span>
      {{ title }}
      <span class="title">———————</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    letter: { type: String },
    title: { type: String }
  }
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';
.caption {
  padding-bottom: 10px;
  font-size: 14px;
  .letter {
    color: #3bbbf2;
    font-weight: bold;
  }
  .tab-contents {
    margin: $spacing-large;
    font-size: 24px;
    font-weight: bold;
    color: #666666;
    .title {
      color: #c9c9c9;
      margin: 50px;
    }
  }
}
</style>
