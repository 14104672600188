import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 添加代理人
export const addAgent = (params) =>
  request({
    url: REST_SERVICE.jbr.addDigitalAgent,
    method: 'post',
    data: params,
  });
// 查找代理人列表
export const getAgentsByUserId = (params) =>
  request({
    url: REST_SERVICE.jbr.getDigitalAgentList,
    method: 'post',
    params,
  });
// 修改代理人
export const updateAgent = (params) =>
  request({
    url: REST_SERVICE.jbr.updateDigitalAgent,
    method: 'post',
    data: params,
  });
export const getAgentUserList = (params) =>
  request({
    url: REST_SERVICE.jbr.getAgentUserList,
    method: 'post',
    data: params,
  });

// 删除代理人
export const deleteAgent = (params) =>
  request({
    url: REST_SERVICE.jbr.deleteDigitalAgent,
    method: 'post',
    data: params,
  });

// 获取经办人角色
export const getDigitalAgentRole = (params) =>
  request({
    url: REST_SERVICE.jbr.getDigitalAgentRole,
    method: 'get',
    params,
  });

export const getAgentUserQyUser = (params) =>
  request({
    url: REST_SERVICE.jbr.getAgentUserQyUser,
    method: 'post',
    data: params,
  });
export const getAgentUserByQyId = (params) =>
  request({
    url: REST_SERVICE.jbr.getAgentUserByQyId,
    method: 'post',
    data: params,
  });
