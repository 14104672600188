// 我要申请步骤条数据处理
import { localDataPermission, localDataUser } from '@iebdc/utils/local-data';
import { getCookie } from '@iebdc/utils/cookies';
import { step } from '@public/sq.js';
const stepL = JSON.parse(JSON.stringify(step));

export default {
  namespaced: true,
  state: {
    /**
     * @params index 当前步骤条索引
     * @params title 当前步骤条标题
     * @params name 当前步骤条路由名称
     * @params permission 当前步骤条权限
     * @params code 流程代码在这个里面才需要去走核验
     * */
    //所有配置的步骤条
    stepList: stepL,
    //当前筛选的步骤条
    currentSelecte: [],
    // 当前激活步骤条
    activeItem: null,
    // 当前激活步骤条索引
    activeIndex: 0,
    // 步骤条下一步需要跳转的信息
    nextItem: null,
    //上一步需要跳转的信息
    prevItem: null,
  },
  getters: {
    // 获取步骤条数据，过滤没有权限的数据
    // getStepList: (state) => (currentSelectedFlowCode) => {
    //   const permissionArr = localDataPermission.get() || [];
    //
    //   // 根据权限过滤步骤条数据
    //   return state.stepList.filter((step) => permissionArr.includes(step.permission) && !step.hasOwnProperty('code'));
    // }
  },
  mutations: {
    getStepList(state, data) {
      let stepData = [...state.stepList];
      const currentSelectedFlowCode = data.code;
      const currentSelectedName = data.currentSelectedName;
      const permissionArr = localDataPermission.get() || [];
      if (currentSelectedFlowCode === 'N200104' && currentSelectedName === '新建商品房买卖') {
        stepData = stepData.filter((item) => item.title !== '楼盘表提取');
      }
      let filterSomeArray = stepData.filter(
        (step) =>
          (step.code && step.code.indexOf(currentSelectedFlowCode) > -1) ||
          (step.companyCode && step.companyCode.indexOf(currentSelectedFlowCode) > -1)
      );
      if (
        filterSomeArray.some((item) => item.title === '楼盘表提取') &&
        filterSomeArray.some((item1) => item1.title === '产权证核验')
      ) {
        if (getCookie('selectedAreaCode') === '654026' || getCookie('selectedAreaCode') === '650100') {
          stepData = stepData.filter((item2) => item2.title !== '产权证核验');
        } else {
          stepData = stepData.filter((item2) => item2.title !== '楼盘表提取');
        }
      }
      if (
        getCookie('selectedAreaCode') !== '654026' &&
        getCookie('selectedAreaCode') !== '650100' &&
        filterSomeArray.some((item) => item.title === '楼盘表提取')
      ) {
        stepData = stepData.filter((item2) => item2.title !== '楼盘表提取');
        stepData[1].code.push(currentSelectedFlowCode);
      }
      // 根据权限过滤步骤条数据
      state.currentSelecte = [
        ...stepData.filter((step) => {
          if (data.code === '') {
            return permissionArr.includes(step.permission) && !step.hasOwnProperty('code');
          } else {
            if (step.hasOwnProperty('areaCode')) {
              console.log(step.areaCode.toString());
              console.log(step.areaCode.indexOf(getCookie('selectedAreaCode')) > -1);
            }
            console.log('step.permission', permissionArr.includes(step.permission), step.permission);
            // if (localDataUser.get().userType === 'gr') {
            //   return (
            //     //动态显示产权核验/提取楼盘表步骤
            //     //有权限的步骤条&&（拿到当前选择code值所在的步骤条||没有code属性的步骤条）

            //     permissionArr.includes(step.permission) &&
            //     ((step.hasOwnProperty('code') && step.code.indexOf(data.code) > -1) || !step.hasOwnProperty('code')) &&
            //     (((data.step === 'VERIFICATION' || data.step === 'VERIFYSUCCESS') &&
            //       step.name !== 'intelligentAudit') ||
            //       (data.step !== 'VERIFICATION' && data.step !== 'VERIFYSUCCESS')) &&
            //     ((step.hasOwnProperty('areaCode') && step.areaCode.indexOf(getCookie('selectedAreaCode')) > -1) ||
            //       !step.hasOwnProperty('areaCode'))
            //   );
            // } else if (localDataUser.get().userType === 'jg') {
            //   //企业用户转移登记  子类流程走楼盘表提取
            //   if (
            //     step.hasOwnProperty('areaCode') &&
            //     step.areaCode.indexOf(getCookie('selectedAreaCode')) > -1 &&
            //     !(currentSelectedFlowCode === 'N200104' && currentSelectedName === '新建商品房买卖')
            //   ) {
            //     // 无产权证核验 有楼盘表
            //     // if (step.permission === 'IEBDC:SQ:LPBTQ' && !step.code.includes(currentSelectedFlowCode)) {
            //     //   step.code.push(currentSelectedFlowCode);
            //     // }
            //     if (step.permission === 'IEBDC:SQ:CQZHY' && step.code.includes(currentSelectedFlowCode)) {
            //       step.code.splice(step.code.indexOf(currentSelectedFlowCode), 1);
            //     }
            //   } else {
            //     console.log(step, 'step----------------------');
            //     //无楼盘表 有产权证核验
            //     if (
            //       step.permission === 'IEBDC:SQ:CQZHY' &&
            //       !step.code.includes(currentSelectedFlowCode) &&
            //       step.name !== 'landCertify' &&
            //       step.name !== 'propertySeize' &&
            //       step.name !== 'propertyUnseal'
            //     ) {
            //       step.code.push(currentSelectedFlowCode);
            //     }
            //     if (step.permission === 'IEBDC:SQ:LPBTQ' && step.code.includes(currentSelectedFlowCode)) {
            //       step.code.splice(step.code.indexOf(currentSelectedFlowCode), 1);
            //     }
            //   }
            console.log(
              step.permission,
              (permissionArr.includes(step.permission) &&
                step.hasOwnProperty('code') &&
                step.code.indexOf(currentSelectedFlowCode) > -1) ||
                (step.hasOwnProperty('companyCode') && step.companyCode.indexOf(currentSelectedFlowCode) > -1) ||
                !step.hasOwnProperty('code')
            );

            return (
              (permissionArr.includes(step.permission) &&
                step.hasOwnProperty('code') &&
                step.code.indexOf(currentSelectedFlowCode) > -1) ||
              (step.hasOwnProperty('companyCode') && step.companyCode.indexOf(currentSelectedFlowCode) > -1) ||
              !step.hasOwnProperty('code')
            );

            // // permissionArr.push('IEBDC:SQ:LPBTQ');
            // // if (step.permission === 'IEBDC:SQ:LPBTQ' && step.companyCode.includes(data.code)) {
            // //   step.code.push(data.code);
            // // }

            // return (
            //   //动态显示产权核验/提取楼盘表步骤
            //   //有权限的步骤条&&（拿到当前选择code值所在的步骤条||没有code属性的步骤条）

            //   permissionArr.includes(step.permission) &&
            //   ((step.hasOwnProperty('companyCode') && step.companyCode.indexOf(data.code) > -1) ||
            //     !step.hasOwnProperty('code')) &&
            //   (((data.step === 'VERIFICATION' || data.step === 'VERIFYSUCCESS') &&
            //     step.name !== 'intelligentAudit') ||
            //     (data.step !== 'VERIFICATION' && data.step !== 'VERIFYSUCCESS')) &&
            //   ((step.hasOwnProperty('areaCode') && step.areaCode.indexOf(getCookie('selectedAreaCode')) > -1) ||
            //     !step.hasOwnProperty('areaCode'))
            // );
            // }
          }
        }),
      ];
      console.log(state.currentSelecte);
      // if (['N200108', 'N200110', 'N200104'].includes(data.code)) {
      //   let currentSelecteNewData = $.extend(true, [], state.currentSelecte);
      //   currentSelecteNewData.map((item, index) => {
      //     if (item.name === 'authorizedCertify') {
      //       console.log('筛选前', state.currentSelecte);
      //       state.currentSelecte.splice(index, 1);
      //     }
      //   });
      //   console.log(state.currentSelecte);
      // }
      // console.log("======",state.currentSelecte);
    },
    // 跳转到指定步骤条
    targetToStep(state, name) {
      // 找到步骤需要跳转的步骤条
      if (state.currentSelecte) {
        // 找到当前激活的步骤
        // 当前激活步骤
        state.activeItem = state.currentSelecte.find((step, index, arr) => {
          if (step.title === name) {
            // 赋值当前激活的索引
            state.activeIndex = index;
            // 下一步跳转所需数据
            state.nextItem = arr[index + 1];
            state.prevItem = arr[index - 1];
            return step;
          }
        });
      }
    },
  },
};
