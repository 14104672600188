import { login, zwloginByToken } from '@iebdc/api/zww-login.js';
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
import { userInfo } from '@iebdc/api/login';
import store from '@iebdc/store/index';
import { getAppInfo, getUserInfoByUserId } from '@iebdc/api/user-server.js';
import { crypto } from '@iebdc/utils/crypto';
import { REST_SERVICE } from '../config';
let activeType = '';
let userTypes = ['gr', 'jg'];
let userName = '';
let userId = '';
var zwwLogin = {
  analysisUrl(url, loading, callback, callbackNo) {
    let dz_url = url.split('?');
    if (dz_url.length > 1) {
      let cs = dz_url[1];
      if (cs.split('#/').length > 1) {
        let cs_arr = cs.split('#/')[0].split('&');
        let params = {};
        for (let i = 0; i < cs_arr.length; i++) {
          //遍历数组，拿到json对象
          params[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1];
        }
        if (typeof params.code != 'undefined') {
          if (url.indexOf('ZWWWYCX') > -1) {
            sessionStorage.setItem('userRoleId', window.CONFIG.APP_INFO.KSCXroleId);
            sessionStorage.setItem('isAbled', 1);
          }
          if (params.commonareacode || params.code) {
            console.log('params', params);
            let code = params.commonareacode || params.code;
            loading(code);
          }

          this.zwwLogin(params.code, callback);
        } else {
          callbackNo();
        }
      } else {
        let cs_arr = cs.split('&');
        let params = {};
        for (let i = 0; i < cs_arr.length; i++) {
          //遍历数组，拿到json对象
          params[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1];
        }
        if (typeof params.code != 'undefined') {
          sessionStorage.setItem('userRoleId', window.CONFIG.APP_INFO.KSroleId);
          sessionStorage.setItem('isAbled', 1);
          // loading(params.commonareacode);
          if (params.commonareacode || params.code) {
            console.log('params', params);
            let code = params.commonareacode || params.code;
            loading(code);
          }
          this.zwwLogin(params.code, callback, callbackNo);
        } else {
          callbackNo();
        }
      }
    } else {
      // callbackNo();
    }
  },
  zwwLogin(code, callback, callbackNo) {
    const params = {
      code: code,
      client_id: window.CONFIG.LOGIN_TO_ZWW.client_id,
      redirect_uri: crypto.decryptByDES(window.CONFIG.LOGIN_TO_ZWW.redirect_uri),
    };
    login(params).then((res) => {
      if (res != null && res.access_token != null && res.refresh_token != null) {
        const param = {
          host: '192.168.1.49',
          token: res.access_token,
          retoken: res.refresh_token,
        };
        this.zwwLoginByToken(param, callback);
      } else {
        callbackNo();
        // setTimeout(() => {
        //   window.history.replaceState(
        //     '',
        //     '',
        //     process.env.NODE_ENV === 'production' ? 'iebdc.html#/home-sign-in' : 'iebdc#/home-sign-in'
        //   );
        //   location.reload();
        //   // this.$router.push('/home-sign-in');
        // }, 50);
      }
    });
  },
  zwwLoginByToken(params, callback) {
    zwloginByToken(params).then((res) => {
      if (res.success) {
        userName = res.data.user.realName;
        userId = res.data.user.userInfo.id;
        const parameter = {
          token: res.data.token,
        };
        activeType = res.data.usertype === '1' ? 0 : 1;
        console.log(this);
        //   store.state.setUserType = activeType;

        store.dispatch('user-type/setUserType', activeType);
        // store.dispatch('setUserType', activeType);
        localDataUser.set({ token: res.data.token });

        this.getUserInfo(res.data.user.loginName, parameter, callback);
      }
    });
  },
  getUserInfo(username, parameter, callback) {
    userInfo({ loginName: username }).then((res) => {
      if (res && res.success) {
        //判断是否验证
        const userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
        // 用户类型
        //   userInfo.userType = 'gr';
        userInfo.userType = userTypes[activeType];
        userInfo.username = userName;

        userInfo.zjzl = userInfo.zjzl == null || userInfo.zjzl == 'null' ? '1' : userInfo.zjzl;
        // 同意联系电话字段
        userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
        // 用户是否实名注册
        userInfo.authName = res.data.authName;
        let userCategory = userInfo.userCategory;
        console.log('userInfo', userInfo);
        if (userCategory === 'WSMRZ') {
          localDataUser.clear();
          new Vue()
            .$confirm('该用户未实名认证，请进行实名认证！', '提示', {
              confirmButtonText: '立即认证',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
              window.history.replaceState(
                '',
                '',
                process.env.NODE_ENV === 'production' ? 'iebdc.html#/sign-in' : 'iebdc#/sign-in'
              );
              // 跳转到注册页面中的实名认证部分
              this.$router.push({
                path: '/sign-in',
                name: 'sign-in',
                query: {
                  type: userTypes[activeType].value,
                  userInfo: userInfo,
                },
              });
              setTimeout(() => {
                location.reload();
              }, 300);
              // this.$router.push({
              //   name: 'sign-in',
              //   path: '/sign-in',
              //   query: {
              //     type: this.userType[this.activeType].value,
              //   },
              //   params: {
              //     userInfo,
              //   },
              // });
              //   store.state.setUserType = activeType;
              //   this.$store.dispatch('sign-in-step/getGrFirstStepData', userInfo);
              //   this.$store.dispatch('sign-in-step/getJgFirstStepData', userInfo);
            })
            .catch(() => {
              // 退回到登录页面
              // this.$router.push({ name: 'home-login-out' });
              // setTimeout(() => {
              //   window.history.replaceState(
              //     '',
              //     '',
              //     process.env.NODE_ENV === 'production' ? 'iebdc.html#/home-sign-in' : 'iebdc#/home-sign-in'
              //   );
              //   location.reload();
              //   this.$router.push('/home-sign-in');
              // }, 300);
            });
        } else {
          // 调用checkTokenByAppKey验证状态
          this.checkTokenHandler(parameter, userInfo, callback);
        }
      }
    });
  },
  checkTokenHandler(parameter, userInfo, callback) {
    const params = {
      userId: userId,
      userName: userName,
    };
    Promise.all([getAppInfo(), getUserInfoByUserId(params, userInfo)]).then(() => {
      // this.$message.success('登录成功！');
      callback();
      // this.loading = false;

      // if (localStorage.getItem('IEBDC_ZWW_SX')) {
      //   setTimeout(() => {
      //     window.history.replaceState(
      //       '',
      //       '',
      //       process.env.NODE_ENV === 'production' ? 'iebdc.html#/home/check-in1' : 'iebdc#/home/check-in1'
      //     );
      //     location.reload();
      //     // this.$router.push({ path: '/home/check-in1' });
      //   }, 50);
      // } else {
      //   window.history.replaceState(
      //     '',
      //     '',
      //     process.env.NODE_ENV === 'production' ? 'iebdc.html#/home-sign-in' : 'iebdc#/home-sign-in'
      //   );
      //   location.reload();
      //   //   this.$router.push('/home-sign-in');
      // }
    });
  },
};

export default zwwLogin;
