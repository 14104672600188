/**
 * 首页路由配置
 */
export default {
  path: '/home/connect',
  name: 'connect',
  component: () => import('iebdc/views/connect'),
  meta: {
    isNotMenu: true,
    permission: 'IEBDC:GNXS:DBLJ'
  }
};
