/**
 * 首页路由配置
 */
export default {
  path: '/home/xxfw/guidance',
  name: 'guidance',
  component: () => import('iebdc/views/guidance'),
  meta: {
    label: '办事指南',
    // isNotMenu: true,
    // activeMenu: '/home/xxfw',
    // permission: 'IEBDC:SY:XXFW:BSZN',
    permission: 'IEBDC:BSZN',
    construction: 'IEBDC:SY:XXFW:BSZN:SFZJ',
    // icon: 'iconzhinan',
    // background: 'linear-gradient(to bottom right, #19EBE1 , #02ADBF)'
  },
};
