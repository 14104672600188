<template>
  <div class="bread-menu-wrap flex-space-between" v-if="isShow">
    <div class="bread-menu-wrap-left flex-center-align">
      当前位置:&nbsp;&nbsp;
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(level, index) in levelList" :key="index">
          <router-link :to="level.path">{{ level.label }}</router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="bread-menu-wrap-right" v-if="levelList.length > 2">
      <span @click="goBack()">返回上级</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonBreadcrumb',
  data: function () {
    return {
      levelList: [],
    };
  },
  watch: {
    $route(to) {
      this.levelList = [];
      to.matched.forEach((item) => {
        if (!item.meta) return;
        this.levelList.push({
          path: item.path,
          label: item.meta.label,
        });
      });
    },
  },
  computed: {
    isShow() {
      try {
        return !sessionStorage.getItem('userRoleId');
      } catch (error) {
        return true;
      }
    },
  },
  mounted() {
    this.levelList = [];
    console.log(this.$route.matched, '56236');
    this.$route.matched.forEach((item) => {
      if (!item.meta) return;
      this.levelList.push({
        path: item.path,
        label: item.meta.label,
      });
    });
  },
  methods: {
    goBack() {
      let url = '';
      console.log(this.$route.path, '8562');
      if (this.$route.redirectedFrom) {
        //处理重定向的上级
        url = this.levelList[this.levelList.length - 3].path;
      } else {
        url = this.levelList[this.levelList.length - 2].path;
      }
      // 解决产权核验，楼盘表提取页面点击当一步回到阅读须知页面后点击返回上一级的问题
      if (this.$route.query === 1) {
        console.log('走这里');
        url = this.levelList[this.levelList.length - 3].path;
      }
      console.log(url, 'url');
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.bread-menu-wrap {
  line-height: 40px;
  font-size: 13px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #e5e5e5;
  .bread-menu-wrap-right {
    span {
      color: #145ccd;
      cursor: pointer;
    }
  }
}
</style>
