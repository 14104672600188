import checkInRoute from './check-in'; // 我要申请
import checkInRoute1 from './check-in1';
import serviceAppointment from './service-appointment'; // 我要预约
import visitionService from './visiting-service'; // 上门服务
import onlinePay from './online-pay'; //我的缴费
/**
 * 路由配置
 */
export default {
  path: '/home/wybl',
  name: 'wybl',
  component: () => import('iebdc/views/wybl'),
  meta: {
    label: '我要办理',
    // permission: 'IEBDC:SY:DJBL',
    permission: 'IEBDC:SY:DJBL',
    construction: 'IEBDC:SY:DJBL:SFZJ'
  },
  children: [checkInRoute, checkInRoute1, onlinePay, serviceAppointment, visitionService]
};
