import request from '../utils/request';
import { REST_SERVICE } from '../config';

const unifiedService = {
  //token验证
  getAccessToken: function(data) {
    return request({
      url: REST_SERVICE.unified.getAccessToken,
      method: 'post',
      data
    });
  }
};

export default unifiedService;
