<template>
  <footer class="flex-center footer">
    <!-- <img src="../assets/images/dashboard/dzjg.png" alt="党政机关" /> -->
    <!-- <p class="vertical-bar"></p> -->
    <div class="footer-wrap flex">
      <!-- <div class="contact-block">
         <div style="width: 210px; float: left; " class="left">
          <div style="height: 83px; margin-bottom: 3px; background: #fff; padding: 15px 0 0 10px" >
            <router-link :to="'/home/call'" class="contact-label" style="cursor: pointer; height: 20px;margin-left: -80px" >联系我们 </router-link>
          </div>
          <div style="height: 83px; background: #fff; padding: 0px 0 0 10px">
            <p class="contact-label" style="cursor: pointer; height: 20px">业务咨询</p>
            <p class="contact-tel" style="cursor: pointer; height: 20px">{{ rxfw }}</p>
          </div>
        </div>
        <div style="width: 160px; float: left; margin-left: 3px" class="right">
          <div style="height: 83px; margin-bottom: 3px; background: #fff; padding: 15px 0 0 10px">
            <img src="https://zwfw.xinjiang.gov.cn/images/kefu.png" style="float: left" />
            <router-link :to="'/home/connect'" style="padding: 8px">反馈建议 </router-link>
          </div>
          <div style="height: 83px; background: #fff; padding: 15px 0 0 10px">
            <img src="https://zwfw.xinjiang.gov.cn/images/zhinan.png" style="float: left" />
            <router-link :to="'/home/newman-guide'" style="padding: 8px">操作指南 </router-link>
          </div>
        </div>
      </div> -->
      <div>
        <div style="width: 210px; float: left" class="left">
          <div class="contant-block" style="margin-bottom: 3px">
            <!-- <router-link :to="'/home/call'" class="contact-label left-text" style="cursor: pointer"
              >联系我们
            </router-link> -->
            <p @click="callMe" class="contact-label left-text" style="cursor: pointer">联系我们</p>
          </div>
          <div class="contant-block">
            <p class="contact-label left-t-text" style="cursor: pointer; height: 20px">业务咨询</p>
            <p class="contact-tel left-b-text" style="cursor: pointer; height: 20px">{{ rxfw }}</p>
          </div>
        </div>
        <div style="width: 160px; float: left; margin-left: 3px" class="right">
          <div style="height: 83px; margin-bottom: 3px; background: #fff; padding: 15px 0 0 10px">
            <img src="https://zwfw.xinjiang.gov.cn/images/kefu.png" style="float: left" />
            <router-link :to="'/home/connect'" class="right-text">反馈建议 </router-link>
          </div>
          <div style="height: 83px; background: #fff; padding: 15px 0 0 10px">
            <img src="https://zwfw.xinjiang.gov.cn/images/zhinan.png" style="float: left" />
            <router-link :to="'/home/newman-guide'" class="right-text">操作指南 </router-link>
          </div>
        </div>
      </div>
      <ul class="qrshow-list">
        <li class="qrshow-item" @mouseover="mouseOver(1)" @mouseleave="mouseLeave(1)">
          <p class="qrcode-name">新疆政务服务APP</p>
          <div class="qrcode-show" v-if="flag">
            <img src="https://zwfw.xinjiang.gov.cn/images/qrcode-app.png" />
            <span class="qrcode-explain">新疆政务服务APP</span>
          </div>
        </li>
        <li class="qrshow-item" @mouseover="mouseOver(2)" @mouseleave="mouseLeave(2)">
          <p class="qrcode-name">新疆政务服务微信小程序</p>
          <div class="qrcode-show" v-if="flag1">
            <img src="https://zwfw.xinjiang.gov.cn/images/xiaochxu.png" />
            <span class="qrcode-explain">新疆政务服务微信小程序</span>
          </div>
        </li>
        <li class="qrshow-item" @mouseover="mouseOver(3)" @mouseleave="mouseLeave(3)">
          <p class="qrcode-name">新疆政务服务支付宝小程序</p>
          <div class="qrcode-show" v-if="flag2">
            <img src="https://zwfw.xinjiang.gov.cn/images/alipay.png" />
            <span class="qrcode-explain">新疆政务服务支付宝小程序</span>
          </div>
        </li>
      </ul>
      <!-- <div class="gov-links">
        <div class="img" style="float: left; width: 227px; height: 168px; background: #fff">
          <a
            href="https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=6500000086&url=http%3A%2F%2Fzwfw.xinjiang.gov.cn%2F"
            class="a"
          >
            <div>
              <img src="https://zwfw.xinjiang.gov.cn/images/cavil.png" alt="" />
            </div>
          </a>
        </div>
        <div class="img" style="float: left; width: 144px; height: 168px; margin-left: 2px; background: #fff">
          <a href="http://bszs.conac.cn/sitename?method=show&id=21A846E334DB75DEE053012819ACDF92" class="b">
            <div>
              <img src="https://zwfw.xinjiang.gov.cn/images/red.png" alt="" />
            </div>
          </a>
        </div>
      </div> -->
    </div>
    <div class="footer-info-item">
      <!--       <p>
        <router-link :to="'/home/about'">关于本网 </router-link>
        <span class="space">|</span>
        <router-link :to="'/home/statement'">本站声明 </router-link>
        <span class="space">|</span>
        <router-link :to="'/home/newman-guide'">帮助中心 </router-link>
        <span class="space">|</span>
        <router-link :to="'/home/connect'">反馈建议</router-link>
      </p> -->
      <p>
        <span class="copyright-content">主办单位：{{ zbdw }}</span
        ><span class="copyright-content">建设单位：{{ jsdw }}</span>
        <!-- <span class="copyright-content">运行单位：{{ yxdw }}</span> -->
        <span class="copyright-content">备案编号：{{ babh }}</span>
      </p>
      <!--<p>
        本系统支持正版谷歌浏览器，支持IE10以上版本、火狐以及360浏览器
         <span class="margin-base"><img class="badge" src="../assets/images/dashboard/hz.png" alt="徽章" />{{ gwab }}<span class="margin-base">政府网站标识码：{{ zhengf }}</span></span>
       </p> -->
      <!-- <p>
         业务咨询：{{ rxfw }}
      <span class="margin-base">工作时间:上午：{{ gzTimeAM }},下午：{{ gzTimePM }}</span>
      </p> -->
    </div>
  </footer>
</template>

<script>
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
export default {
  data() {
    return {
      address: window.CONFIG.APP_INFO.address,
      systemphone: window.CONFIG.APP_INFO.systemphone,
      zbdw: window.CONFIG.APP_INFO.zbdw,
      jsdw: window.CONFIG.APP_INFO.jsdw,
      babh: window.CONFIG.APP_INFO.babh,
      yxdw: window.CONFIG.APP_INFO.yxdw,
      userData: localDataUser.get(),
      beianxh: window.CONFIG.APP_INFO.beianxh,
      gwab: window.CONFIG.APP_INFO.gwab,
      zhengf: window.CONFIG.APP_INFO.zhengf,
      rxfw: window.CONFIG.APP_INFO.rxfw,
      gzTimeAM: window.CONFIG.APP_INFO.gzTimeAM,
      gzTimePM: window.CONFIG.APP_INFO.gzTimePM,
      flag: false,
      flag1: false,
      flag2: false,
    };
  },
  methods: {
    // 移入
    a() {
      console.log(123522525);
      this.$emit('getLxwm');
      // this.$emit()
    },
    mouseOver(num) {
      if (num === 1) {
        this.flag = true;
      } else if (num === 2) {
        this.flag1 = true;
      } else if (num === 3) {
        this.flag2 = true;
      }
    },
    callMe() {
      this.$alert(
        '1、账号注册登录的问题请联系新疆维吾尔自治区人民政府网新疆政务服务网，联系电话：0991-7531791<br/>2、具体业务咨询请致电12345转各地不动产登记中心<br/>3、系统故障问题请联系新疆维吾尔自治区不动产登记中心，可以致电12345转自治区不动产登记中心或加qq群：205706559',
        '提示',
        {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          callback: (action) => {},
        }
      );
    },
    // 移出
    mouseLeave(num) {
      if (num === 1) {
        this.flag = false;
      } else if (num === 2) {
        this.flag1 = false;
      } else if (num === 3) {
        this.flag2 = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/common-variables.scss';
footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 330px;
  color: #343434;
  background: #e5e6ec;
  border-top: 1px solid #e3e6e8;
  font-size: 15px;
  .contant-block {
    height: 83px;
    background: #fff;
    padding: 0px 0 0 10px;
  }
  .left:first-child {
    margin-bottom: 3px;
  }
  .right .right-text {
    position: absolute;
    margin: 18px 0 0 8px;
  }
  .left .left-t-text {
    position: absolute;
    margin: 10px 0 0 30px;
  }
  .left .left-b-text {
    position: absolute;
    margin: 36px 0 0 30px;
  }
  .left .left-text {
    position: absolute;
    margin: 23px 0 0 32px;
  }
  .contact-label {
    // padding: 12px 0 14px 0;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    // margin-left: 25px;
  }
  .contact-tel {
    height: 40px;
    // padding-bottom: 9px;
    line-height: 40px;
    // margin-left: 25px;
  }
  .vertical-bar {
    width: 1px;
    height: 100px;
    border-right: 1px solid #ffff;
    margin: $spacing-large;
  }
  .footer-wrap {
    margin: 52px 0 49px 0;
    display: flex;
    margin-left: $spacing-base;
    a:hover(:not(.left a)) {
      border-bottom: #fff 2px solid;
    }
    p {
      line-height: 30px;
    }
    .space {
      margin: $spacing-base;
    }
    .margin-base {
      margin-left: $spacing-base;
    }
    .badge {
      position: relative;
      bottom: -3px;
      margin-right: $spacing-base;
    }
    .qrshow-list {
      margin: 0 20px 0;
      list-style: none;
    }
    .qrshow-item {
      position: relative;
      width: 373px;
      background: #fff;
      margin-bottom: 3px;
    }
    .qrcode-name {
      height: 54px;
      line-height: 54px;
      padding-left: 58px;
      background: url(../assets/images/qr-code.png) no-repeat left 20px center;
      cursor: pointer;
    }
    .qrcode-show {
      position: absolute;
      width: 220px;
      height: 220px;
      padding-top: 32px;
      background: #fff;
      box-shadow: 0px 0px 6px 0px #d6d5d4;
      top: -231px;
      left: 0;
      text-align: center;
      img {
        width: 134px;
        height: 134px;
      }
      .qrcode-explain {
        display: block;
        height: 54px;
        line-height: 54px;
        text-align: center;
      }
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 10px solid;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        bottom: -20px;
        left: 30px;
      }
    }
  }
}
/*   .contact-block {
    display: flex;
    width: 373px;
    height: 168px;
    margin-right: 41px;
    padding: 26px 20px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
      }
      a {
        width: 125px;
        text-align: center;
        display: block;
        // margin-left: 20px;
      }
    }
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        display: block;
        width: 140px;
        height: 70px;
        div {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
      .a {
        width: 52px;
        height: 64px;
      }
    }
    .centerBox {
      width: 1px;
      height: 100%;
      background-color: #eeeeee;
    }
  } */
.contact-block {
  float: left;
  width: 373px;
  height: 168px;
  margin-right: 41px;
  .left {
    p {
      display: block;
      height: 40px;
      // line-height: 40px;
      margin-left: 25px;
      box-sizing: initial;
    }
  }
  a {
    font-size: 14px;
    text-align: center;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-left: 25px;
    box-sizing: initial;
  }
  .contact-label {
    padding: 12px 0 14px 0;
    font-size: 16px;
    font-weight: bold;
  }
}
.gov-links {
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    .a {
      display: block;
      width: 140px;
      height: 70px;
      div {
        width: 100%;
        height: 100%;
      }
    }
    .b {
      display: block;
      width: 52px;
      height: 64px;
      div {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.footer-info-item {
  p {
    height: 50px;
    color: #666;
    line-height: 60px;
  }
  .copyright-content {
    display: inline-block;
    height: 50px;
    color: #666;
    line-height: 60px;
    margin-right: 45px;
    font-size: 14px;
  }
}
</style>
