/**
 * sign-in-step store
 * @description
 * 注册所进行到的步骤
 */
export default {
  namespaced: true,
  state: {
    grActiveStep: 0,
    jgActiveStep: 0,
    grFirstStepData: {},
    grSecondStepData: {},
    jgFirstStepData: {},
    jgSecondStepData: {}
  },
  mutations: {
    setGrActiveStep(state, activeStep) {
      state.grActiveStep = activeStep;
    },
    setJgActiveStep(state, activeStep) {
      state.jgActiveStep = activeStep;
    },
    setGrFirstStepData(state, data) {
      state.grFirstStepData = { ...data };
    },
    setGrSecondStepData(state, data) {
      state.grSecondStepData = { ...data };
    },
    setJgFirstStepData(state, data) {
      state.jgFirstStepData = { ...data };
    },
    setJgSecondStepData(state, data) {
      state.jgSecondStepData = { ...data };
    }
  },
  actions: {
    getGrActiveStep({ commit }, activeStep) {
      commit('setGrActiveStep', activeStep);
    },
    getJgActiveStep({ commit }, activeStep) {
      commit('setJgActiveStep', activeStep);
    },
    getGrFirstStepData({ commit }, data) {
      commit('setGrFirstStepData', data);
    },
    getGrSecondStepData({ commit }, data) {
      commit('setGrSecondStepData', data);
    },
    getJgFirstStepData({ commit }, data) {
      commit('setJgFirstStepData', data);
    },
    getJgSecondStepData({ commit }, data) {
      commit('setJgSecondStepData', data);
    }
  }
};
