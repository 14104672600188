<template>
  <div id="app" class="full-height">
    <!--  <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutUp"
      mode="out-in"
    > -->
    <router-view v-if="showApp" />
    <!-- </transition> -->
  </div>
</template>

<script>
import { getSystemDic } from './api/register.js';
import { getAppInfo, getUserInfoByUserId, findUser, getArea, updateRealNameStatus } from '@iebdc/api/user-server.js';
import { localDataUser, localDataPermission } from '@iebdc/utils/local-data';
export default {
  name: 'app',
  data() {
    return {
      showApp: false,
    };
  },
  provide() {
    const packageName = window.CONFIG.APP_INFO.packageName;
    // 自定义图片地址
    return {
      customImagePath:
        process.env.NODE_ENV === 'production'
          ? `../${packageName}/customImages/iebdc/`
          : '../../../../public/customImages/iebdc/',
    };
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e));
  },
  created() {
    // 获取区域权限
    // 看用户是否登录
    const user = localDataUser.get();
    if (user && user.userId) {
      this.$store.dispatch('getPermission', user.userId);
    } else {
      this.$store.dispatch('getPermission');
    }
    // 初始化字典值
    getSystemDic();
    let _this = this;
    let wait = setInterval(function () {
      //确保有值
      if (localDataPermission.get()) {
        //清除定时器
        _this.showApp = true;
        clearInterval(wait);
      }
    }, 50);
  },
  methods: {
    beforeunloadFn() {
      const user = localDataUser.get();
      if (user) {
        const params = {
          userId: localDataUser.get().userId,
          userName: localDataUser.get().loginName,
        };
        Promise.all([getAppInfo(), getUserInfoByUserId(params), findUser(params), getArea()]).then(() => {});
        if (!localDataPermission.get().includes('IEBDC:DL:TYRZ')) {
          // updateRealNameStatus({ userId: localDataUser.get().id }).then();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: 'Microsoft YaHei';
  min-width: 1200px;
}
</style>
