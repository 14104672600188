/**
 * 我的缴费路由配置
 */
export default {
  path: '/home/online-pay',
  name: 'online-pay',
  component: () => import('iebdc/views/online-pay'),
  meta: {
    label: '我的缴费',
    isNotMenu: true,
    activeMenu: '/home/wybl',
    permission: 'IEBDC:SY:ZXJF',
    construction: 'IEBDC:SY:ZXJF:SFZJ',
    icon: require('../../assets/images/wyjf.png')
    // background: 'linear-gradient(to bottom right, #37A3FD , #2367F1)'
  }
};
