import { localDataPermission } from './local-data.js';

//获取到最大的areacode
export const getAreaCode = (areaList) => {
  let mostAreaCode = 0;
  areaList.forEach((item) => {
    if (Number(item.code) > mostAreaCode) {
      mostAreaCode = item.code;
    }
  });
  return mostAreaCode;
};
// 获取树形节点所有节点集合
export const getAllNodes = (arrayNodes, attChildren) => {
  if (arrayNodes === null) {
    return [];
  }
  attChildren = attChildren === null ? 'children' : attChildren;
  var result = [];
  for (var ii = 0; ii < arrayNodes.length; ii++) {
    var pNode = arrayNodes[ii];
    result.push(pNode);
    if (pNode[attChildren]) {
      var arrayTempNodes = getAllNodes(pNode[attChildren], attChildren);
      result = result.concat(arrayTempNodes);
    }
  }
  return result;
};
// 根据参数获取节点集合
export const getNodesByParam = (arrayNodes, strName, pValue, attChildren) => {
  var result = [];
  attChildren = attChildren === null ? 'children' : attChildren;
  var listNodes = getAllNodes(arrayNodes, attChildren);
  for (var ii = 0; ii < listNodes.length; ii++) {
    var pNode = listNodes[ii];
    if (String(pNode[strName]) === String(pValue)) {
      result.push(pNode);
    }
  }

  return result;
};

// 判断输入权限标识符 是否有权限

export const hasPermission = (value) => {
  const permissionArr = localDataPermission.get('IEBDC_PERMISSION_DATA');
  if (value) {
    return permissionArr.some((item) => {
      return value === item;
    });
  }
  return false;
};
