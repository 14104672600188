/**
 * 首页路由配置
 */
export default {
  path: '/home/newman-guide',
  name: 'newman-guide',
  component: () => import('iebdc/views/newman-guide'),
  meta: {
    isNotMenu: true,
    permission: 'IEBDC:GNXS:DBLJ'
  }
};
