import request from '../utils/request';
import { REST_SERVICE } from '../config';

const authorizationSearch = {
  // 获取企业用户
  authorizeBankUserGet(params) {
    return request({
      url: REST_SERVICE.authorizationSearch.authorizeBankUserGet,
      method: 'get',
      params,
    });
  },
  // 分页获取授权信息
  authorizeGetByPage(params) {
    return request({
      url: REST_SERVICE.authorizationSearch.authorizeGetByPage,
      method: 'get',
      params,
    });
  },
  // 新增授权
  authorizeAdd(data) {
    return request({
      url: REST_SERVICE.authorizationSearch.authorizeAdd,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 银行权属证明查询(批量授权)
  authorizeBankInspectList(data) {
    return request({
      url: REST_SERVICE.authorizationSearch.authorizeBankInspectList,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 银行权属证明查询(单个授权)
  authorizeBankOneInspect(data) {
    return request({
      url: REST_SERVICE.authorizationSearch.authorizeBankOneInspect,
      method: 'post',
      data,
    });
  },
  // 解析EXCEL表格用户数据(批量授权)
  authorizeExcelToUser(data) {
    return request({
      url: REST_SERVICE.authorizationSearch.authorizeExcelToUser,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryumXrufAiDBtvEb7Z',
      },
      data,
    });
  },
  // 导出查询模板
  exportQueryTemplete(params) {
    return request({
      url: REST_SERVICE.authorizationSearch.exportQueryTemplete,
      method: 'get',
      responseType: 'arraybuffer',
      params,
    });
  },
  // 导出查询结果数据
  exportQszmExcel(params) {
    return request({
      url: REST_SERVICE.authorizationSearch.exportQszmExcel,
      method: 'get',
      responseType: 'arraybuffer',
      params,
    });
  },
  ///visualQueryRecordController/save
  visualQuerysave(params) {
    return request({
      url: REST_SERVICE.visualQuery.visualQuerysave,
      method: 'get',
      params,
    });
  },
  visualQueryfind(params) {
    return request({
      url: REST_SERVICE.visualQuery.visualQueryfind,
      method: 'get',
      params,
    });
  },
  visualQueryupdate(params) {
    return request({
      url: REST_SERVICE.visualQuery.visualQueryupdate,
      method: 'get',
      params,
    });
  },
};

export default authorizationSearch;
