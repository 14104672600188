<template>
  <div class="main-wrap">
    <div ref="header"><Header @isShowLogin="isShowLogin" /></div>
    <div :key="key" :class="route ? 'main-view2' : 'main-view'" :style="{ minHeight: minHeight + 'px' }">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <div ref="footer"><Footer @getLxwm="getLxwm" /></div>
    <!-- <div class="bar-box">
      <div class="bar-box-tip wechat" @click="getewm">
        <img src="../assets/images/wechat.png" />
        <span>微信公众号</span>
      </div>
      <div class="bar-box-tip back-top" @click="backTop">
        <img src="../assets/images/backtop.png" />
        <span>返回顶部</span>
      </div>
    </div> -->
    <!--     <el-dialog :config="ewmConfig">-->
    <!--       <template slot="content">-->
    <!--        <el-image :src="src"></el-image>-->
    <!--       </template>-->
    <!--     </el-dialog>-->
    <el-dialog title="" :visible.sync="dialogVisible" width="33%">
      <div class="block">
        <span>扫描二维码进入微信公众号</span>
        <el-image :src="src"></el-image>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisibleLxwm" width="45%">
      <div class="tsCont">
        <p>1、账号注册登录的问题请联系新疆维吾尔自治区人民政府网新疆政务服务网，联系电话：0991-7531791。</p>
        <p>2、具体业务咨询请致电12345转各地不动产登记中心。</p>
        <p>
          3、系统故障问题请联系新疆维吾尔自治区不动产登记中心，可以致电12345转自治区不动产登记中心或加qq群：205706559。
        </p>
      </div>

      <p style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="dialogVisibleLxwm = false">确 定</el-button>
      </p>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="isShow" width="360px">
      <LoginDialog />
    </el-dialog>
  </div>
</template>

<script>
import Header from './NewHeader.vue';
import Footer from './Footer.vue';
import LoginDialog from '@/pages/iebdc/views/new-dashboard/LoginDialog';
export default {
  name: 'layout',
  components: { Header, Footer, LoginDialog },
  data() {
    return {
      key: 1,
      bswdFlag: false,
      dialogVisible: false,
      dialogVisibleLxwm: false,
      minHeight: '',
      isShow: false,
      src: require('../assets/images/dashboard/' + window.CONFIG.APP_INFO.wxUrl),
    };
  },
  methods: {
    isShowLogin() {
      this.isShow = true;
    },
    backTop() {
      if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop = 0;
      }
    },
    getewm() {
      this.dialogVisible = true;
    },
    getLxwm() {
      console.log('123');
      this.dialogVisibleLxwm = true;
    },
    contentBoxH() {
      let screeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let headerHeight = this.$refs.header.offsetHeight;
      // let footerHeight = this.$refs.footer.offsetHeight;
      let footerHeight = 148;
      let height = screeHeight - headerHeight - footerHeight;
      if (height <= 360) {
        height = 360;
      }
      this.minHeight = height;
    },
  },
  computed: {
    route() {
      return (this.bswdFlag = this.$route.name === 'bswd');
    },
  },
  mounted() {
    this.contentBoxH();
    window.onresize = () => {
      this.contentBoxH();
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';
.tsCont {
  padding: 10px;
  p {
    line-height: 30px;
  }
}
.main-wrap {
  background: white;
  .main-view {
    margin: 0px auto;
    width: 1300px;
    position: relative;
  }
  .main-view2 {
    margin: 0 auto;
    width: 100%;
  }
  .bar-box {
    position: fixed;
    width: 100px;
    top: calc(50% - 50px);
    right: 0;
    box-shadow: 0 8px 16px 2px rgba(66, 66, 66, 0.2);
    background-color: #fff;
    z-index: 1000;
    img {
      margin: 15px 30px 10px 30px;
    }
    span {
      display: block;
      font-size: 15px;
      font-weight: bold;
      color: rgba(102, 102, 102, 1);
      text-align: center;
    }
    .bar-box-tip {
      cursor: pointer;
    }
    .bar-box-tip.back-top {
      background: rgba(32, 119, 190, 1);
      margin-top: 10px;
      padding-bottom: 20px;
      span {
        color: #fff;
      }
    }
  }
  //弹框部分
  .block {
    text-align: center;
    /deep/.el-image {
      width: 258px;
      height: 260px;
      border: 1px solid #ffffff;
    }
    span {
      display: block;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
