/**
 * 计算状态
 * @description
 * 声明全局的计算状态
 */
const getters = {
  // 返回设备信息
  device: (state) => state.app.device,
  // 字典值
  dic: (state) => state.dic.dic,
  // 返回区域权限
  permission: (state) => state.permission.permission,
  //返回该功能是否在建的权限
  construction: (state) => state.construction.construction,
  // 注册进行到的步骤
  grActiveStep: (state) => state['sign-in-step'].grActiveStep,
  jgActiveStep: (state) => state['sign-in-step'].jgActiveStep,
  // 注册进行时传递的数据
  grFirstStepData: (state) => state['sign-in-step'].grFirstStepData,
  grSecondStepData: (state) => state['sign-in-step'].grSecondStepData,
  jgFirstStepData: (state) => state['sign-in-step'].jgFirstStepData,
  jgSecondStepData: (state) => state['sign-in-step'].jgSecondStepData,
  // 我的申请firstFlow数据保存
  firstFlow: (state) => state['wysq-data'].firstFlow,
  // 当前城市的 首页区域数据集合
  finalAreaData: (state) => state['dashboard'].finalAreaData,
  // 办事大厅名字
  selectedBsdtTitle: (state) => state['dashboard'].selectedBsdtTitle,
  // 办事大厅code
  selectedBsdtCode: (state) => state['dashboard'].selectedBsdtCode,
  //区域(区/县)名字
  selectedAreaTitle: (state) => state['dashboard'].selectedAreaTitle,
  //区域(区/县)code
  selectedAreaCode: (state) => state['dashboard'].selectedAreaCode,
  //登记机构名字
  selectedOrganizationsTitle: (state) => state['dashboard'].selectedOrganizationsTitle,
  //登记机构code
  selectedOrganizationsCode: (state) => state['dashboard'].selectedOrganizationsCode,
  keys: (state) => state['selectArea'].keys,

  // // 当前城市的 首页区域数据集合
  // finalAreaData: (state) => state['selectArea'].finalAreaData,
  // // 办事大厅名字
  // selectedBsdtTitle: (state) => state['selectArea'].selectedBsdtTitle,
  // // 办事大厅code
  // selectedBsdtCode: (state) => state['selectArea'].selectedBsdtCode,
  // //区域(区/县)名字
  // selectedAreaTitle: (state) => state['selectArea'].selectedAreaTitle,
  // //区域(区/县)code
  // selectedAreaCode: (state) => state['selectArea'].selectedAreaCode,
  // //登记机构名字
  // selectedOrganizationsTitle: (state) => state['selectArea'].selectedOrganizationsTitle,
  // //登记机构code
  // selectedOrganizationsCode: (state) => state['selectArea'].selectedOrganizationsCode,
  //用户类型
  userType: (state) => state['user-type'].userType,
  taxStatus: (state) => state['taxStatus'].taxStatus,
};

export default getters;
