import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 获取区县和用户的权限交集
export const areaUserPermission = (params) =>
  request({
    url: REST_SERVICE.uums.areaUserPermission,
    method: 'get',
    params
  });

// 获取获取辖区信息
export const area = (params) =>
  request({
    url: REST_SERVICE.uums.area,
    method: 'get',
    params
  });

// 获取组织机构信息
export const getOrganBsdt = (params) =>
  request({
    url: REST_SERVICE.uums.getOrganBsdt,
    method: 'get',
    params,
  });

// 通过登记机构查询办事大厅列表
export const findByDjjg = (params) =>
  request({
    url: REST_SERVICE.uums.findByDjjg,
    method: 'get',
    params,
  });

// 查询办事大厅
export const getWorkOffice = (params) =>
  request({
    url: REST_SERVICE.uums.getWorkOffice,
    method: 'get',
    params,
  });
export const getRoleById = (params) =>
  request({
    url: REST_SERVICE.role.getRole,
    method: 'get',
    params,
  });
