<template>
  <div class="wrap full-width flex-column">
    <Header />
    <div class="main mainOnly">
      <div class="main-big">
        <el-row class="main-header-wrap">
          <el-col :span="15" class="left-content">
            <el-tabs v-model="type" @tab-click="tabClick">
              <el-tab-pane v-for="(item, index) in stepsData" :key="index" :label="item.label" :name="item.name">
                <!-- 步骤条-->
                <el-steps :active="type === 'gr' ? grActiveStep : jgActiveStep" simple class="mt-40">
                  <el-step v-for="(item, index) in signInSteps" :key="index" :title="item"></el-step>
                </el-steps>
                <!-- 创建账号 -->
                <create-account
                  :type="type"
                  v-if="(type === 'gr' && grActiveStep === 0) || (type === 'jg' && jgActiveStep === 0)"
                ></create-account>

                <auth-gr v-if="grActiveStep === 1 && type === 'gr'"></auth-gr>
                <auth-jg v-if="jgActiveStep === 1 && type === 'jg'"></auth-jg>
                <finish-gr v-if="grActiveStep === 2 && type === 'gr'"></finish-gr>
                <finish-jg v-if="jgActiveStep === 2 && type === 'jg'"></finish-jg>
              </el-tab-pane>
            </el-tabs>
          </el-col>

          <el-col :span="7" :offset="2" class="right-content">
            <div>
              <p class="color2C8BFF fs16">温馨提醒:</p>
              <!-- <p class="color6D6D6D">
                您好!{{ systemName }}用户可尝试直接通过手机验证码登录，提示未注册再进行注册即可。
              </p> -->
            </div>
            <div>
              <p class="color6D6D6D">登陆注册问题请咨询:</p>
              <p class="color2C8BFF">{{ loginRegisterConsultingPhone }}</p>
            </div>
            <div>
              <p class="color6D6D6D">实名认证疑问请咨询:</p>
              <p class="color2C8BFF">{{ authConsultingPhone }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './component/header';
import CreateAccount from './component/create-account';
import AuthGr from './component/auth-gr';
import AuthJg from './component/auth-jg';
import FinishGr from './component/finish-gr';
import FinishJg from './component/finish-jg';
import Footer from '../../layout/Footer';
import { mapGetters } from 'vuex';

export default {
  name: 'sing-in',
  components: {
    Header,
    CreateAccount,
    AuthGr,
    AuthJg,
    FinishGr,
    FinishJg,
    Footer,
  },
  data() {
    return {
      systemName: '',
      loginRegisterConsultingPhone: '',
      authConsultingPhone: '',
      signInSteps: ['创建账号', '实名认证', '注册完成'],
      type: 'gr',
      stepsData: [
        { label: '个人注册', name: 'gr' },
        { label: '法人注册', name: 'jg' },
      ],
    };
  },
  computed: {
    ...mapGetters(['grActiveStep', 'jgActiveStep']),
  },
  created() {
    this.initSystemInfo();
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.initActiveStep();
  },
  methods: {
    initActiveStep() {
      let activeStep = this.$route.query.userInfo ? 1 : 0;
      this.$store.dispatch('sign-in-step/getGrActiveStep', activeStep);
      this.$store.dispatch('sign-in-step/getJgActiveStep', activeStep);
    },
    initSystemInfo() {
      const { iebdc } = window.CONFIG.APP_INFO;
      this.systemName = window.CONFIG.APP_INFO.systemName;
      this.loginRegisterConsultingPhone = iebdc.loginRegisterConsultingPhone;
      this.authConsultingPhone = iebdc.authConsultingPhone;
    },
    tabClick() {
      this.signInSteps[1] = this.type === 'gr' ? '实名认证' : '证件信息';
    },
  },
};
</script>

<style lang="scss" scoped>
.color2C8BFF {
  color: #2c8bff;
}
.color6D6D6D {
  color: #6d6d6d;
}
.fs16 {
  font-size: 16px;
}
.wrap {
  height: 100vh;
  background: #f7f7f7;
  .header {
    background: #ffffff;
    margin: 0;
  }
}
.mainOnly {
  overflow-y: scroll;
  flex: 1;
  .main-big {
    position: relative;
    width: 1200px;
    margin: 30px auto;
  }
  .main-big::after {
    content: '';
    position: absolute;
    left: 26px;
    top: 68px;
    width: 1148px;
    height: 2px;
    background-color: #e4e7ed;
    z-index: 1;
  }
  .main-header-wrap /deep/ {
    width: 100%;
    background: #ffffff;
    padding: 10px 26px 26px 26px;
    box-sizing: border-box;
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    .el-tabs__item {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      height: 60px;
      line-height: 60px;
    }
    .el-tabs__item.is-active {
      color: #2c8bff;
    }
    .el-tabs__active-bar {
      background-color: #2c8bff;
    }
    .el-step__head.is-finish {
      color: #2c8bff;
      border-color: #2c8bff;
    }
    .right-content {
      background: #f7f7f7;
      margin-top: 86px;
      min-height: 600px;
      padding: 40px 30px;
      box-sizing: border-box;
      div {
        margin-bottom: 26px;
      }
      p {
        line-height: 30px;
      }
    }
    .el-steps--simple {
      background: #ffffff;
      margin-bottom: 20px;
    }
    .el-step.is-simple .el-step__arrow::after,
    .el-step.is-simple .el-step__arrow::before {
      -webkit-transform: inherit;
      -ms-transform: inherit;
      transform: inherit;
      -webkit-transform-origin: inherit;
      -ms-transform-origin: inherit;
      transform-origin: inherit;
    }
    .el-step.is-simple .el-step__arrow::after {
      height: 0;
      display: inline-block;
      position: absolute;
      width: 1px;
      background: #c0c4cc;
    }
    .el-step.is-simple .el-step__arrow::before {
      content: '----------------------';
      display: inline-block;
      position: absolute;
      left: 84px;
      top: 0px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      width: 140px;
      background: #ffffff;
      color: #cbcbcb;
    }
    .el-step__head.is-process .el-step__arrow::before,
    .el-step__head.is-finish .el-step__arrow::before {
      color: #2c8bff !important;
    }
    .el-step__head.is-wait {
      color: #f7f7f7;
      background: rgba(247, 247, 247, 1);
      border-radius: 100%;
      width: 24px;
      height: 24px;
      position: relative;
      border-color: #f7f7f7;
    }
    .el-step__head.is-process,
    .el-step__head.is-finish {
      color: #2c8bff;
      background: rgba(44, 139, 255, 1);
      border-radius: 100%;
      width: 22px;
      height: 22px;
      position: relative;
    }
    .el-step__icon.is-text {
      border-radius: 50%;
      width: 26px;
      height: 26px;
      position: absolute;
      left: -2px;
      top: -2px;
      border: 2px solid #f7f7f7;
    }
    .el-step__head.is-process .el-step__icon.is-text,
    .el-step__head.is-finish .el-step__icon.is-text {
      border: 2px solid rgba(44, 139, 255, 0.4);
    }
    .el-step__title.is-process,
    .el-step__title.is-finish {
      color: #414141;
    }
    .el-step.is-simple .el-step__icon {
      position: relative;
    }
    .el-step.is-simple .el-step__icon:after {
      position: static;
      content: '1';
      color: #ffffff;
      width: 8px;
      height: 11px;
    }
    .el-step.is-simple .el-step__title {
      margin-left: 10px;
    }
    .el-step__head.is-process .el-step__icon:after,
    .el-step__head.is-finish .el-step__icon:after {
      color: #ffffff !important;
    }
    .el-steps .is-simple:nth-child(1) .el-step__icon:after {
      content: '1';
      color: #cbcbcb;
    }
    .el-steps .is-simple:nth-child(2) .el-step__icon:after {
      content: '2';
      color: #cbcbcb;
    }
    .el-steps .is-simple:nth-child(3) .el-step__icon:after {
      content: '3';
      color: #cbcbcb;
    }
    .el-input--small .el-input__inner {
      height: 46px;
      line-height: 46px;
      border-right: 1px solid #f7f7f7;
      background: #f7f7f7;
      border-color: #f7f7f7;
    }
    .el-input__inner:focus {
      border-right: 1px solid #ff5b24;
      background: #ffffff;
      border-color: #ff5b24;
    }
    .el-link.el-link--primary:hover {
      color: #2c8bff;
    }
    .el-link.zcxyLink.el-link--primary:hover {
      color: #ff5b24;
    }

    .el-input-group__append,
    .el-input-group__prepend {
      ackground-color: #f7f7f7;
      color: #909399;
      vertical-align: middle;
      display: table-cell;
      position: relative;
      border: 1px solid #f7f7f7;
      border-radius: 0;
      padding: 0 20px;
      width: 1px;
      white-space: nowrap;
    }
    .inpSjyzm .el-input--small .el-input__inner {
      width: 460px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:hover,
    .el-button.is-disabled:focus {
      background-color: #f7f7f7;
      border-color: #f7f7f7;
      color: #d6d5d0;
    }
    .el-button {
      background-color: #2c8bff;
      border-color: #2c8bff;
      color: #ffffff;
    }
    .yzmButton {
      height: 44px;
    }
  }
}
</style>
