<template>
  <div class="header">
    <div class="width1200 flex">
      <div class="img">
        <img :src="logo"
             height="56" />
        <!-- <span class="title">欢迎注册 {{ userType }}版</span> -->
      </div>
      <div class="login flex-center-align">
        已有账号,<router-link to="login"
                     class="link">马上登录</router-link>|
        <router-link to="home"
                     class="link">返回首页</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sing-in-header',
  props: {
    // type: {
    //   type: String,
    //   required: true
    // }
  },
  inject: ['customImagePath'],
  data () {
    return {
      logo: ''
    };
  },
  created() {
    this.logo = this.customImagePath + window.CONFIG.APP_INFO.iebdc.header;
  },
  computed: {
    // userType() {
    //   return this.type === 'gr' ? '个人' : '企业';
    // }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
.width1200 {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.header {
  padding: 16px 15px !important;
  min-width: 1200px !important;
  margin: $spacing-medium 0;
  .width1200 {
    justify-content: space-between;
  }
  .title {
    display: inline-block;
    margin-left: 20px;
    transform: translateY(-20px);
    font-size: 20px;
  }
  .login {
    line-height: 40px;
    float: right;
    margin-right: 0;

    .link {
      margin: 0 5px;
    }
  }
}
</style>
