<template>
  <el-form ref="form" class="form" :model="formModel" label-width="130px">
    <!--标题 -->
    <div v-if="formTitle" class="table-title flex-space-between">
      <h5>{{ formTitle }}</h5>
    </div>
    <!-- 表单主体 -->
    <el-row justify="space-between" type="flex">
      <template v-for="(input, i) in formItems">
        <el-col v-if="input._ifPlaceHolder" :key="input.key + '_' + i" :span="input.span">
          <el-form-item v-if="input._ifRender" :prop="input.key" :label="input.title" :rules="input.rules">
            <template v-if="input.tag == 'choose-bdcqzh'">
              <choose-bdcqzh
                :bdcqzhData="formModel[input.key]"
                :bdcqzhConfig="{ rules: input.rule, mbanType: input.props.mbanType, disabled: disabled }"
                @update:bdcqzhData="(val) => (formModel.bdcqzh = val)"
              ></choose-bdcqzh>
            </template>
            <component
              v-else
              :is="input.tag"
              v-model="formModel[input.key]"
              v-bind="input.props || {}"
              @change="input.change && input.change(formModel[input.key])"
            >
              <!-- 渲染select option -->
              <template v-if="input.tag === 'el-select'">
                <el-option v-for="item in input.props.options" :key="item.value" :label="item.label" :value="item.value" />
              </template>
              <!-- 这里渲染radio -->
              <template v-if="input.tag === 'div'">
                <el-radio
                  v-for="item in input.props.options"
                  :key="item.value"
                  v-model="formModel[input.key]"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </template>
            </component>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
  </el-form>
</template>

<script>
/**
 * 基础动态表单
 *
 * @description
 * 核心逻辑为动态表单计算项，核心方法为computeFormItem
 * 如需改变样式，可写样式组件，直接将该组件的方法引入过去
 */
import { ElementMapping, isFunction } from './utils';
export default {
  name: 'Index',
  props: {
    formConfig: {
      type: Array,
      default: () => [],
    },
    formModel: {
      type: Object,
      default: () => {},
    },
    formTitle: String,
  },
  computed: {
    // 动态计算需要循环的表单项
    formItems() {
      return (this.formConfig || []).map((item) => this.computeFormItem(item, this.formModel));
    },
  },
  methods: {
    computeFormItem(config, form) {
      // 返回结构体
      const item = { ...config };
      // 表单控件的类型
      const type = item.type || 'input';
      // 对应到组件映射表
      const def = ElementMapping[type];
      item.tag = def.component;
      if (item.tag === 'choose-bdcqzh') {
        form.bdcqzh = '';
      }
      item.props = Object.assign({}, def.props, item.props);
      // 获取动态 props
      if (isFunction(item.getProps)) {
        Object.assign(item.props, item.getProps(form));
      }
      // 获取动态标题
      if (isFunction(item.title)) {
        item.title = item.title(form);
      }
      // 设置动态验证
      if (isFunction(item.rules)) {
        item.rules = item.rules(form);
      }
      // 条件渲染
      item._ifRender = isFunction(item.ifRender) ? !!item.ifRender(form) : true;
      // 是否占位
      item._ifPlaceHolder = item._ifRender ? true : !item.isNotPlaceHolder;
      // 设置宽度
      item.span = item.span || 11;
      // 防止表单提交时存在多余 key
      // if (!item._ifRender) {
      //   delete form[item.key];
      // }
      // form-item 配置
      return item;
    },
    // 重置字段
    resetFields() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/common-variables';
.form {
  margin-top: 26px;
}
.table-title {
  padding: 10px 0 10px 10px;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  h5 {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      height: 3px;
      width: 108%;
      bottom: -10px;
      left: -7%;
      background-color: $color-primary;
    }
  }
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-row--flex {
  flex-wrap: wrap;
}
/deep/ .el-select.el-select--small.w36 {
  width: 36%;
}
/deep/ .el-input.w80,
/deep/ .el-select.w80,
/deep/ .el-date-editor.el-input__inner.w80 {
  width: 80%;
}
/*/deep/ .el-col-24 {*/
/*  /deep/ .el-input {*/
/*    width: 92%;*/
/*  }*/
/*}*/
</style>
