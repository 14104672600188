/**
 * 首页路由配置
 */
export default {
  path: '/home/my-query',
  name: 'my-query',
  component: () => import('iebdc/views/my-query'),
  meta: {
    label: '我的查询',
    isNotMenu: true,
    // permission: 'IEBDC:SY:WDCK'
    permission: 'IEBDC:WDCX'
  }
};
