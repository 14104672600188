/**
 * 用户路由配置
 */
export default {
  path: '/home/userCenter',
  name: 'user-center',
  component: () => import('@/pages/iebdc/views/user-center-new'),
  meta: {
    label: '个人中心',
    // isNotMenu: true,
    permission: 'IEBDC:GRZX'
  }
};
