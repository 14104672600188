<template>
  <div class="header1">
    <div class="location-wrap" @click="showBsdtDialog">
      <div class="location-top">
        <img src="../../assets/images/dashboard/dw.png" alt="" width="12" height="15" />
        <span>{{
          'selectedBsdtTitle' in selectArr && selectArr.selectedBsdtTitle
            ? selectArr.selectedBsdtTitle
            : '切换区域与办事大厅'
        }}</span>
        <i class="el-icon-caret-bottom arrow-icon"></i>
      </div>
    </div>

    <el-dialog
      :visible.sync="bsdtDialogVisible"
      center
      :title="'请先选择办事大厅'"
      custom-class="dialog-class"
      :before-close="closeDialog"
      :modal="false"
    >
      <div class="bsdt-dialog-content-wrap">
        <!-- 市 -->
        <div class="bsdt-item flex" v-if="chooseBsdtData.sheng">
          <div class="bsdt-item-label">市:</div>
          <div class="bsdt-item-content">
            <template v-for="(item, index) in chooseBsdtData.sheng.childrens">
              <span
                :class="chooseBsdtData.shi.code === item.code ? 'chosen-bsdt-span' : ''"
                class="bsdt-span"
                v-if="item.visible"
                :key="index"
                @click="chooseBsdt(item)"
              >
                {{ item.title }}
              </span>
            </template>
          </div>
        </div>

        <!-- 区域 -->
        <div class="bsdt-item flex">
          <div class="bsdt-item-label">区域:</div>
          <div class="bsdt-item-content">
            <template v-for="(item, index) in chooseBsdtData.shi.childrens">
              <span
                :class="chooseBsdtData.xian.code === item.code ? 'chosen-bsdt-span' : ''"
                class="bsdt-span"
                v-if="item.visible"
                :key="index"
                @click="chooseBsdt(item)"
              >
                {{ item.title }}
              </span>
            </template>
          </div>
        </div>

        <!-- 办事大厅 -->
        <div class="bsdt-item flex" v-if="'selectedBsdtTitle' in selectArr && selectArr.selectedBsdtTitle">
          <div class="bsdt-item-label color2c8bff">办事大厅:</div>
          <div class="bsdt-item-content">
            <span
              :class="selectArr.selectedBsdtTitle === item.officeName ? 'chosen-bsdt-span' : ''"
              class="bsdt-span"
              v-for="(item, index) in chooseBsdtData.xian.bsdtData"
              :key="index"
              @click="chooseBsdt(item)"
            >
              {{ item.officeName }}
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { localDataUser } from '@iebdc/utils/local-data.js';
import { area, getOrganBsdt, getWorkOffice } from '@iebdc/api/uums';
import { mapGetters, mapState } from 'vuex';
import bsdtDialog from '@iebdc/mixin/bsdtDialog';
import { cacheHasPermission, cache } from './area-cahce';
import { localDataArea } from '@iebdc/utils/local-data';
import { filterArea, filterAreaAll } from '@iebdc/views/new-dashboard/header/filterArea.js';
import { getCookie } from '@iebdc/utils/cookies';
let zlData = {
  zlProvince: getCookie('selectedShengCode') || '650000',
  zlCity: getCookie('selectedShiCode') || '',
  zlArea: getCookie('selectedAreaCode') || '',
};

export default {
  props: {
    isShowLogin: Function,
  },
  mixins: [bsdtDialog],
  inject: ['customImagePath'],
  data() {
    return {
      logo: '',
      logoImgAlt: window.CONFIG.APP_INFO.systemName,
      // isLogined: true, // 判断是否登录
      userData: localDataUser.get(),
      bsdtTitle: '',
      areaData: [], // 省/市/县(区) 数据源
      orgData: [], // 县(区) 下所有办事大厅 数据源
      bsdtData: [], // 市 所有的办事大厅 数据源
      chooseBsdtData: {
        // 当前展示的 办事大厅弹窗数据源
        sheng: '',
        shi: {},
        xian: {},
        // bsdtArr: [],
        bsdtObj: {},
      },
      // 判断是否为我要办理模块
      flag: false,
      // 本地存储的行政区代码
      selectArr: {},
    };
  },
  watch: {
    $route: {
      handler() {
        var urlArr = window.location.href.split('/');
        this.flag = urlArr.includes('check-in');
        if (!this.flag) {
          this.initBsdtDialogData(filterAreaAll(localDataArea.get()));
        } else {
          this.initBsdtDialogData(filterArea(localDataArea.get(), localDataUser.get().accountArea));
        }
      },
    },
    deep: true,
    immediate: true, // immediate选项可以开启首次赋值监听
  },
  computed: {
    ...mapState('selectArea', ['areaObj', 'bsdtDialogVisible']),

    // 获取存在vuex 中的 选中的办事大厅名字以及code++++选中的 区域(区/县)的名字和code
    ...mapGetters(['keys']),

    ...mapState('wysq-data', ['firstFlow']),
  },
  methods: {
    initCustomSystemInfo() {
      const { APP_INFO } = window.CONFIG;
      this.logo = this.customImagePath + APP_INFO.iebdc.header;
      this.logoImgAlt = APP_INFO.systemName;
    },
    // 页面初始化
    init() {
      this.initCustomSystemInfo();
      var urlArr = window.location.href.split('/');
      this.flag = urlArr.includes('check-in');
      if (getCookie('selectedAreaTitle') === '' && getCookie('selectedShengTitle') === '') this.getBsdtDialogData();
      else {
        // this.getCookieBsdt();
        if (this.flag && localDataUser.get() && localDataUser.get().accountArea) {
          this.initBsdtDialogData(filterArea(localDataArea.get(), localDataUser.get().accountArea));
        } else {
          this.initBsdtDialogData(filterAreaAll(localDataArea.get()));
        }
      }
    },
    closeDialog() {
      console.log(this.areaObj);
      if (localDataUser.get() && !('selectedBsdtTitle' in this.areaObj && this.areaObj.selectedBsdtTitle)) {
        this.$message.error('请选择办事大厅！');
        this.$store.commit('selectArea/isShowBsdtDialog', true);
      } else {
        this.$store.commit('selectArea/isShowBsdtDialog', false);
      }
    },

    // 显示选择办事大厅的弹窗
    showBsdtDialog() {
      this.getBsdtDialogData();
      this.$store.commit('selectArea/isShowBsdtDialog', true);
    },

    async getBsdtDialogData() {
      // 政务网企业、银行账户跳转到网上大厅，需走审核功能（企业用户可以修改自己的所属区域，审核过后的用户只能选择自己所属区办理业务）
      // var urlArr1 = window.location.href.split("/");
      // if (
      //   urlArr1.includes("check-in") &&
      //   localDataUser.get() &&
      //   localDataUser.get().accountArea
      // ) {
      //   this.initBsdtDialogData(
      //     filterArea(localDataArea.get(), localDataUser.get().accountArea)
      //   );
      //   if (
      //     getCookie("selectedAreaTitle") === "" &&
      //     getCookie("selectedShengTitle") === ""
      //   ) {
      //     this.chooseBsdt(
      //       filterArea(localDataArea.get(), localDataUser.get().accountArea)[0]
      //         .childrens[0]
      //     );
      //   }
      // } else {
      // 省/市/县(区) 所有的区域数据
      let areaData = await area();
      let areaLength = areaData.data.length;
      if (areaLength && areaLength > 0) {
        for (let i = 0; i < areaLength; i++) {
          const areaItem = areaData.data[i];
          this.bsdtTitle = areaItem.title;
          if (areaItem.code === window.CONFIG.code) {
            this.areaData.push(areaItem);
          }
        }
      }

      // 所有的机构数据
      let orgData = await getOrganBsdt();
      let orgLength = orgData.data.length;
      if (orgLength && orgLength > 0) {
        for (let i = 0; i < orgLength; i++) {
          const orgItem = orgData.data[i];
          if (orgItem.code === window.CONFIG.code) {
            this.orgData.push(orgItem);
          }
        }
      }

      // 区域下的所有的办事大厅数据
      let bsdtData = await getWorkOffice({ nSize: 10000 });
      this.bsdtData = [...bsdtData.data.page];

      // 重构 区域数据源---areaData---将区县与办事大厅通过areaId连接起来，同时兼顾市辖区的情况
      this.rebuildAllData(this.areaData, this.orgData, this.bsdtData);

      //找到当前设定区域下的值
      let finalAreaData = this.getAllNodes(this.areaData, 'childrens').filter((item) => {
        return item.code === window.CONFIG.code;
      });
      localStorage.setItem('finalAreaDataLocal', JSON.stringify(finalAreaData));
      // 将重构的后的区域数据 放入vuex
      // this.$store.commit("selectArea/setFinalAreaData", finalAreaData);
      this.$store.commit('selectArea/setFinalAreaData', finalAreaData);
      // 获取 cookie 中的 办事大厅弹窗的数据
      this.getCookieBsdt();

      // 如果 vuex 里没有选择 区域(区/县)
      // if (!cacheHasPermission.get()) {
      //   this.chooseBsdt(finalAreaData[0]);
      // }

      // 初始化办事大厅弹窗内容
      this.initBsdtDialogData(filterAreaAll(finalAreaData));
      // }
    },

    rebuildAllData(areaData, orgData, bsdtData) {
      //重构 bsdtData 数据，给办事大厅加上所属区县的areaId
      this.rebuildBsdtData(orgData, bsdtData);

      //重构areaData 数据, 将区县与办事大厅通过areaId连接后的bsdtDat,放入areaData的相同的areaId的bsdtData属性里
      let allAreaData = this.getAllNodes(this.areaData, 'childrens');
      for (let ii = 0; ii < allAreaData.length; ii++) {
        let areaItem = allAreaData[ii];
        areaItem.bsdtData = [];
        for (let jj = 0; jj < bsdtData.length; jj++) {
          if (areaItem.id === bsdtData[jj].areaId) {
            areaItem.bsdtData.push(bsdtData[jj]);
            // bsdtData[jj].areaData = areaItem;
          }
          // else if (allAreaData[ii].code && parseInt(allAreaData[ii].code) % 100 === 1) {
          //   // 防止出现市辖区的情况
          //   if (parseInt(allAreaData[ii].code / 100) === parseInt(bsdtData[jj].djjg / 100)) {
          //     let bsdtArea = { ...bsdtData[jj] };
          //     bsdtArea.isAdd = true;
          //     allAreaData[ii].bsdtData.push(bsdtArea);
          //   }
          // }
        }
      }
    },

    //重构 bsdtData
    rebuildBsdtData(orgData, bsdtData) {
      if (orgData === null || bsdtData === null) {
        return false;
      }
      let allOrgData = this.getAllNodes(orgData, 'childrens');
      for (let ii = 0; ii < bsdtData.length; ii++) {
        let bsdtItem = bsdtData[ii];
        for (let jj = 0; jj < allOrgData.length; jj++) {
          if (allOrgData[jj].code === bsdtItem.officeCode) {
            bsdtItem.areaId = allOrgData[jj].areaId;
            break;
          }
        }
      }
    },

    // 扁平化数据
    getAllNodes(arrayNodes, attChildren) {
      if (arrayNodes === null) {
        return [];
      }
      attChildren = attChildren === null ? 'children' : attChildren;
      let result = [];
      for (let ii = 0; ii < arrayNodes.length; ii++) {
        let pNode = arrayNodes[ii];
        result.push(pNode);
        if (pNode[attChildren]) {
          let arrayTempNodes = this.getAllNodes(pNode[attChildren], attChildren);
          result = result.concat(arrayTempNodes);
        }
      }
      return result;
    },

    /*
     * 添加cookies
     */
    addAreaCookie(item) {
      let obj = {};
      let obj1 = {};
      let obj2 = {};
      let obj3 = {};
      switch (item.areaType) {
        // 当是省时,把当前 选中的省 存入vuex 同时存入cookie，同时清空选中的办事大厅
        case '2':
          obj[this.keys.selectedShengCode] = item.code;
          obj[this.keys.setSelectedShengTitle] = item.title;
          this.$store.commit('selectArea/setArea', [obj, this.flag]);

          // this.$store.commit('selectArea/setSelectedShengCode', item.code);
          // this.$store.commit('selectArea/setSelectedShengTitle', item.title);
          // addCookie('selectedShengTitle', this.$store.state.selectArea.selectedShengTitle, 1);
          // addCookie('selectedShengCode', this.$store.state.selectArea.selectedShengCode, 1);
          // this.removeArea();
          break;
        // 当是市时,把当前 选中的市 存入vuex 同时存入cookie，同时清空选中的办事大厅
        case '3':
          obj1[this.keys.selectedShiCode] = item.code;
          obj1[this.keys.selectedShiTitle] = item.title;
          this.$store.commit('selectArea/setArea', [obj1, this.flag]);
          // this.$store.commit('selectArea/setSelectedShiCode', item.code);
          // this.$store.commit('selectArea/setSelectedShiTitle', item.title);
          // addCookie('selectedShiTitle', this.$store.state.selectArea.selectedShiTitle, 1);
          // addCookie('selectedShiCode', this.$store.state.selectArea.selectedShiCode, 1);
          zlData.zlCity = item.code;
          this.removeArea('shi');
          break;
        // 当是区县时,把当前 选中的区域(区/县) 存入vuex 同时存入cookie，同时清空选中的办事大厅
        case '4':
          console.log(this.flag, 'fk');
          obj2[this.keys.selectedAreaCode] = item.code;
          obj2[this.keys.selectedAreaTitle] = item.title;
          this.$store.commit('selectArea/setArea', [obj2, this.flag]);
          zlData.zlArea = item.code;
          this.removeArea('xian');
          break;
        // 当选中的是 办事大厅时，选中的办事大厅 相关数据存入vuex和cookie中
        default:
          obj3[this.keys.selectedBsdtCode] = item.officeCode;
          obj3[this.keys.selectedBsdtTitle] = item.officeName;
          obj3[this.keys.selectedOrganizationsCode] = item.djjg;
          obj3[this.keys.selectedOrganizationsTitle] = item.jgmc;
          this.$store.commit('selectArea/setArea', [obj3, this.flag]);
          // this.$store.commit('selectArea/setSelectedBsdtCode', item.officeCode);
          // this.$store.commit('selectArea/setSelectedBsdtTitle', item.officeName);
          // this.$store.commit('selectArea/setSelectedOrganizationsCode', item.djjg);
          // this.$store.commit('selectArea/setSelectedOrganizationsTitle', item.jgmc);
          // addCookie('selectedBsdtTitle', this.$store.state.selectArea.selectedBsdtTitle, 1);
          // addCookie('selectedBsdtCode', this.$store.state.selectArea.selectedBsdtCode, 1);
          // addCookie('selectedOrganizationsTitle', this.$store.state.selectArea.selectedOrganizationsTitle, 1);
          // addCookie('selectedOrganizationsCode', this.$store.state.selectArea.selectedOrganizationsCode, 1);
          this.chooseBsdtData.bsdtObj = item;
          this.$bus.$emit('selectedOrganizationsCode', item.djjg);
          this.$bus.$emit('selectedZlData', zlData);
      }
    },

    /*
     * 移除cookies
     */
    removeArea(item) {
      const removeObj = {};
      const removeObj1 = {};
      switch (item) {
        case 'shi':
          removeObj[this.keys.selectedBsdtCode] = '';
          removeObj[this.keys.selectedBsdtTitle] = '';
          removeObj[this.keys.selectedOrganizationsCode] = '';
          removeObj[this.keys.selectedOrganizationsTitle] = '';
          removeObj[this.keys.selectedAreaCode] = '';
          removeObj[this.keys.selectedAreaTitle] = '';
          this.$store.commit('selectArea/setArea', [removeObj, this.flag]);
          //       this.$store.commit('selectArea/setSelectedBsdtTitle', '');
          //       this.$store.commit('selectArea/setSelectedBsdtCode', '');
          //       this.$store.commit('selectArea/setSelectedOrganizationsTitle', '');
          //       this.$store.commit('selectArea/setSelectedOrganizationsCode', '');
          //       this.$store.commit('selectArea/setSelectedAreaTitle', '');
          //       this.$store.commit('selectArea/setSelectedAreaCode', '');
          //       removeCookie('selectedBsdtTitle');
          //       removeCookie('selectedBsdtCode');
          //       removeCookie('selectedOrganizationsTitle');
          //       removeCookie('selectedOrganizationsCode');
          //       removeCookie('selectedAreaTitle');
          //       removeCookie('selectedAreaCode');
          break;
        case 'xian':
          removeObj1[this.keys.selectedBsdtTitle] = '';
          removeObj1[this.keys.selectedBsdtCode] = '';
          this.$store.commit('selectArea/setArea', [removeObj1, this.flag]);
          //       this.$store.commit('selectArea/setSelectedBsdtTitle', '');
          //       this.$store.commit('selectArea/setSelectedBsdtCode', '');
          //       removeCookie('selectedBsdtTitle');
          //       removeCookie('selectedBsdtCode');
          //       removeCookie('selectedOrganizationsTitle');
          //       removeCookie('selectedOrganizationsCode');
          break;
        default:
          this.$store.commit('selectArea/setArea', [{}, this.flag]);
        //       this.$store.commit('selectArea/setSelectedBsdtTitle', '');
        //       this.$store.commit('selectArea/setSelectedBsdtCode', '');
        //       this.$store.commit('selectArea/setSelectedOrganizationsTitle', '');
        //       this.$store.commit('selectArea/setSelectedOrganizationsCode', '');
        //       this.$store.commit('selectArea/setSelectedAreaTitle', '');
        //       this.$store.commit('selectArea/setSelectedAreaCode', '');
        //       this.$store.commit('selectArea/setSelectedShiTitle', '');
        //       this.$store.commit('selectArea/setSelectedShiCode', '');
        //       removeCookie('selectedBsdtTitle');
        //       removeCookie('selectedBsdtCode');
        //       removeCookie('selectedOrganizationsTitle');
        //       removeCookie('selectedOrganizationsCode');
        //       removeCookie('selectedAreaTitle');
        //       removeCookie('selectedAreaCode');
        //       removeCookie('selectedShiTitle');
        //       removeCookie('selectedShiCode');
      }
    },
    clickBsdt(item) {
      this.addAreaCookie(item);
      this.chooseBsdtData.bsdtObj = item;
      this.$store.commit('selectArea/isShowBsdtDialog', false);
      const zjurl = window.CONFIG.APP_INFO.zjUrl;
      if (zjurl.hasOwnProperty(item.officeName)) {
        // this.removeArea();
        window.location.href = this.$decryptByDES(zjurl[item.officeName]);
      }
    },
    // 获取 本地存储 中的 办事大厅弹窗的数据
    getCookieBsdt() {
      if (this.flag) {
        this.selectArr = cache.get() || {};
        console.log(this.selectArr, 'this.selectArr');
        let arr = Object.keys(this.selectArr);
        if (arr.includes('selectedBsdtTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedBsdtTitle: this.selectArr['selectedBsdtTitle'],
            },
            this.flag,
          ]);
        }

        if (arr.includes('selectedBsdtCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedBsdtCode: this.selectArr['selectedBsdtCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedOrganizationsTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedOrganizationsTitle: this.selectArr['selectedOrganizationsTitle'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedOrganizationsCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedOrganizationsCode: this.selectArr['selectedOrganizationsCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedAreaTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedAreaTitle: this.selectArr['selectedAreaTitle'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedAreaCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedAreaCode: this.selectArr['selectedAreaCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('sectedShengCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShengCode: this.selectArr['selectedShengCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedShiCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShiCode: this.selectArr['selectedShiCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('sectedShengTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShengTitle: this.selectArr['selectedShengTitle'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedShiTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShiTitle: this.selectArr['selectedShiTitle'],
            },
            this.flag,
          ]);
        }
      } else {
        this.selectArr = cacheHasPermission.get() || {};
        let arr = Object.keys(this.selectArr);
        if (arr.includes('selectedBsdtTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedBsdtTitle: this.selectArr['selectedBsdtTitle'],
            },
            this.flag,
          ]);
        }

        if (arr.includes('selectedBsdtCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedBsdtCode: this.selectArr['selectedBsdtCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedOrganizationsTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedOrganizationsTitle: this.selectArr['selectedOrganizationsTitle'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedOrganizationsCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedOrganizationsCode: this.selectArr['selectedOrganizationsCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedAreaTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedAreaTitle: this.selectArr['selectedAreaTitle'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedAreaCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedAreaCode: this.selectArr['selectedAreaCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('sectedShengCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShengCode: this.selectArr['selectedShengCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedShiCode')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShiCode: this.selectArr['selectedShiCode'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('sectedShengTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShengTitle: this.selectArr['selectedShengTitle'],
            },
            this.flag,
          ]);
        }
        if (arr.includes('selectedShiTitle')) {
          this.$store.commit('selectArea/setArea', [
            {
              selectedShiTitle: this.selectArr['selectedShiTitle'],
            },
            this.flag,
          ]);
        }
      }
    },
    //点击 市 区域或者办事大厅的选项
    chooseBsdt(item) {
      this.addAreaCookie(item);
      switch (item.areaType) {
        case '2':
          //省
          this.chooseBsdtData.sheng = item;
          this.chooseBsdtData.bsdtObj = null;
          this.chooseBsdt(this.chooseBsdtData.sheng.childrens[0]);
          break;
        case '3':
          //市
          this.chooseBsdtData.shi = item;
          this.chooseBsdtData.bsdtObj = null;
          // this.chooseBsdt(this.chooseBsdtData.shi.childrens[0]);
          if (this.flag) {
            this.selectArr = cache.get() || {};
          } else {
            this.selectArr = cacheHasPermission.get() || {};
          }
          break;
        case '4':
          //县
          this.chooseBsdtData.xian = item;
          this.chooseBsdtData.bsdtObj = null;
          this.chooseBsdt(this.chooseBsdtData.xian.bsdtData[0]);
          if (this.flag) {
            this.selectArr = cache.get() || {};
          } else {
            this.selectArr = cacheHasPermission.get() || {};
          }
          break;
        default:
          //办事大厅
          this.chooseBsdtData.bsdtObj = item.officeName;
          if (this.flag) {
            this.selectArr = cache.get() || {};
          } else {
            this.selectArr = cacheHasPermission.get() || {};
          }
          this.$store.commit('selectArea/isShowBsdtDialog', false);
          if (this.flag) {
            const zjurl = window.CONFIG.APP_INFO.zjUrl;
            if (zjurl.hasOwnProperty(item.officeName)) {
              this.removeArea();
              window.location.href = this.$decryptByDES(zjurl[item.officeName]);
            }
          }
      }
    },

    // // 初始化办事大厅弹窗里的内容
    initBsdtDialogData(finalAreaData) {
      if (this.flag) {
        this.selectArr = cache.get() || {};
      } else {
        this.selectArr = cacheHasPermission.get() || {};
      }
      // console.log(this.chooseBsdtData,'chooseBsdtData')
      //省
      for (let i = 0, len = finalAreaData.length; i < len; i++) {
        if (finalAreaData[i].code === window.CONFIG.code) {
          this.chooseBsdtData.sheng = finalAreaData[i];
        }
      }
      //市
      if (
        !this.chooseBsdtData.sheng ||
        !this.chooseBsdtData.sheng.childrens ||
        this.chooseBsdtData.sheng.childrens.length <= 0
      ) {
        return;
      }
      for (let i = 0, len = this.chooseBsdtData.sheng.childrens.length; i < len; i++) {
        if ('selectedShiCode' in this.selectArr) {
          if (this.chooseBsdtData.sheng.childrens[i].code === this.selectArr.selectedShiCode) {
            this.chooseBsdtData.shi = this.chooseBsdtData.sheng.childrens[i];
            // this.$store.commit(
            //   "selectArea/setArea",
            //   this.chooseBsdtData.shi.title
            // );
            // this.$store.commit('selectArea/setArea', );
          }
        }
      }
      //县
      if (
        !this.chooseBsdtData.shi ||
        !this.chooseBsdtData.shi.childrens ||
        this.chooseBsdtData.shi.childrens.length <= 0
      ) {
        return;
      }
      for (let i = 0, len = this.chooseBsdtData.shi.childrens.length; i < len; i++) {
        if ('selectedAreaCode' in this.selectArr) {
          if (this.chooseBsdtData.shi.childrens[i].code === this.selectArr.selectedAreaCode) {
            this.chooseBsdtData.xian = this.chooseBsdtData.shi.childrens[i];
            // this.$store.commit('selectArea/setSelectedAreaTitle', this.chooseBsdtData.xian.title);
            // this.$store.commit("selectArea/setArea", [
            //   this.chooseBsdtData.shi.title,
            //   "setSelectedAreaTitle",
            // ]);
          }
        }
      }
      if (this.chooseBsdtData.xian.bsdtData) {
        if ('selectedBsdtCode' in this.selectArr) {
          for (let i = 0, len = this.chooseBsdtData.xian.bsdtData.length; i < len; i++) {
            if (this.chooseBsdtData.xian.bsdtData[i].officeCode === this.selectArr.selectedBsdtCode) {
              this.chooseBsdtData.bsdtObj = this.chooseBsdtData.xian.bsdtData[i];
            }
          }
        }
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
.color2c8bff {
  color: #2c8bff;
}
.header1 {
  .location-wrap {
    display: flex;
    flex-direction: column;
    margin: auto 35px;
    padding: 14px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    cursor: pointer;

    .location-top {
      img {
        vertical-align: middle;
        margin-right: 6px;
      }
      .area-name {
        margin: 0 10px 0 6px;
        color: #333;
        font-size: 18px;
        font-weight: bold;
      }

      .arrow-icon {
        color: #9b9b9b;
        font-size: 14px;
      }
    }

    p {
      color: #999;
      font-size: 14px;
    }
  }

  /deep/ .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        .el-dialog__title {
          font-size: 24px;
          font-weight: bold;
        }
      }
      .el-dialog__body {
        .bsdt-dialog-content-wrap {
          .bsdt-item {
            padding: $spacing-base 0;
            border-bottom: 1px dotted #d2d2d2;
            font-size: 18px;
            color: #333;

            .bsdt-item-label {
              width: 108px;
              line-height: 34px; // 为了与第一排的 区域对齐
              text-align: right;
              font-weight: bold;
            }

            .bsdt-item-content {
              flex: 1;

              .bsdt-span {
                display: inline-block;
                margin: 0 16px;
                padding: 8px 12px 8px 10px;
                cursor: pointer;
              }

              .chosen-bsdt-span {
                background: #2c8bff;
                color: #fff;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
