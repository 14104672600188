/**
 * 首页路由配置
 */
export default {
  // name: 'quanSZM-query',
  // path: '/home/info-search/quanSZM-query',
  // component: quanSZMQuery,
  name: 'newQuanSZM-query',
  path: '/home/info-search/newQuanSZM-query',
  component: () => import('../../views/newQuanSZM-query'),
  meta: {
    label: '不动产(房产)查询',
    isNotMenu: true,
    activeMenu: '/home/info-search',
    permission: 'IEBDC:SY:BDCZMCX', // permission: 'IEBDC:SY:XXCX:FCXXCX',
    // construction: 'IEBDC:CX:ZSCX', //construction: 'IEBDC:SY:XXCX:FCXXCX:SFZJ',
    icon: require('../../assets/images/qszmcx.png'),
    // background: 'linear-gradient(to bottom right, #37A3FD , #2367F1)'  //IEBDC:SY:BDCZMCX    IEBDC:CX:ZSCX
  },
};
