import { areaUserPermission, getRoleById } from '../../api/uums';
import { localDataPermission } from '@iebdc/utils/local-data';
/**
 * permission store
 * @description
 * 权限的状态存储
 */
export default {
  state: {
    permission: localDataPermission.get() || []
  },
  getters: {
    //判断是否有权限
    hasPermission(state, permission) {
      return function (key) {
        return permission.includes(key);
      };
    }
  },
  mutations: {
    setPermission(state, permission) {
      state.permission = permission;
    }
  },
  actions: {
    getPermission({ commit }, userId = null, callBack) {
      const params = { areaCode: window.CONFIG.code, userId };
      //跨省通办用户专用
      //   console.log(window.CONFIG.APP_INFO.KSroleId);
      //   sessionStorage.setItem('userRoleId', window.CONFIG.APP_INFO.KSroleId);
      //   console.log('userRoleId', sessionStorage.getItem('userRoleId'));
      if (sessionStorage.getItem('userRoleId')) {
        getRoleById({ roleId: sessionStorage.getItem('userRoleId') })
          .then((res) => {
            if (res.success) {
              let permission = res.data.permissions.map((item) => item.permValue);
              localDataPermission.set(permission);
              commit('setPermission', permission);
            }
            // callBack();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        areaUserPermission(params).then((res) => {
          const { data } = res;
          if (!data || data.length === 0) {
            return;
          }
          let permission = data.map((item) => item.permValue);
          localDataPermission.set(permission);
          commit('setPermission', permission);
        });
      }
    }
  }
};
