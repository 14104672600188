<template>
  <footer class="footer">
    Copyright © 2017-2020 甘肃省“互联网＋不动产登记”信息平台1 - Powered By GreatMap V1.0
  </footer>
</template>

<script>
export default {
  name: 'sign-in-footer'
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100px;
  text-align: center;
  font-size: 12px;
  line-height: 70px;
  background: #f2f6fc;
}
</style>
