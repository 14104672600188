import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 查询 文章列表
export const getPolicyData = (params) =>
  request({
    url: REST_SERVICE.policy.getPolicyData,
    method: 'get',
    params
  });

// 查询 文章详细内容
export const getPolicyDetail = (params) =>
  request({
    url: REST_SERVICE.policy.getPolicyDetail,
    method: 'get',
    params
  });


