import { AreaCacheObject } from './area-cache-object';

// 包含权限
export const cacheHasPermission = new AreaCacheObject('IEBDC_SELECT_AREA_HAS_PERMISSION');
// 不包含权限
export const cache = new AreaCacheObject('IEBDC_SELECT_AREA');

export const CacheObj = (name) => {
  return name === 'cacheHasPermission' ? cacheHasPermission : cache;
};
