import Vue from 'vue';
import Jbr from './index.vue';

const JbrDialog = Vue.extend(Jbr);

Jbr.install = function (data) {
  let instance = new JbrDialog({
    data,
  }).$mount();

  document.body.appendChild(instance.$el);

  Vue.nextTick(() => {
    instance.dialogVisible = true;
    // show 和弹窗组件里的show对应，用于控制显隐
  });
};

export default Jbr;
