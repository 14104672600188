/**
 * 首页路由配置
 */
export default {
  path: '/home/statement',
  name: 'statement',
  component: () => import('iebdc/views/statement'),
  meta: {
    isNotMenu: true,
    permission: 'IEBDC:GNXS:DBLJ'
  }
};
