import request from '../utils/request';
import { GLOBAL_CONFIG, REST_SERVICE } from '../config';
import { localDataApp, localDataUser } from '@iebdc/utils/local-data';
import { getAreaCode } from '../utils/common';
import { getAreaData } from '@iebdc/api/register';
import Vue from 'vue';
import store from '../store/index';

// 获取AppInfo
export const getAppInfo = () => {
  return request({
    url: REST_SERVICE.app.getAppInfo,
    method: 'get',
    params: {
      name: GLOBAL_CONFIG.APP_IEBDC_KEY,
    },
  }).then((res) => {
    localDataApp.set({
      appId: res.data.id,
    });
  });
};

export const getArea = () => {
  // //获取所有区域
  getAreaData().then((res) => {
    if (res.success) {
      store.dispatch('setArea', res.data);
    }
  });
};

// 获取uums用户信息（菜单权限）
export const getUserInfoByUserId = (params, userInfo) => {
  return request({
    url: REST_SERVICE.userInfo.getUserInfo,
    method: 'get',
    params: { userId: params.userId },
  }).then((res) => {
    if (res.success && res.data) {
      let data = Object.assign(params, {
        realName: res.data.realName,
        userInfo: res.data.userInfo,
        areaCode: getAreaCode(res.data.organizations),
      });
      if (localDataUser.get()) data = Object.assign(localDataUser.get(), data);
      localDataUser.set({
        ...data,
        ...userInfo,
      });
      store.dispatch('getPermission', params.userId);
      return params, userInfo;
    }
  });
};

export const findUserCenter = (params) => {
  return request({
    url: REST_SERVICE.register.findUser,
    method: 'get',
    params: { loginName: params.userName },
  });
};

//获取用户基本信息
export const findUser = (params) => {
  return request({
    url: REST_SERVICE.register.findUser,
    method: 'get',
    params: { loginName: params.userName },
  }).then((res) => {
    if (res.success && res.data) {
      //判断是否实名
      if (window.CONFIG.UNIFIED_PERSONAL) {
        if (res.data.institutionalUser && res.data.institutionalUser.userCategory === 'WSMRZ') {
          //是否实名认证
          // new Vue()
          //   .$confirm('用户未实名认证，请前往实名认证！', '提示', {
          //     confirmButtonText: '立马认证',
          //     cancelButtonText: '暂不认证',
          //     type: 'warning',
          //     center: true
          //   })
          //   .then(() => {
          //     if (res.data.agentUser) {
          //       Vue.prototype.$slsm();
          //     } else if (res.data.userCategory === 'NORMAL') {
          //       Vue.prototype.$slsm();
          //     } else {
          //       Vue.prototype.$qyrz();
          //     }
          //   })
          //   .catch();
          //是否实名认证
          // Vue.prototype.$alert('该用户在统一认证中未实名通过，请前往实名认证！', '提示', {
          //   confirmButtonText: '确定',
          //   type: 'warning',
          //   callback: () => {
          //     window.location.href = window.CONFIG.UNIFIED_PERSONAL;
          //   }
          // });
        }
      }
      //判断是否验证
      let userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
      // 用户类型
      userInfo.userType = res.data.institutionalUser ? 'jg' : 'gr';
      // 同意联系电话字段
      userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
      // 用户是否实名注册
      userInfo.authName = res.data.authName;
      //代理人角色
      if (res.data.agentUser) {
        userInfo.agentUser = res.data.agentUser;
        userInfo.id = res.data.agentUser.id;
        userInfo.realNameStatus = res.data.agentUser.realNameStatus;
      }
      userInfo = Object.assign(localDataUser.get(), userInfo);
      localDataUser.set({
        ...userInfo,
      });
    }
  });
};

export const updateRealNameStatus = (data) =>
  request({
    url: REST_SERVICE.user.updateRealNameStatus,
    method: 'post',
    data: data,
  });
