<template>
  <div>
    <div v-if="!isSm">
    <el-form label-width="120px" :model="formData" :rules="rules" ref="form">
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="formData.name" placeholder="在此输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="民族：" prop="mz">
        <dict-select type="民族" clearable v-model="formData.mz"></dict-select>
      </el-form-item>
      <el-form-item label="身份证号：" prop="zjh">
        <el-input v-model="formData.zjh" placeholder="请输入您的证件号码"></el-input>
      </el-form-item>
      <el-form-item label="性别：">
        <dict-select type="性别" v-model="formData.xb" placeholder="" disabled></dict-select>
      </el-form-item>
      <el-form-item label="出生日期：">
        <el-date-picker disabled v-model="formData.csrq" type="date" value-format="yyyy-MM-dd" placeholder=""> </el-date-picker>
      </el-form-item>
      <el-form-item class="text-center">
        <el-button @click="authUser" class="next-btn">立即注册</el-button>
      </el-form-item>
    </el-form>
     </div>
    <div class="text-center" style="padding-left: 120px;">
      <el-button class="next-btn" v-if="hasPermission('IEBDC:GNXS:WXGZH') && !isSm" @click="isSm = !isSm">
        扫码注册
      </el-button>
    </div>
    <el-form v-if="isSm && hasPermission('IEBDC:GNXS:WXGZH')">
      <el-form-item>
        <div class="ewm-title text-center">微信扫描，快捷认证</div>
        <div class="ewm-content">
          <el-image class="img" :src="ewmUrl"></el-image>
        </div>
      </el-form-item>
      <el-form-item class="text-center">
        <el-button class="next-btn" @click="isSm = !isSm">电脑注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateName, validateZjh } from '../common/validate';
import hasPermission from '../../../mixin/hasPermission';
import { getEwm, authGrUser } from '../../../api/register';
export default {
  name: 'auth-gr',
  mixins: [hasPermission],
  /* props: {
    userInfo: {
      type: Object,
      required: true
    }
  }, */
  data() {
    // 表单验证规则
    this.rules = {
      name: [{ required: true, validator: validateName, trigger: 'change' }],
      mz: [{ required: true, message: '请选择您的民族', trigger: 'change' }],
      zjh: [
        {
          required: true,
          validator: validateZjh(this.getDataFromCard),
          trigger: 'change'
        }
      ]
    };
    return {
      isSm: false, // 是否使用二维码注册
      ewmUrl: '', // 二维码图片地址
      formData: {
        // id: '5e3fd3014a208529bf19297a',
        // loginName: 'QAWS202002090006',
        // id: this.userInfo.id,
        // loginName: this.grFirstStepData.loginName,
        csrq: '',
        xb: ''
      }
    };
  },
  computed: {
    ...mapGetters(['grFirstStepData'])
  },
  mounted() {
    if (this.hasPermission('IEBDC:GNXS:WXGZH')) {
      // 二维码扫描登录
      this.isSm = true;
      this.getEwm();
    } else {
      // 电脑登录
      this.isSm = false;
    }
  },
  methods: {
    // 根据身份证获取用户的信息
    getDataFromCard(value) {
      const strCord = String(value);
      // 420881199809101019
      const birthdayStr = strCord.slice(6, 14);
      this.formData.xb = this.getSex(strCord);

      this.formData.csrq = `${birthdayStr.slice(0, 4)}-${birthdayStr.slice(4, 6)}-${birthdayStr.slice(6, 9)}`;
    },
    // 获取性别
    getSex(idCard) {
      let sexVal = '';
      if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexVal = 1; //男性
      } else if (parseInt(idCard.slice(-2, -1)) % 2 === 0) {
        sexVal = 2; // 女性
      } else {
        sexVal = '不详';
      }
      return sexVal;
    },
    // 获取二维码
    getEwm() {
      const params = {
        url: window.CONFIG.ewm_path_login,
        loginName: this.grFirstStepData.loginName,
        intend: '123'
      };
      getEwm(params).then((res) => {
        if (res && res.success) {
          this.ewmUrl = res.data;
        }
      });
    },
    authUser() {
      // 验证表单
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.formData.loginName = this.grFirstStepData.loginName;
          authGrUser(this.formData).then((res) => {
            if (res && res.success) {
              let passToFinalStepData = {
                userName: this.grFirstStepData.loginName,
                phone: this.grFirstStepData.tel,
                name: this.formData.name,
                zjh: this.formData.zjh
              };
              this.$store.dispatch('sign-in-step/getGrSecondStepData', passToFinalStepData);
              // this.$emit('callback', passToFinalStepData);
              this.$store.dispatch('sign-in-step/getGrActiveStep', 2);
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.ewm-content {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 335px;
  height: 335px;
  /deep/.el-image {
    width: 335px;
    height: 335px;
    border: 1px solid #ffffff;
  }
  /*.img {*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*}*/
}
</style>
