<template>
  <div class="header flex">
    <div class="img">
      <img :src="logo" />
      <!-- <span class="title">欢迎注册 {{ userType }}版</span> -->
    </div>
    <div class="login">
      已有账号,<router-link to="login" class="link">马上登录</router-link>|
      <router-link to="home" class="link">返回首页</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sing-in-header',
  data() {
    return {
      logo: require('../../../assets/images/dashboard/' + window.CONFIG.APP_INFO.logo),
    };
  },
  /* props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    userType() {
      return this.type === 'gr' ? '个人' : '企业';
    }
  } */
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  padding: 15px 100px;
  justify-content: space-between;
  .img {
    img {
      height: 45px;
    }
  }
  .title {
    display: inline-block;
    margin-left: 20px;
    transform: translateY(-20px);
    font-size: 20px;
  }
  .login {
    line-height: 40px;
    float: right;
    margin-right: 0;

    .link {
      margin: 0 5px;
    }
  }
}
</style>
