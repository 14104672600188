import Vue from 'vue';
import { getAppInfo, getUserInfoByUserId, findUser, getArea } from '@iebdc/api/user-server.js';
import {
  checkTokenByAppKey
  // getCode
} from '@iebdc/api/login';

export const checkTokenHandle = (token, next) => {
  checkTokenByAppKey({ token: token, appKey: window.CONFIG.APP_IEBDC_KEY }).then((res) => {
    if (res.success) {
      const params = {
        userId: res.data.userId,
        userName: res.data.userName
      };
      Promise.all([getAppInfo(), getUserInfoByUserId(params), findUser(params), getArea()]).then(() => {
        Vue.prototype.$message.success('登录成功！');
        next('/home-login-out');
      });
    }
  });
};
