<template>
    <div class="noData" :style="{marginTop: data.marginTop}">
      <div class="imgbox" :style="{width: data.imgWidth,height: data.imgHeight}">
        <img :src="data.src" />
      </div>
      <div class="mt40">
        <p class="color2C8BFF fs24">{{data.title}}</p>
        <p class="fs20">{{data.description}}</p>
      </div>
    </div>
</template>

<script>
    export default {
      props: {
        data: {
          type: Object,
          default:()=>{
            return {
              src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
              imgWidth: '242px',
              imgHeight: '194px',
              marginTop: '-146px',
              title: '很抱歉',
              description: '暂无数据...',
            }
          }
        }
      },
    }
</script>

<style scoped lang="scss">
  .mt40{
    margin-top: 40px;
  }
  .fs24{font-size: 24px;}
  .fs20{font-size: 20px;margin-top: 10px;}
  .color2C8BFF{color: #2C8BFF;}
  .noData{
    position: absolute;
    text-align: center;
    width: 244px;
    height: 292px;
    margin-left: -122px;
    top: 50%;
    margin-top: -146px;
    left: 50%;
  }
  .imgbox {
    display: inline-block;
    img{
      width: 100%;
      height: 100%;
    }
  }
</style>
