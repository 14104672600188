/**
 * 首页路由配置
 */
export default {
  path: '/home-login-out',
  name: '/home-login-out',
  component: () => import('iebdc/views/new-dashboard'),
  meta: {
    label: '首页',
    permission: 'IEBDC:SY'
  }
};
