import { areaUserPermission } from '../../api/uums';
import { localDataPermission } from '@iebdc/utils/local-data';
/**
 * permission store
 * @description
 * 权限的状态存储
 */
export default {
  state: {
    construction: localDataPermission.get() || []
  },
  getters: {
    //判断该模块是否在建状态
    hasConstruction(state, construction) {
      return function(key) {
        return construction.includes(key);
      };
    }
  },
  mutations: {
    setConstruction(state, construction) {
      state.construction = construction;
    }
  },
  actions: {
    getConstruction({ commit }, userId = null) {
      const params = { areaCode: window.CONFIG.code, userId };
      areaUserPermission(params).then((res) => {
        const { data } = res;
        if (!data || data.length === 0) {
          return;
        }
        let construction = data.map((item) => item.permValue);
        localDataPermission.set(construction);
        commit('setConstruction', construction);
      });
    }
  }
};
