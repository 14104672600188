/**
 * 首页路由配置
 */
export default {
  path: '/home/xxfw/policy-law',
  name: 'policy-law',
  component: () => import('iebdc/views/policy-law'),
  meta: {
    label: '政策法规',
    // isNotMenu: true,
    // activeMenu: '/home/xxfw',
    // permission: 'IEBDC:SY:XXFW:ZCFG',
    permission: 'IEBDC:ZCFG',
    construction: 'IEBDC:SY:XXFW:ZCFG:SFZJ',
    // icon: 'iconauction-fill',
    // background: 'linear-gradient(to bottom right, #37A3FD , #2367F1)'
  }
};
