<template>
  <div>
    <!-- <object id="UCAPI"
            classid="CLSID:18201fef-1ac1-4900-8862-5ec6154bb307"
            style="display: none"></object> -->
    <object id="SMObj" classid="clsid:E5C44C76-610A-4C1F-9083-6CE933E3DC1D" style="display: none"></object>
    <div class="login-main-wrap">
      <div class="login-wrap">
        <div class="login-user-type pointer">
          <span
            v-for="(item, index) in userType"
            :key="index"
            :class="{ 'active-type': activeType === index }"
            @click="activeType = index"
            >{{ item.label }}</span
          >
        </div>
        <el-form :model="formData" class="login-form" :rules="rules" ref="loginForm">
          <!--账号密码登录-->
          <div class="login-form-div" v-if="activeType === 1 || (activeType === 0 && !isUkLogin_gr)">
            <el-form-item prop="usernameOrPhone">
              <i class="sjhIcon loginicon"></i>
              <el-input placeholder="请输入您的用户名和手机号" v-model="formData.usernameOrPhone"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <i class="mmIcon loginicon"></i>
              <el-input placeholder="请输入您的密码" show-password v-model="formData.password"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="flex">
                <div style="width: 58%">
                  <i class="yzmIcon loginicon"></i>
                  <el-input placeholder="请输入验证码" v-model="formData.code"></el-input>
                </div>
                <div class="pointer code" @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
                <!-- <div @click="refreshCode" class="pointer link" style="line-height: 40px">换一张</div> -->
              </div>
            </el-form-item>
            <el-form-item v-if="activeType === 1 && permission_jmgyz && permission_ukyz">
              <el-select style="width: 100%" v-model="verify_way">
                <el-option label="加密狗验证" value="verify_way_jmg"></el-option>
                <el-option label="ukey验证" value="verify_way_ukey"></el-option>
              </el-select>
            </el-form-item>
            <div v-if="activeType === 0 && $hasPermission('IEBDC:DL:GRUKDL')" class="login_method_select_content">
              <el-link :underline="false" type="primary" @click.native="initUkey">ukey登录</el-link>
            </div>
          </div>
          <!--ukey登录-->
          <div class="login-form-div" v-if="isUkLogin_gr && activeType === 0">
            <el-form-item prop="value">
              <el-select style="width: 100%" v-model.trim="formDataUk.value" placeholder="请选择安全证书">
                <el-option
                  v-for="item in showUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="pin">
              <el-input v-model.trim="formDataUk.pin" placeholder="pin" class="input-text" show-password />
            </el-form-item>
            <div class="login_method_select_content">
              <el-link :underline="false" type="primary" @click.native="isUkLogin_gr = false">账号密码登录</el-link>
            </div>
          </div>
          <el-form-item>
            <el-button
              type="primary"
              class="login-button"
              @click.native="submit"
              @keyup.enter.native="submit"
              style="margin-bottom: 30px"
              >立即登录</el-button
            >
          </el-form-item>

          <el-form-item class="password-check no-mb">
            <el-checkbox v-model="checked" :class="checked ? 'isChecked' : ''">记住密码</el-checkbox>
            <div class="right">
              <el-link :underline="false" type="primary" @click.native="goPreSignIn">立即注册</el-link
              >&nbsp;&nbsp;|&nbsp;&nbsp;
              <el-link :underline="false" type="primary" @click.native="gotoChangePassword">忘记密码?</el-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from './component/identify';
import {
  getUser,
  fetchFun,
  jsonp,
  isBlack,
  login,
  userInfo,
  getRandom,
  downloadExe,
  checkKey,
  checkKeyByHn,
  checkTokenByAppKey,
} from '../../api/login';
import { getAppInfo, getUserInfoByUserId } from '../../api/user-server.js';
import { REST_SERVICE } from '../../config';
import { crypto } from '../../utils/crypto.js';
import { isIe, CerticateCollectionX } from '../../utils/multibrowser';
import hasPermission from '../../mixin/hasPermission';
// import verCode from '../../mixin/ver-code';
import utils from '@/utils/utils';
const userTypes = ['gr', 'jg'];
import { mapMutations } from 'vuex';
import { tr } from 'date-fns/locale';
export default {
  name: 'LoginDialog',
  mixins: [hasPermission],
  components: {
    SIdentify,
  },
  props: {
    isShow: Boolean,
  },
  data() {
    // 登录表单验证规则
    // 验证码校验
    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      let newVal = value.toLowerCase();
      let identifyStr = this.identifyCode.toLowerCase();
      if (newVal === '') {
        callback(new Error('请输入验证码'));
      } else if (newVal !== identifyStr) {
        callback(new Error('验证码不正确!'));
      } else {
        callback();
      }
    };
    /* const validateCode = (rule, value, callback) => {
      if (value !== 0 && !value) {
        callback(new Error('请输入验证码'));
      } else {
        if (value !== this.codeVerify) {
          // 刷新验证码
          getCode();
          callback(new Error('验证码有误'));
        }
        callback();
      }
    }; */
    return {
      serialNum: '', //uk秘钥
      // 新增记住密码
      checked: false,
      isUkLogin_gr: false, // 个人用户是否ukey登录
      verify_way: 'verify_way_jmg', // 验证方式
      formData: {
        usernameOrPhone: '',
        password: '',
        code: '',
        value: '',
        pin: '',
      },
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      identifyCode: '',
      rules: {
        usernameOrPhone: [{ required: true, message: '请输入用户名或电话号码', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        code: [{ required: true, validator: validateVerifycode, trigger: 'change' }],
        value: [{ required: true, message: '安全证书不能为空', trigger: 'change' }],
        pin: [{ required: true, message: 'pin不能为空', trigger: 'blur' }],
      },
      userType: [
        { label: '个人登录', permission: 'IEBDC:DL:GR', value: 'gr' },
        { label: '法人登录', permission: 'IEBDC:DL:QY', value: 'qy' },
      ],
      activeType: 0,
      certs: null,
      showUserList: [], // 安全证书列表
      formDataUk: {}, // ukey登录表单
    };
  },
  computed: {
    permission_jmgyz() {
      // 加密狗验证权限
      return this.$hasPermission('IEBDC:DLZC:JMGYZ');
    },
    permission_ukyz() {
      // uk验证权限
      return this.$hasPermission('IEBDC:DLZC:UKYZ');
    },
    verify_way_ukey() {
      if (this.permission_jmgyz && this.permission_ukyz) {
        return this.verify_way === 'verify_way_ukey';
      } else {
        return this.permission_ukyz;
      }
    },
    verify_way_jmg() {
      if (this.permission_jmgyz && this.permission_ukyz) {
        return this.verify_way === 'verify_way_jmg';
      } else {
        return this.permission_jmgyz;
      }
    },
  },
  created() {
    var _this = this;
    document.onkeydown = function (e) {
      if (e.keyCode === 13) {
        _this.submit();
      }
    };
  },
  watch: {
    activeType(val) {
      if (Number(val) === 1) {
        // 初始化金格uk
        if (this.permission_ukyz) {
          this.initJinGe();
        }
      }
    },
  },
  mounted() {
    // 验证码初始化
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);

    // 记住密码时，获取cookie中的信息
    this.getCookie();
    // // 初始化验证码
    // this.getVerCode();
    // 拿到区域权限 根据区域权限判断显示个人还是企业
    this.activeType = this.$hasPermission('IEBDC:DL:GR') ? 0 : 1;
  },
  methods: {
    //注册
    ...mapMutations('user-type', ['setUserType']),
    beforeClose() {
      this.$emit('dialogBeforeClose');
    },
    // 新增验证码相关逻辑 start
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 新增验证码相关逻辑 end

    // 新增跳转修改密码
    gotoChangePassword() {
      this.$router.push({ path: '/change-password', query: { userType: this.userType[this.activeType].value } });
    },

    // 新增跳转 注册
    goPreSignIn() {
      this.$router.push('/sign-in');
    },

    // 新增记住密码--设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      window.document.cookie = 'userName' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie = 'userPwd' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString();
    },
    // 新增记住密码--获取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          if (arr2[0] === 'userName') {
            this.formData.usernameOrPhone = arr2[1];
          } else if (arr2[0] === 'userPwd') {
            this.formData.password = crypto.decryptByDES(arr2[1]);
          }
        }
      }
    },
    // 新增记住密码--清除cookie
    clearCookie: function () {
      this.setCookie('', '', -1);
    },
    // 初始化金格uk
    initJinGe() {
      if (SMObj.WebGetKeySN()) {
        this.serialNum = SMObj.WebGetKeySN();
      }
    },
    // 初始化ukey签名证书
    initUkey() {
      this.isUkLogin_gr = true;
      //声明证书集对象
      if (isIe()) {
        //IE
        this.certs = document.getElementById('UCAPI');
      } else {
        this.certs = new CerticateCollectionX();
      }
      //根据属性设置过滤条件(1表示使用SKF接口，2表示CSP(CSP不能枚举SM2证书)，)
      this.certs.setCryptoInterface(1);
      //软证书过滤标识(0禁用，1允许，默认0)
      //certs.setCF_SoftEnabled(1);
      //过滤签名证书算法("sm2","rsa")
      this.certs.setCF_CertAlg('sm2');
      //获取签名证书
      this.certs.setCF_KeyUsage(0x20);
      //加载证书集返回值等于0表示成功
      let loadResult = this.certs.Load();
      let len = this.certs.getSize();
      if (loadResult !== 0 || len < 1) {
        this.loginBtnDisable = true;
        this.$message({
          message: '未获取到证书',
          type: 'error',
        });
        return false;
      }
      this.loginBtnDisable = false;
      for (let i = 0; i < len; i++) {
        let cert = this.certs.GetAt(i);
        let certObj = {};
        certObj.label = cert.getFriendlyName();
        certObj.value = i;
        this.showUserList = [...this.showUserList, certObj];
      }
    },
    // 验证登录表单
    submit() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          // 如果有uk加密权限则企业用户先金格ukey验证
          if (this.verify_way_ukey && this.activeType === 1) {
            this.jinGrUk();
          } else if (this.verify_way_ukey && this.activeType === 1 && !this.serialNum) {
            this.$message.error('企业用户请绑定uk使用ie浏览器登录!');
          } else {
            // 普通登录
            this.verifyUser(userTypes[this.activeType]);
          }
        }
      });
    },
    //登录
    login(username) {
      const params = {
        username: username,
        password: this.formData.password,
        userType: this.formData.userType,
      };
      //新增 验证通过判断是否勾选记住密码 勾选则配置cookie
      if (this.checked) {
        //加密然后存入cookie
        this.setCookie(this.formData.usernameOrPhone, crypto.encryptByDES(this.formData.password));
      } else {
        this.clearCookie();
      }
      login(params).then((res) => {
        if (res && res.success) {
          // this.$message.success('登登录成功！');
          // this.$router.push('/');
          // 登录成功后获取用户信息判断状态
          const parameter = {
            token: res.data.gmsso_cli_ec_key,
          };
          //普通登录存储用户状态,vuex
          this.setUserType(this.activeType);
          this.getUserInfo(username, parameter);
        } else {
          //登录失败
          const resultMessage = res.message;
          if (
            resultMessage.search(/该用户超过3个月没有登录，为保证安全，请重新设置密码！/g) >= 0 ||
            resultMessage.search(/用户距离上次修改密码已经超过3个月，为保证安全，请重新设置密码！/g) >= 0
          ) {
            this.$confim(res.message, '提示', {
              confirmButtonText: '修改密码',
              cancelButtonText: '取消',
            }).then(() => {
              // 显示修改密码页面
              this.gotoChangePassword();
            });
          } else {
            this.$message.warning(res.message);
          }
        }
      });
    },
    // 通过ukey登录
    loginByUK() {
      //声明签名证书对象
      let signCert;
      signCert = this.certs.GetAt(this.formDataUk.value);
      //设置pin码
      signCert.setUserPIN(this.formDataUk.pin);
      //signCert.setDefaultP7Digest("sha256");
      //签名(0表示attach，1表示detach)
      let signedData = signCert.PKCS7String('12', 0);
      const obj = this.showUserList.find((item) => {
        return item.value === this.formDataUk.value;
      });
      const username = obj.label;
      const timestamp = new Date().getTime();
      const params = {
        password: timestamp.toString(),
        username,
        signedData,
        base64Code: '',
      };
      login(params, 'ukey').then((res) => {
        if (res && res.success) {
          // 登录成功后获取用户信息判断状态
          this.getUserInfo(username);
          //ukey之后使用vuex存储用户类型
          this.setUserType(this.activeType);
        } else {
          this.$message.success('登录失败！');
        }
      });
    },
    // 通过金格uk在ie浏览器登录
    async jinGrUk() {
      if (this.serialNum) {
        let newUserName = '';
        const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
        // 如果输入的是电话号码
        if (re.test(this.formData.usernameOrPhone)) {
          // 先根据号码获取用户 然后按照用户验证步骤来
          newUserName = await this.getUkName();
          console.log(newUserName, 'newUserName');
          this.getInfo(newUserName);
        } else {
          newUserName = this.formData.usernameOrPhone;
          this.getInfo(newUserName);
        }
      } else {
        this.$message.error('请给当前用户绑定的uk秘钥！');
      }
    },
    async getInfo(username) {
      let randNum = await this.getRandom(username); // 拿到随机数
      if (randNum) {
        let jgBtn = await this.checkKeyByHn(username, this.serialNum);
        console.log(jgBtn, 'jgBtn');
        if (jgBtn === true) {
          this.verifyUser(userTypes[this.activeType]);
        } else {
          this.$message.error('当前用户绑定的uk秘钥有误！');
        }
      }
    },
    // 检测token
    checkTokenHandler(parameter, userInfo) {
      return checkTokenByAppKey(parameter).then((res) => {
        if (res.success) {
          const params = {
            userId: res.data.userId,
            userName: res.data.userName,
          };
          Promise.all([getAppInfo(), getUserInfoByUserId(params, userInfo)]).then(() => {
            this.$message.success('登录成功！');
            this.isLoading = false;
            setTimeout(() => {
              location.reload();
              this.$router.push('/home-login-out');
            }, 300);
          });
        }
      });
    },
    // 获取用户信息
    getUserInfo(username, parameter) {
      userInfo({ loginName: username }).then((res) => {
        if (res && res.success) {
          //判断是否验证
          const userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
          // 用户类型
          userInfo.userType = userTypes[this.activeType];
          // 同意联系电话字段
          userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
          // 用户是否实名注册
          userInfo.authName = res.data.authName;
          let userCategory = userInfo.userCategory;
          if (userCategory === 'WSMRZ') {
            this.$confirm('该用户未实名认证，请进行实名认证！', '提示', {
              confirmButtonText: '立即认证',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                // 跳转到注册页面中的实名认证部分
                this.$router.push({
                  name: 'sign-in',
                  path: '/sign-in',
                  query: {
                    type: this.userType[this.activeType].value,
                  },
                  params: {
                    userInfo,
                  },
                });
                this.$store.dispatch('sign-in-step/getGrFirstStepData', userInfo);
                this.$store.dispatch('sign-in-step/getJgFirstStepData', userInfo);
              })
              .catch(() => {
                // 退回到登录页面
                this.$router.push({ name: 'home-login-out' });
              });
          } else {
            // 调用checkTokenByAppKey验证状态
            return this.checkTokenHandler(parameter, userInfo);
          }
        }
      });
    },
    // 验证用户信息
    verifyUser(type) {
      this.formData.userType = type;
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      // 如果输入的是电话号码
      if (re.test(this.formData.usernameOrPhone)) {
        // 先根据号码获取用户 然后按照用户验证步骤来
        this.getUserName();
      } else {
        // 输入的是用户名 验证
        this.phoneExsitCheck();
      }
    },
    // 获取用户名（根据电话号码）
    getUserName(temp) {
      const params = {
        phone: this.formData.usernameOrPhone,
        userCategory: this.formData.userType === 'gr' ? 'gr' : 'qy',
      };
      getUser(params).then((res) => {
        if (res.data) {
          const username = res.data;
          this.phoneExsitCheck(username);
        }
      });
    },
    // 手机验证是否注册过
    phoneExsitCheck(username = this.formData.usernameOrPhone) {
      const fetchFunUrlAndParams = [
        {
          url: REST_SERVICE.register.exsitGrLoginName,
          params: {
            keyWord: username,
          },
        },
        {
          url: REST_SERVICE.register.exsitJgLoginName,
          params: {
            loginName: username,
          },
        },
      ];
      let fn = () => {
        fetchFun(fetchFunUrlAndParams[this.activeType].url, fetchFunUrlAndParams[this.activeType].params).then(
          (res) => {
            if (res.data) {
              // 判断是否在黑名单内
              this.exsitisblack(username);
            } else {
              this.$message.warning('未找到当前用户！');
            }
          }
        );
      };
      // 在这里如果是企业用户则先验证加密狗
      if (this.formData.userType === 'jg') {
        // 在这里验证加密狗
        if (this.verify_way_jmg) {
          this.verifyJg(username, () => {
            fn();
          });
        } else {
          fn();
        }
      } else {
        fn();
      }
    },
    // 判断在不在黑名单内
    exsitisblack(username) {
      const params = {
        loginName: username,
      };
      isBlack(params).then((res) => {
        if (res.data) {
          this.$message.warning('黑名单限制登录！');
        } else {
          // 不在黑名单 登录
          if (this.activeType === 0) {
            if (this.isUkLogin_gr) {
              this.loginByUK();
              return;
            }
          }
          this.login(username);
        }
      });
    },
    // 验证加密狗
    async verifyJg(username, callback) {
      let strUrl = 'http://127.0.0.1:17681';
      // 验证用户类型
      const rand = await this.getRandom(username); // 拿到随机数
      if (!rand) {
        this.$message.error('获取随机数失败，请检查服务!');
        return false;
      }
      let pJsonData = { function: 'InitiateLock', sn: 0 };
      let strJsonData = JSON.stringify(pJsonData);
      jsonp(strUrl, strJsonData).then(
        (res) => {
          if (res && res.length > 0 && res[0].ret === true) {
            pJsonData = { function: 'Lock32_Function', sn: 0, rand: rand };
            strJsonData = JSON.stringify(pJsonData);
            jsonp(strUrl, strJsonData).then(async (res) => {
              if (res && res.length > 0 && res[0].ret === true) {
                // 验证
                let bRt = await this.checkKey(username, res[0].lock);
                if (bRt === true) {
                  // 验证成功进入首页
                  callback && callback();
                } else {
                  this.$message.error('加密狗验证失败，请确认是否拥有权限！');
                }
              } else {
                this.$message.error('加密狗验证失败，请确认是否插入加密狗！');
              }
            });
          } else {
            this.$message.error('加密狗初始化失败！');
          }
        },
        () => {
          this.$confirm('缺失加密插件，是否下载', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
          }).then(() => {
            // 下载插件
            downloadExe();
          });
        }
      );
    },
    // 获取随机数（用于验证加密狗）
    getRandom(username) {
      return new Promise((reslove) => {
        getRandom({ loginName: username }).then((res) => {
          if (res && res.success) {
            reslove(res.data);
          } else {
            reslove(false);
          }
        });
      });
    },
    // 后台验证加密狗
    async checkKey(username, lock) {
      const params = {
        loginName: username,
        secretKey: lock,
      };
      const res = await checkKey(params);
      if (res && res.data === true) {
        return true;
      } else {
        return false;
      }
    },

    //验证海南信息中心uk秘钥
    async checkKeyByHn(username, lock) {
      const params = {
        loginName: username,
        secretKey: lock,
      };
      const res = await checkKeyByHn(params);
      if (res && res.data === true) {
        return true;
      } else {
        return false;
      }
    },
    // 获取用户名（根据电话号码）
    getUkName() {
      const params = {
        phone: this.formData.usernameOrPhone,
        userCategory: this.formData.userType === 'gr' ? 'gr' : 'qy',
      };
      return new Promise((reslove) => {
        getUser(params).then((res) => {
          if (res.data && res.success) {
            reslove(res.data);
          } else {
            reslove(false);
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
.login-main-wrap {
  text-align: center;
  .loginicon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -9px;
    z-index: 10;
  }
  .sjhIcon {
    background: url('../../assets/images/login/sjh.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .mmIcon {
    background: url('../../assets/images/login/mm.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .yzmIcon {
    background: url('../../assets/images/login/yzm.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .login-wrap {
    margin: 0 auto;
    padding: 0 $spacing-medium;
    background-color: #fff;
    .login-user-type {
      margin-bottom: 20px;
      width: 100%;
      height: 45px;

      span {
        display: inline-block;
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        width: 50%;
        height: 100%;
        color: #282828;
        font-weight: bolder;
        /*border-bottom: 2px solid #dcdfe6;*/
        @extend .transition-default;
      }

      .active-type {
        color: #2196f3;
        /*border-bottom: 2px solid #409eff;*/
      }
    }

    .login-form {
      .login-form-div {
        overflow: hidden;
      }

      /deep/.el-form-item {
        margin-bottom: 20px;

        .el-input__icon {
          color: #666666;
          font-size: 24px;
        }

        // 新增样式
        &.password-check {
          padding: 0px 20px;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: -20px;
          background: #f5f5f5;
          height: 54px;
          .right {
            float: right;
            line-height: 54px;
            color: #2196f3;
            /deep/.el-link {
              color: #2196f3;
            }
          }
          /deep/.el-checkbox {
            float: left;
            line-height: 54px;
            color: #282828;
          }
          /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
            color: #2196f3;
          }
          /deep/ .el-checkbox__inner {
            height: 17px;
            width: 17px;
            border: 2px solid #dcdfe6;
          }

          /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #fff;
          }

          /deep/ .el-checkbox__inner::after {
            top: 3px;
            left: 3px;
            background-color: #0d71f6;
            width: 7px;
            height: 7px;
          }

          /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
            transform: rotate(0deg) scaleY(1);
            -webkit-transform: rotate(0deg) scaleY(1);
          }

          .isChecked {
            /deep/ .el-checkbox__inner {
              border: 2px solid #0d71f6;
            }
          }
        }
      }

      /deep/ .el-input__inner {
        height: 45px !important;
        border: none;
        color: #222;
        font-size: 16px;
        padding-left: 40px;
        box-sizing: border-box;
        background-color: #f5f5f5;
        border-radius: 5px;

        &:focus {
          border: 1px solid #409eff;
          background: #ffffff;
        }
      }
      /deep/.el-input__prefix {
        left: 10px;
      }

      .flex {
        justify-content: space-between;
      }

      .code {
        height: 45px;
        img {
          height: 100%;
        }
      }

      .login-button {
        background-color: #2196f3;
        border-color: #2196f3;
        width: 100%;
        height: 45px;
        font-size: 18px;
      }
    }
  }
  /deep/.el-form-item--small .el-form-item__content {
    line-height: 54px;
  }
}
.login_method_select_content {
  text-align: right;
  transform: translateY(-20px);
}

/** ie浏览器字颜色设置*/
/deep/ input:-ms-input-placeholder {
  color: #b4bccc !important;
}
</style>
