<template>
  <div class="full-height overflow-hidden">
    <Header />
    <div class="main flex-start-justify">
      <div class="step-content box-shadow-base">
        <h1 class="top">找回密码</h1>
        <div class="step-detail">
          <find-password :userType="userType"></find-password>
        </div>
      </div>
    </div>
<!--    <Footer />-->
  </div>
</template>

<script>
import Header from './component/header';
import FindPassword from './component/find-password';
import Footer from './component/footer';

export default {
  name: 'change-password',
  components: {
    Header,
    Footer,
    FindPassword
  },
  data() {
    // this.userType = this.$route.query.userType; // 账户类型
    return {
      userType: ''
    };
  },
  mounted() {
    this.userType = this.$route.query.userType; // 账户类型
    console.log(this.userType);
  }
};
</script>

<style lang="scss" scoped>
.mt-30 {
  margin-top: 30px;
}
.main {
  padding: 71px 0;
  height: 100%;
  /*min-height: 803px;*/
  background: #409eff;
  .step-content {
    overflow: hidden;
    width: 800px;
    background: white;
    // background: hsla(0, 0%, 100%, 0.5);
    // padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .top {
      background: #3a62d7;
      line-height: 80px;
      text-align: center;
      color: #fff;
      font-size: 24px;
      font-weight: normal;
    }

    .step-detail {
      width: 450px;
      margin-left: 50%;
      margin-top: 60px;
      transform: translateX(-50%);
      /deep/ .el-input__inner {
        height: 40px !important;
        &:focus {
          border-bottom: 1px solid #409eff;
        }
      }
    }
  }
}
</style>
