<template>
  <div class="wrap full-width flex-column">
    <Header />

    <div class="main flex-column">
      <div class="main-header-wrap">
        <h1 class="top">注册</h1>
      </div>

      <div class="step-content">
        <div class="content-wrap full flex-center">
          <div class="pre-sign-in-content flex-space-between">
            <div class="sign-in-box" v-for="(item, index) in signInArr" :key="index">
              <router-link
                :to="{
                  name: 'sign-in',
                  query: { type: item.query }
                }"
              >
                <div class="click-area full">
                  {{ item.text }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './component/header';
import Footer from '@/pages/iebdc/layout/Footer';

export default {
  name: 'pre-sign-in',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      signInArr: [
        {
          text: '个人',
          query: 'gr'
        },
        {
          text: '企业',
          query: 'jg'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  height: 100vh;
}
.main {
  overflow-y: scroll;
  flex: 1;
  background-color: #f8f6f9;

  .main-header-wrap {
    padding-top: 60px;
    height: 130px;
    background: url('../../assets/images/signIn/zcbg.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;

    .top {
      margin: 0 auto;
      width: 60%;
      line-height: 70px;
      text-align: center;
      color: #fff;
      font-size: 24px;
      font-weight: normal;
    }
  }

  .step-content {
    flex: 1;
    margin: 0 auto;
    width: 60%;
    background: #fff;

    .content-wrap {
      .pre-sign-in-content {
        width: 50%;
        height: 50%;

        .sign-in-box {
          width: 40%;
          line-height: 300px;
          background: #f8f8f8;
          color: #409eff;
          font-size: 20px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
