import request from '../utils/request';
import { GLOBAL_CONFIG, REST_SERVICE } from '../config';
import { localDataUser } from '@iebdc/utils/local-data';

/**
 * 登陆
 * @return {AxiosPromise}
 */
export function login(params, loginWay = 'cp') {
  let url = '';
  if (loginWay === 'cp') {
    // 账号密码登录
    url = REST_SERVICE.login.login;
  } else if (loginWay === 'ukey') {
    // ukey登录
    url = REST_SERVICE.login.loginUk;
  }
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: 'get',
      params: Object.assign(params, { appKey: GLOBAL_CONFIG.APP_IEBDC_KEY }),
    })
      .then((res) => {
        localDataUser.set({
          username: params.username,
          token: res.data.gmsso_cli_ec_key,
        });
        if (res) {
          resolve(res);
        } else {
          reject();
        }
      })
      .catch((error) => {
        throw new Error(JSON.stringify(error));
      });
  });
}
// 获取验证码
export const getCode = () =>
  request({
    url: REST_SERVICE.login.getCode,
    method: 'get',
  });
// 获取用户
export const getUser = (params) =>
  request({
    url: REST_SERVICE.user.findUserName,
    method: 'get',
    params,
  });
// 用于传入url地址的请求
export const fetchFun = (url, params, method = 'get') => {
  let obj = null;
  if (method === 'get') {
    obj = {
      url,
      method: 'get',
      params,
    };
  }
  if (method === 'post') {
    obj = {
      url,
      method: 'post',
      data: params,
    };
  }
  return request(obj);
};
export const loginByUkey = (params) =>
  request({
    url: REST_SERVICE.ca.loginByUkey,
    method: 'post',
    params,
  });
// 用于请求加密狗
export const jsonp = (url, params) => {
  return new Promise((resolve, reject) => {
    // 这里的 "jsonCallBack" ，和调用的 jsonp 的 url 中的 callback 值相对应（见粗体字）

    window.jsonpcallback = (result) => {
      resolve(result);
    };
    const JSONP = document.createElement('script');

    JSONP.addEventListener('error', () => {
      reject('缺少插件');
    });

    JSONP.type = 'text/javascript';

    JSONP.src = `${url}?callback=jsonpcallback&json=${encodeURIComponent(params)}`;

    document.getElementsByTagName('head')[0].appendChild(JSONP);

    setTimeout(() => {
      JSONP.removeEventListener('error');
      document.getElementsByTagName('head')[0].removeChild(JSONP);
    }, 500);
  });
};
// 判断用户是否在黑名单内
export const isBlack = (params) =>
  request({
    url: REST_SERVICE.isblack,
    method: 'get',
    params,
  });
// 获取用户信息
export const userInfo = (params) =>
  request({
    url: REST_SERVICE.register.findUser,
    method: 'get',
    params,
  });
// 根据用户名获取随机数
export const getRandom = (params) =>
  request({
    url: REST_SERVICE.user.getRandom,
    method: 'post',
    data: params,
  });
// 下载加密狗插件
export const downloadExe = () => {
  window.open(REST_SERVICE.verfiry.download);
};
// 后台验证加密狗
export const checkKey = (params) =>
  request({
    url: REST_SERVICE.verfiry.checkKey,
    method: 'post',
    data: params,
  });
//验证海南信息中心uk秘钥
export const checkKeyByHn = (params) =>
  request({
    url: REST_SERVICE.verfiry.checkKeyByHn,
    method: 'post',
    data: params,
  });
/*
 * 获取userId,userName,token
 */
export const checkTokenByAppKey = (params) => {
  return request({
    url: REST_SERVICE.login.token,
    method: 'get',
    params: {
      ...params,
      appKey: GLOBAL_CONFIG.APP_IEBDC_KEY,
    },
  });
};
// 修改密码
export const changePsw = (params) =>
  request({
    url: REST_SERVICE.verfiry.changePsw,
    method: 'post',
    data: params,
  });
