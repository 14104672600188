// 引入二维码插件
import QRCode from 'qrcodejs2';
// 开始生成二维码
/**desc
 * 这里生成二维码的宽高可以自己调整，包括样式具体参考https://www.npmjs.com/package/qrcodejs2
 * */
const crateQrcode = (text) => {
  return new Promise((resolve, reject) => {
    // 创建div元素
    const element = document.createElement('div');
    // eslint-disable-next-line no-unused-vars
    let qrCode = new QRCode(element, {
      text: text,
      width: 148,
      height: 148,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.Q
    });
    if (element.getElementsByTagName('canvas')[0]) {
      const canvas = element.getElementsByTagName('canvas')[0];
      const src = canvas.toDataURL('image/png');
      resolve(src);
    }
  });
};
export default crateQrcode;
