/**
 * 首页路由配置
 */
export default {
  path: '/home/batchProcessing',
  name: 'batchProcessing',
  component: () => import('iebdc/views/batchProcessing'),
  meta: {
    label: '批量办理',
    isNotMenu: true,
    permission: 'IEBDC:BSZN',
    construction: 'IEBDC:SY:XXFW:BSZN:SFZJ',
  },
  redirect: '/home/batchProcessing/index',
  children: [
    {
      path: '/home/batchProcessing/index',
      name: 'batchProcessingIndex',
      component: () => import('iebdc/views/batchProcessing/flows/chooseTemplate.vue'),
      meta: {
        label: '选择模板',
        icon: 'document',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:YDXZ',
      },
    },
    {
      path: '/home/batchProcessing/apply',
      name: 'batchProcessingApply',
      component: () => import('iebdc/views/batchProcessing/flows/apply.vue'),
      meta: {
        label: '批量办理',
        icon: 'document',
        isNotMenu: true,
        activeMenu: '/home/wybl',
        permission: 'IEBDC:SQ:YDXZ',
      },
    },
  ],
};
