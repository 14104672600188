import Vue from 'vue';
import Qyrz from './index.vue';

const QyrzDialog = Vue.extend(Qyrz);

Qyrz.install = function(data) {
  let instance = new QyrzDialog({
    data
  }).$mount();

  document.body.appendChild(instance.$el);

  Vue.nextTick(() => {
    instance.dialogVisible = true;
    // show 和弹窗组件里的show对应，用于控制显隐
  });
};

export default Qyrz;
