import Vue from 'vue';
import store from '../store/index';
// 没有权限是隐藏
Vue.directive('hasPermission', {
  bind(el, { value }) {
    const permission = store.state.permission.permission;
    if (!permission.includes(value)) {
      el.style.display = 'none';
    }
  }
});
