<template>
  <div>
    <div v-for="(item, index) in routes" :key="index" class="card box-shadow-default" @click="goStep(item)">
      <div class="card-icon" :style="{ backgroundImage: item.meta.background }">
        <!--<i class="icon iconfont " :class="item.meta.icon"></i>-->
        <img :src="item.meta.icon" />
      </div>
      <div class="card-desc">
        <p class="card-desc-title">{{ item.meta.label }}</p>
      </div>
    </div>
    <el-dialog title="实名认证" :visible.sync="smrzVisible" width="33%" @close="close">
      <div class="block">
        <span>请微信扫描下方二维码进行身份认证</span>
        <el-image :src="qrcode"></el-image>
        <span slot="footer">
          <el-button type="primary" @click="getSmrzStatus">刷新认证状态</el-button>
        </span>
        <div style="color: red;">实名认证通过后无跳转，请检查地址是否被浏览器拦截</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { subRoutes } from 'iebdc/router';
import authorizationSearch from '@iebdc/api/authorization-search';
import { localDataUser } from '@iebdc/utils/local-data';

import crateQrcode from '@iebdc/plugins/createQRcode.js';
export default {
  name: 'card-item',
  data() {
    return {
      smrzVisible: false,
      qrcode: '',
      smrzId: '',
      timer: null,
    };
  },
  computed: {
    // 获取当前路由的子路由
    routes() {
      let data = [];
      subRoutes.forEach((item) => {
        if (item.name === this.$route.name) {
          item.children.forEach((pitem) => {
            if (this.$hasPermission(pitem.meta.permission)) {
              // 二级路由（无具体页面）
              data.push(pitem);
            }
          });
        }
      });
      return data;
    },
  },
  beforeDestroy() {
    clearInterval();
  },
  methods: {
    //跳转
    goStep(item) {
      console.log(item);
      if (item && item.meta && item.meta.label === '自治区可视化查询') {
        // window.open('https://www.xinjiangbdc.com.cn/gkcs/dist/#/home');
        this.toSmrz();
      } else {
        this.$router.push({
          name: item.name,
        });
      }
    },
    getSmrzStatus() {
      authorizationSearch.visualQueryfind({ id: this.smrzId }).then((res) => {
        if (res.success && res.data.rzzt) {
          clearInterval(this.timer);
          this.timer = null;
          var userInfo = localDataUser.get();
          var token= {
            areaCode: userInfo.areaCode || '650000',
            idCard: res.data.zjh || '',
            realName: res.data.name || '',
            cardType: userInfo.zjzl || 1,
            token: userInfo.token || '',
            phone: userInfo.tel || '',
            userName: res.data.name || '游客',
          }
          window.open(
            `https://www.xinjiangbdc.com.cn/gkcs/dist/#/home?token=${encodeURIComponent(this.$encryptByDES(JSON.stringify(token), 'greatmap2023.'))}`
          );
          // window.open(
          //   `https://www.xinjiangbdc.com.cn/gkcs/dist/#/home?userName=${localDataUser.get().name}`
          // );
        }
      });
    },
    close() {
      clearInterval(this.timer);
      this.timer = null;
    },
    toSmrz() {
      var userData = localDataUser.get();
      if (!userData) {
        window.open('https://www.xinjiangbdc.com.cn/gkcs/dist/#/home?userName=游客'); 
      }
      let params = {
        name: userData.name,
        zjh: userData.zjh,
      };
      let _this = this;
      authorizationSearch.visualQuerysave(params).then((res) => {
        if (res.success) {
          this.smrzId = res.data.id;
          let url = this.$decryptByDES(window.CONFIG.APP_INFO.certificationUrl);
          url =
            url +
            '?userName=' +
            this.$encryptByDES(userData.name, 'greatmap') +
            '&idCard=' +
            this.$encryptByDES(userData.zjh, 'greatmap') +
            '&receiveSeqNo=' +
            res.data.id +
            '&type=kshcx';
          console.log(url);
          Promise.all([crateQrcode(url)])
            .then((src) => {
              console.log(src);
              _this.qrcode = src[0];  
              _this.smrzVisible = true;
              _this.timer = setInterval(() => {
                this.getSmrzStatus();
              }, 2000);
              _this.$once('hook:beforeDestroy', () => {
                clearInterval(_this.timer);
                _this.timer = null;
              });
            })
            .catch(() => {
              _this.$message.error('获取二维码失败，请联系管理员');
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.card {
  transition: 0.3s all;
  float: left;
  background-color: #fff;
  border-radius: 5px;
  height: 130px;
  line-height: 131px;
  margin-bottom: 20px;
  cursor: pointer;
  width: calc(100% / 4 - 19px);
  margin-right: 25px;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .card-icon {
    float: left;
    margin-top: 36px;
    text-align: center;
    width: 84px;
    height: 84px;
    line-height: 84px;
    //border-radius: 100%;
    color: #ffffff;
    margin-left: 28px;
    .iconfont {
      font-size: 30px;
    }
  }
  .card-desc {
    display: inline-block;
    letter-spacing: 1px;
    text-align: center;
    width: calc(100% - 120px);
    font-size: 18px;
    .card-desc-title {
      font-weight: $--font-weight-primary;
      color: $--color-text-regular;
    }
  }
  &:hover {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 0 6px #999;
    box-shadow: 0 0 6px #999;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    //background-color: #2C8BFF;
    .card-desc,
    .card-desc-title {
      //color: #ffffff;
    }
    .card-icon {
      background: #ffffff !important;
      color: #2c8bff !important;
    }
  }
}
.block {
  text-align: center;
  /deep/.el-image {
    width: 258px;
    height: 260px;
    border: 1px solid #ffffff;
  }
  span {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .rowdisplay {
    display: inline-block;
    width: 100%;
    padding: $spacing-base $spacing-medium;
    .el-col {
      margin: $spacing-base 0;
      /deep/ .el-form-item {
        margin: 0;
        .el-form-item__content {
          text-align: left;
          line-height: 29px !important;
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
