<template>
  <el-row :gutter="20">
    <el-col :span="flexWidth">
      <el-form-item label="证书（证明）模板">
        <el-select v-model="tempalte" @change="change" class="w-100" :disabled="disabled">
          <el-option
            v-for="(tempalte, index) in tempalteList"
            :key="index"
            :label="tempalte.label"
            :value="tempalte.label"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="flexWidth">
      <el-form-item label="不动产权证号">
        <div class="flex">
          <div
            v-for="(item, index) in tempalteSplitArr"
            :key="index"
            :class="{ 'w-100': item === ',' }"
          >
            <el-input v-if="item === ','" v-model="formdata[index]" :disabled="disabled"></el-input>
            <span v-else>{{ item }}</span>
          </div>
          <el-button
            v-if="deleteBtn"
            :disabled="disabledBtn"
            style="margin-left: 10px"
            @click="deleteRow"
            type="warning"
            plain
            >删除</el-button
          >
        </div>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'certificate-template',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      type: String
    },
    deleteBtn: {
      type: Boolean
    },
    disabledBtn: {
      type: Boolean
    },
    tempalteList: {
      type: Array
    },
    //长度
    flexWidth: {
      type: Number,
      default: 12
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formdata: [],
      tempalte: '其它'
    };
  },
  watch: {
    formdata(n) {
      let templateData = this.tempalte;
      // 用户所输入数据
      let inputDatas = [];
      inputDatas = n.filter((item) => item);
      const reg = /(\*+)/;
      if (templateData === '其它') {
        templateData = inputDatas[0] || '';
        this.$emit('update', templateData);
        return;
      }
      for (const item of inputDatas) {
        templateData = templateData.replace(reg, item);
      }
      this.$emit('update', templateData);
    }
  },
  computed: {
    tempalteSplitArr() {
      let templateStr = this.tempalte;
      if (templateStr === '其它' || !templateStr) {
        return [','];
      }
      // 将***替换成,
      const reg = /(\*+)/g;
      return templateStr.replace(reg, ',').split('');
    }
  },
  methods: {
    change() {
      this.formdata = [];
    },
    deleteRow() {
      this.$emit('deleteRow');
    }
  }
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>
