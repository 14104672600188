/**
 * 首页路由配置
 */
 export default {
  path: '/home/call',
  name: 'call',
  component: () => import('iebdc/views/call'),
  meta: {
    isNotMenu: true,
    permission: 'IEBDC:GNXS:DBLJ'
  }
};
