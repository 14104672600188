import { localDataDic } from '@iebdc/utils/local-data';
/**
 * dic store
 * @description
 * 字典储存
 */
let label = '';
const dic = localDataDic.get() || {};
function findDictionaryTree(data, value) {
  let label = '';
  for (let index in data) {
    if (String(data[index].value) === String(value)) {
      label = data[index].label;
      break;
    }
    if (data[index].hasOwnProperty('childrens')) {
      findDictionaryTree(data[index]['childrens'], value);
    }
  }
  return label;
}
export default {
  state: {
    dic: dic.dic,
  },
  getters: {
    getDic: function (state) {
      return state.dic;
    },
    //根据字典类型获取字典
    getDicByType: function (state) {
      return function (label) {
        let temp =
          state.dic &&
          state.dic.filter((currentValue) => {
            return currentValue.label === label;
          });
        if (temp && temp.length === 1 && temp[0].childrens) {
          return temp[0].childrens;
        } else {
          return [];
        }
      };
    },
    //根据类型和值获取对应字典名称
    getLabelDicByTypeAndValue: function (state) {
      label = '';
      return function (label, value) {
        let temp =
          state.dic &&
          state.dic.filter((currentValue) => {
            return currentValue.label === label;
          });
        if (temp && temp.length === 1 && temp[0].childrens) {
          return findDictionaryTree(temp[0].childrens, value);
        } else {
          return value;
        }
      };
    },
  },
  mutations: {
    setDic(state, dic) {
      state.dic = dic;
    },
  },
  actions: {
    setDic({ commit }, dic) {
      commit('setDic', dic);
    },
  },
};
